@use "./colors" as *;

/* variables */
$grid-font-size: 0.75rem;
$grid-line-height: 14px;
$grid-font: "roboto";
$grid-font-weight: 400;
$grid-font-style: normal;

/* null all borders */
.dx-datagrid-rowsview,
.dx-datagrid-headers,
.dx-datagrid-headers .dx-datagrid-table .dx-row > td,
.dx-datagrid .dx-column-lines > td,
.dx-row.dx-data-row.dx-column-lines,
.dx-datagrid-content .dx-datagrid-table .dx-row > td:first-child,
.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td:first-child {
  border: none;
}

.dx-datagrid-rowsview
  .dx-master-detail-row:not(.dx-datagrid-edit-form)
  .dx-master-detail-cell,
.dx-datagrid-rowsview
  .dx-master-detail-row:not(.dx-datagrid-edit-form)
  > .dx-datagrid-group-space {
  background-color: var(--neutral-90, $neutral-90);
  padding: 16px 6px !important;
}

td[aria-describedby*="-fixed"]:not(:has(~ td[aria-describedby*="-fixed"])) {
  border-right: 2px solid var(--neutral-80, $neutral-80) !important;
}

.dx-field-item-help-text,
.dx-field-item-label-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-dark-gray, $text-dark-gray) !important;
  float: left;
}

.dx-datagrid-adaptive-more::before {
  font-family: "Lumen";
  content: "\e313";
}

.dx-data-row.dx-selection {
  background-color: var(
    --ds-color-ui-state-row-selected,
    $ds-color-ui-state-row-selected
  );
  color: var(--text-black, $text-black);
}

.dx-selection.dx-data-row:hover {
  background-color: var(
    --ds-color-ui-state-row-selected-hover,
    $ds-color-ui-state-row-selected-hover
  );
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > tr > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td {
  color: var(--text-black, $text-black);
}

// .dx-datagrid-adaptive-more::before {
//   font-family: "Lumen";
//   content: "\e313";
// }

.dx-layout-manager .dx-label-h-align.dx-flex-layout {
  display: inline-grid;
}

.dragDropGrid .dx-gridbase-container > .dx-datagrid-rowsview.dx-scrollable {
  display: flex;
  border: 1px dashed var(--neutral-50, $neutral-50);
  border-radius: 4px;
}

.dx-layout-manager .dx-label-h-align.dx-flex-layout .dx-field-item-content,
.dx-layout-manager
  .dx-label-h-align.dx-flex-layout
  .dx-field-item-content-wrapper {
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: auto;
  display: block;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--neutral-30, $neutral-30);
}

.dx-datagrid .dx-datagrid-headers .dx-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-data-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-freespace-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-header-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-virtual-row td.dx-pointer-events-none {
  border-left: 0px solid transparent;
  border-right: 0px solid transparent;
}

.dx-datagrid-content .dx-datagrid-table .dx-row > td,
.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
  vertical-align: middle;
}

.dx-datagrid .dx-row.dx-data-row.dx-column-lines .absui-icon--close::before {
  top: 2px;
  position: sticky;
}

.numberColumn {
  color: var(--text-brand, $text-brand);
}

.dx-datagrid-headers .numberColumn {
  color: var(--text-dark-gray, $text-dark-gray) !important;
}

.dx-datagrid-borders > .dx-datagrid-rowsview,
.dx-datagrid-borders > .dx-datagrid-total-footer {
  border-bottom: none;
}

.dx-data-row .cell-edited {
  color: var(--text-brand, $text-brand);
}

.dx-datagrid-borders > .dx-datagrid-headers,
.dx-datagrid-borders > .dx-datagrid-rowsview,
.dx-datagrid-borders > .dx-datagrid-total-footer {
  border-left: none;
  border-right: none;
}

.dx-scrollbar-vertical .dx-scrollable-scroll {
  float: right;
  width: 4px;
}

.dx-scrollbar-horizontal .dx-scrollable-scroll {
  height: 4px;
}

.dx-scrollable-scroll {
  position: relative;
  box-sizing: border-box;
  -webkit-transform: translate(0, 0);
  padding: 0px;
  background-color: transparent;
  opacity: 1;
  overflow: hidden;
  transition: opacity linear;
}

app-photos .dx-scrollable-container {
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.dx-scrollable-container {
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  width: calc(100% - 6px);
  height: 100%;
}

.dx-drawer-wrapper .dx-scrollable-container {
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.dx-datagrid .dx-scrollable-container {
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  width: calc(100% - 6px);
  // height: calc(100% - 8px);
  //height: calc(100% - 15px);
  padding-bottom: 8px;
}

.dx-dropdowneditor-overlay .dx-datagrid .dx-scrollable-container {
  width: 100%;
}

.dx-datagrid-headers {
  color: var(--neutral-95, $neutral-95);
  font-weight: 400;
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
  border-bottom: none;
}

.dx-datagrid .dx-error-row {
  -webkit-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
  display: none;
}

dx-data-grid {
  .dx-header-row .dx-checkbox-container {
    height: 100%;
    width: 100%;
    // display: inline-block;
    // vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    top: 0px;
    //left: -7px;
  }
}

.dx-datagrid-checkbox-size.dx-checkbox-indeterminate .dx-checkbox-icon::before {
  margin-top: -7px;
}

.dx-checkbox-indeterminate .dx-checkbox-icon::before {
  background-color: transparent;
  content: "\ebec" !important;
  font-size: 17px;
  top: 2px;
  top: 14px !important;
  color: var(--neutral-30, $neutral-30);
}
/*
// FILTERS
// change filter icon
// .dx-header-filter{
//   &.dx-header-filter-empty{

//   }
// }

// header panel

*/

.gridWarehouse .dx-datagrid {
  .dx-datagrid-nodata {
    color: var(--neutral-50, $neutral-50);
    font-size: 1.06rem;
    background: url("../img/Empty-state/connectedWarehouseEmptyState.svg");
    background-repeat: no-repeat;
    background-position: top center;
    margin-top: 13px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 0.688rem;
    line-height: 13px;
    text-align: center;
    width: 204px;
    height: 81px;
    padding-top: 80px;
  }
}

.gridNoEmptyImage .dx-datagrid {
  .dx-datagrid-nodata {
    color: var(--neutral-40, $neutral-40);
    background: url("../img/Empty-state/empty.png");
    font-size: 1.06rem;
    margin-top: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 0.688rem;
    line-height: 13px;
    text-align: center;
    width: 100%;
    height: 20px;
    padding-top: 0px;
  }
}

/* MAIN GRID */
.dx-datagrid {
  font-family: $grid-font;
  font-weight: $grid-font-weight;
  font-style: $grid-font-style;
  font-size: $grid-font-size;
  color: var(--neutral-10, $neutral-10);
  line-height: $grid-line-height;
  .dx-datagrid-table.dx-datagrid-table-fixed {
    border: none;
  }
  .dx-datagrid-headers {
    // border-left: 1px solid var(--neutral-80, $neutral-80);
    // border-right: 1px solid var(--neutral-80, $neutral-80);
    border-bottom: 2px solid var(--neutral-80, $neutral-80);
    // border-top: 1px solid var(--neutral-80, $neutral-80);
    color: var(--text-dark-gray, $text-dark-gray) !important;
    border-top: none;
  }

  /* links */
  a {
    font-size: $grid-font-size;
    line-height: $grid-line-height;
  }
  /*filter */
  .dx-header-filter {
    &:not(.dx-header-filter-empty) {
      color: var(--text-brand, $text-brand);
    }
    &::before {
      font-family: "Lumen";
      content: "\f0b0";
      font-size: 0.75rem;
      line-height: 0.75rem;
    }
  }
  /* header-panel */
  .dx-datagrid-header-panel {
    .dx-toolbar {
      .dx-toolbar-items-container {
        background: transparent;
      }
      .dx-button-mode-contained.dx-button-has-icon {
        box-shadow: none;
        background: transparent;
        border: none;
        width: 16px;
        height: 16px;
        > div {
          padding: 0;
        }
      }
    }
  }
  /* headers */
  .dx-row.dx-column-lines.dx-header-row {
    background: var(--neutral-95, $neutral-95);
    height: 34px;
    & > td {
      padding-bottom: 2px;
      font-weight: 500;
      padding-top: 2px;
    }
    & > td:not(:last-child) {
      border-right: 0.5px solid var(--neutral-80, $neutral-80);
      // border-bottom: 0.5px solid var(--neutral-80, $neutral-80);
    }
  }

  .dx-datagrid-borders > .dx-datagrid-headers,
  .dx-datagrid-borders > .dx-datagrid-rowsview,
  .dx-datagrid-borders > .dx-datagrid-total-footer {
    border-left: none;
    border-right: 1px solid var(--neutral-70, $neutral-70);
  }

  .dx-datagrid-rowsview
    .dx-selection.dx-row:not(.dx-row-focused):hover:not(.dx-row-lines)
    > td,
  .dx-datagrid-rowsview
    .dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-lines)
    > td {
    border-bottom: none;
    border-top: none;
  }

  /* filter inputs */
  .dx-datagrid-headers .dx-texteditor-input,
  .dx-datagrid-rowsview .dx-texteditor-input {
    padding-top: 0;
  }
  .dx-widget input {
    font-family: $grid-font;
    font-style: $grid-font-style;
    font-size: $grid-font-size;
    line-height: $grid-line-height;
  }
  .dx-filter-menu.dx-menu .dx-menu-item .dx-menu-item-content {
    padding: 3px;
  }
  .dx-texteditor-container .dx-texteditor-input-container {
    padding-top: 0 !important;
    margin-top: -1px;
  }

  /* filter rows */
  .dx-datagrid-filter-row {
    > td {
      background: var(--neutral-95, $neutral-95);
    }
    .dx-editor-cell .dx-editor-with-menu .dx-texteditor-input,
    .dx-texteditor-input {
      padding-left: 25px;
      background: var(--neutral-95, $neutral-95);
      .input {
        background: var(--neutral-95, $neutral-95);
      }
    }
    .dx-menu-base .dx-menu-item .dx-menu-item-content .dx-icon {
      margin-left: 4px;
      margin-top: 4px;
      &.dx-icon-filter-operation-default::before {
        font-family: "Lumen";
        content: "\e8b6";
        font-size: 0.75rem;
        line-height: 0.75rem;
      }
    }
  }
  .dx-row.dx-column-lines.dx-datagrid-filter-row {
    height: 24px;
    & > td {
      padding: 0;
      margin: 0;
      height: 24px;
      .dx-menu-horizontal,
      .dx-editor-container {
        height: 24px;
      }
    }
    & > td:not(:last-child) {
      border-right: 0.5px solid var(--neutral-80, $neutral-80);
    }
  }

  .dx-datagrid-nodata {
    color: var(--neutral-50, $neutral-50);
    font-size: 1.06rem;
    background: url("../img/Empty-state/no-data-table.svg");
    background-repeat: no-repeat;
    background-position: top center;
    margin-top: 13px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 0.688rem;
    line-height: 13px;
    text-align: center;
    color: var(--text-light-gray, $text-light-gray);
    width: 204px;
    height: 81px;
    padding-top: 60px;
    opacity: 1;
  }


  /* data-rows */
  .dx-row.dx-data-row.dx-column-lines {
    border-bottom: 0.5px solid var(--neutral-80, $neutral-80);
    height: 30px;
    i::before {
      font-size: 18px;
    }

    i.absui-icon--bill-credit-card {
      &::before {
        font-size: $grid-font-size + 0.15rem; //0.9 rem
      }
    }

    i.absui-icon--status-ok-green,
    i.icon-circle-blank {
      &::before {
        font-size: 18px;
      }
    }
    /* selected row */
    &.dx-selection > td {
      border-right: none;
      a {
        color: var(
          --ds-color-ui-state-text-table-focus,
          $ds-color-ui-state-text-table-focus
        );
      }
    }
  }
}

.dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-datagrid-group-space,
.dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  > tr
  > td.dx-datagrid-group-space {
  border-right: none;
  vertical-align: middle;
  padding-top: 0px;
}

.dx-datagrid-rowsview .dx-row-focused.dx-group-row {
  background-color: var(
    --ds-color-ui-state-row-focus,
    $ds-color-ui-state-row-focus
  );
  color: var(--neutral-100, $neutral-100);
}

.dx-datagrid .dx-row > td {
  padding: 5px;
}

.dx-datagrid-content
  .dx-datagrid-table
  [class*="column"]
  + [class*="column"]:last-child {
  // border-top: 0.5px solid var(--neutral-80, $neutral-80);
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td,
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover
  > tr
  > td {
  background-color: transparent;
}

/* GRID BUTTONS */
.grid-btn-rows {
  border-top: 0.5px solid var(--neutral-80, $neutral-80);
  height: 33px;
}
.grid-btn {
  font-family: $grid-font;
  font-weight: $grid-font-weight;
  font-style: $grid-font-style;
  font-size: $grid-font-size;
  color: var(--text-light-gray, $text-light-gray);
  border: none;
  width: auto !important;
  height: 100% !important;
  box-shadow: none !important;
  background: transparent !important;
  i.dx-icon {
    width: $grid-font-size;
    height: $grid-font-size;
    font-size: $grid-font-size;
    line-height: $grid-font-size;
    margin-top: 0px;
    &.dx-icon-add::before {
      content: "\eab6";
      font-family: "Lumen";
    }
  }
  &:hover {
    background: var(--neutral-95, $neutral-95) !important;
  }
}

/* column chooser */

.dx-icon-icon-refresh {
  margin-top: 0px !important;
  &::before {
    font-family: "Lumen";
    content: "\e5d5";
    font-size: 21px;
    line-height: 16px;
  }
}

.dx-icon.dx-icon-column-chooser {
  width: 16px;
  height: 16px;
  margin: 0;
  padding: 0;
  // margin-top: -1px !important;
  margin-bottom: 7px;
  cursor: pointer;
  &::before {
    font-family: "Lumen";
    content: "\e8b8";
    line-height: 16px;
    position: relative;
    // top: 4px;
  }
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-row-lines) > td,
.dx-datagrid-rowsview
  .dx-row-focused.dx-data-row:not(.dx-row-lines)
  > tr:first-child
  > td {
  border-top: none;
  border-bottom: none;
}
/*
.dx-datagrid-column-chooser .dx-popup-normal{
  transform: translate(calc(50vw - 250px), calc(50vh - 400px)) !important
}
*/
.hover-edit-icon {
  &::before {
    color: transparent;
  }
}

.dx-datagrid-rowsview .dx-state-hover.dx-data-row:not(.dx-row-lines) > td,
.dx-datagrid-rowsview
  .dx-state-hover.dx-data-row:not(.dx-row-lines)
  > tr:last-child
  > td {
  background-color: var(--neutral-90, $neutral-90) !important;
}

.dx-datagrid-checkbox-size {
  vertical-align: middle;
  padding-bottom: 2px;
  //padding-left: 14px;
}

.dx-datagrid-checkbox-size .dx-checkbox-icon {
  height: 16px;
  position: relative;
  right: 1px;
  top: -1px;
  top: 0px;
  width: 16px;
}

.dx-row.dx-selection .dx-datagrid-checkbox-size .dx-checkbox-icon {
  height: 12px;
  width: 13px;
  background-color: var(--icons-white, $icons-white);
  &::before {
    margin-top: -8px;
  }
}

.focus-on-detail
  .dx-datagrid-rowsview
  .dx-row-focused.dx-data-row:not(.dx-row-lines)
  > td,
.focus-on-detail
  .dx-datagrid-rowsview
  .dx-row-focused.dx-data-row:not(.dx-row-lines)
  > tr:last-child
  > td {
  background-color: var(
    --neutral-40-opacity-90,
    $neutral-40-opacity-90
  ) !important;
  &.dx-focused {
    // ,&.dx-cell-focus-disabled
    background-color: var(--neutral-40, $neutral-40) !important;
  }
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-row-lines) > td,
.dx-datagrid-rowsview
  .dx-row-focused.dx-data-row:not(.dx-row-lines)
  > tr:last-child
  > td {
  background-color: var(
    --ds-color-ui-state-row-focus,
    $ds-color-ui-state-row-focus
  ) !important;
  color: var(
    --ds-color-ui-state-text-table-focus,
    $ds-color-ui-state-text-table-focus
  ) !important;
  .dx-checkbox-icon {
    width: 14px;
    margin-left: 1px;
    height: 17px;
    border-radius: 2px;
    margin-top: 5px;
    border: none;
    color: var(--neutral-100, $neutral-100);
    background-color: transparent !important;
  }
  .dx-checkbox .dx-checkbox-icon::before {
    content: "";
    position: absolute;
    color: white !important;
    display: block;
    width: 17px;
    top: 50%;
    margin-top: -10.5px;
    left: 50%;
    margin-left: -8px;
  }

  &.dx-focused {
    //,&.dx-cell-focus-disabled
    background-color: var(
      --ds-color-ui-state-cell-focus,
      $ds-color-ui-state-cell-focus
    ) !important;
  }
}

.main-mobile-grid.dx-datagrid-rowsview
  .dx-row-focused.dx-data-row:not(.dx-row-lines)
  > td
  .dx-checkbox
  .dx-checkbox-icon::before,
.dx-datagrid-rowsview
  .dx-row-focused.dx-data-row:not(.dx-row-lines)
  > tr:last-child
  > td
  .dx-checkbox
  .dx-checkbox-icon::before {
  content: "\eab2";
  position: absolute;
  color: var(--neutral-50, $neutral-50) !important;
  display: block;
  width: 17px;
  top: 50%;
  margin-top: -10px;
  left: 50%;
  margin-left: -8px;
}

.dx-datagrid-checkbox-size.dx-checkbox-checked .dx-checkbox-icon {
  font-size: 17px;
}
@media (max-width: 1000px) {
  .dx-datagrid-rowsview .dx-row {
    .dx-checkbox.dx-state-focused .dx-checkbox-icon::before {
      filter: none;
      -webkit-filter: none;
      -webkit-text-stroke-width: 0 !important;
      -webkit-font-smoothing: none !important;
    }
    .dx-checkbox-icon:before {
      top: 54%;
      left: 40%;
    }
  }

  .dx-datagrid-rowsview .dx-selection.dx-row {
    &:not(.dx-row-focused),
    &:not(.dx-row-focused):hover {
      background-color: var(
        --ds-color-ui-state-row-selected,
        $ds-color-ui-state-row-selected
      );
      .dx-checkbox-icon {
        background-color: var(--theme-negative-90, $theme-negative-90);
        &:before {
          content: "\e9a7" !important;
          font-size: 20px !important;
        }
      }
    }

    &.dx-row-focused,
    &.dx-row-focused:hover {
      & > td,
      & > tr > td {
        background-color: var(--neutral-90, $neutral-90) !important;
        .dx-checkbox-icon {
          background-color: var(--neutral-100, $neutral-100);
        }
      }
      background-color: var(--brand-80, $brand-80);
      .dx-checkbox-icon {
        background-color: var(--neutral-100, $neutral-100);

        &:before {
          content: "\e9a7" !important;
          font-size: 20px !important;
        }
      }

      &.dx-selection {
        & > td,
        & > tr > td {
          background-color: var(--brand-80, $brand-80)!important;
          color: var(--brand-neutral-00, $brand-neutral-00)!important;
        }
      }
    }
    
  }

  // .dx-datagrid-rowsview .dx-selection.dx-row.dx-row-focused,
  // .dx-datagrid-rowsview .dx-selection.dx-row.dx-row-focused:hover {
  //   & > td,
  //   & > tr > td {
  //     background-color: var(--brand-80, $brand-80);
  //     .dx-checkbox-icon {
  //       background-color: var(--brand-80, $brand-80);
  //     }
  //   }
  //   background-color: var(--brand-80, $brand-80);
  //   .dx-checkbox-icon {
  //     background-color: var(--brand-80, $brand-80);
  //   }
  // }
}

.dx-datagrid-rowsview
  .dx-row-focused.dx-data-row
  .dx-command-edit:not(.dx-focused)
  .dx-link,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row > td:not(.dx-focused),
.dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr > td:not(.dx-focused) {
  background-color: var(--neutral-90, $neutral-90);
  color: var(--neutral-10, $neutral-10);
}
.dx-datagrid-rowsview .dx-row-focused.dx-data-row > td,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr:last-child > td {
  border-bottom: 0.5px solid var(--neutral-10, $neutral-10);
}

.dx-row.dx-data-row.dx-column-lines.dx-state-hover {
  cursor: pointer;
  .hover-edit-icon {
    &::before {
      color: var(--neutral-30, $neutral-30);
    }
  }
}

.dx-row.dx-data-row.dx-column-lines.dx-selection {
  .hover-edit-icon {
    &::before {
      color: var(--neutral-85, $neutral-85);
    }
  }
}

.dx-row.dx-data-row.dx-column-lines.dx-state-hover {
  cursor: pointer;
  .dx-datagrid-drag-icon {
    &::before {
      color: var(--neutral-30, $neutral-30);
    }
  }
}

.dx-row-focused.dx-row.dx-data-row.dx-column-lines.dx-state-hover {
  cursor: pointer;
  .dx-datagrid-drag-icon {
    &::before {
      color: var(--neutral-100, $neutral-100);
    }
  }
}

.dx-row .dx-command-drag {
  width: 26px;
  min-width: 26px;
}

.dx-row.dx-data-row.dx-column-lines {
  &.dx-row-focused {
    .dx-datagrid-drag-icon {
      &::before {
        color: var(--text-brand-inverted, $text-brand-inverted);
      }
    }
  }
  .dx-datagrid-drag-icon {
    &::before {
      font-size: 21px;
      font-family: Lumen;
      content: "\eaa2";
      color: var(--text-light-gray, $text-light-gray);
      margin-right: -5px;
    }
  }
}

.dx-datagrid
  .dx-datagrid-headers.dx-state-focused
  .dx-header-row
  td
  .dx-header-filter:focus,
.dx-datagrid .dx-datagrid-headers.dx-state-focused .dx-header-row td:focus {
  outline: none;
  outline-offset: 2px;
}

/* TREEVIEW */

#treeview .dx-searchbox .dx-icon {
  margin-top: -17px;
  margin-left: 0;
}

.dx-header-row .sort-column-bg {
  background-color: var(--neutral-85, $neutral-85);
  &:hover {
    background-color: var(--neutral-80, $neutral-80);
    cursor: pointer;
  }
}
.dx-data-row:hover {
  background-color: var(
    --ds-color-ui-state-neutral-hover,
    $ds-color-ui-state-neutral-hover
  );
}

.dx-row-focused {
  .dx-progressbar-percent,
  i::before,
  .black-txt,
  .grey-txt,
  .second-row {
    color: var(
      --ds-color-ui-state-text-table-focus,
      $ds-color-ui-state-text-table-focus
    ) !important;
  }
}

.dx-row-focused {
  .dx-progressbar-percent,
  i::before,
  .black-txt,
  .grey-txt,
  .p-amount {
    color: var(--neutral-100, $neutral-100) !important;
  }
}

.dx-row-focused {
  .dx-progressbar-percent,
  i::before,
  .black-txt,
  .grey-txt,
  .hov-underline {
    color: var(
      --ds-color-ui-state-text-table-focus,
      $ds-color-ui-state-text-table-focus
    ) !important;
  }
}

.dx-datagrid {
  color: var(--neutral-10, $neutral-10);
  background-color: var(--ds-body-background-100, $ds-body-background-100);
  line-height: inherit;
}

.black-txt {
  color: var(--neutral-10, $neutral-10);
  font-weight: 500;
}

.grey-txt {
  color: var(--text-light-gray, $text-light-gray);
  font-weight: 400;
}

.dx-datagrid-headers {
  .grid-header-wrapper {
    position: relative;
    gap: 0 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    .header-sort-icon {
      cursor: pointer;
      color: transparent;
      font-size: 0.875rem;
      line-height: 0.875rem;
      width: 18px;
      height: 14px;

      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 0;
      padding-left: 4px;
      font-weight: 400;
      background-color: var(--neutral-90, $neutral-90);
      font-style: normal;
      display: none;
      &::before {
        font-size: 0.875rem;
        line-height: 0.875rem;
        font-family: Lumen;
      }
      &.arr-down::before {
        content: "\e5db";
      }
      &.arr-up::before {
        content: "\e5d8";
      }
    }
  }

  .sort-column-bg {
    background-color: var(--neutral-85, $neutral-85);
    &:hover {
      background-color: var(--neutral-80, $neutral-80);
      cursor: pointer;
      .grid-header-wrapper {
        .header-sort-icon {
          background-color: var(--neutral-80, $neutral-80);
        }
      }
    }
    .grid-header-wrapper {
      .header-sort-icon {
        color: var(--text-positive, $text-positive);
        display: block;
        background-color: var(--neutral-85, $neutral-85);
      }
    }
  }

  .sort-column-muted {
    &:hover {
      background-color: var(--neutral-90, $neutral-90);
      cursor: pointer;
      .grid-header-wrapper {
        .header-sort-icon {
          background-color: var(--neutral-90, $neutral-90);
          color: var(--neutral-50, $neutral-50);
          display: block;
        }
      }
    }
  }

  .sort-column-bg,
  .sort-column-muted:hover {
    .grid-header-wrapper {
      padding-right: 18px;
    }
  }

  .sorting-unavailable {
    cursor: default;
  }
}

.sad-position {
  .dx-datagrid .dx-row.dx-column-lines.dx-header-row {
    height: 24px;
    border-bottom: 0.5px solid var(--neutral-80, $neutral-80);
    #dx-col-36 {
      border-right: 0.5px solid var(--neutral-80, $neutral-80);
    }
    #dx-col-109 {
      border-right: 0.5px solid var(--neutral-80, $neutral-80);
    }
    #dx-col-99 {
      border-right: 0.5px solid var(--neutral-80, $neutral-80);
    }
  }
}

.stockHistoryGridHeader {
  .dx-datagrid .dx-row.dx-column-lines.dx-header-row {
    height: 24px;
  }
}

.grid-order-history {
  .dx-datagrid .dx-row.dx-column-lines.dx-header-row {
    height: 24px;
  }
}

// class css for grid in drop-box
.dropDownBoxClassGrid {
  .dx-datagrid .dx-row.dx-column-lines.dx-header-row {
    background: var(
      --ds-background-dropdown-menu,
      $ds-background-dropdown-menu
    );
  }

  .dx-datagrid .dx-row.dx-column-lines.dx-header-row > td:not(:last-child) {
    border-right: 0.5px solid var(--neutral-100, $neutral-100);
  }

  .dx-datagrid .dx-datagrid-headers {
    color: var(--neutral-10, $neutral-10) !important;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 0.75rem !important;
    line-height: 14px !important;
  }

  .dx-datagrid .dx-row.dx-data-row.dx-column-lines {
    border-bottom: 0.5px solid var(--neutral-100, $neutral-100);
    height: 30px;
  }

  .dx-datagrid {
    width: calc(100% + 10px);
    font-family: "roboto";
    font-weight: normal;
    font-style: normal;
    font-size: 0.75rem;
    color: var(--text-light-gray, $text-light-gray);
    line-height: 14px;
  }
}

.dx-header-row
  > td
  > .dx-datagrid-text-content:not(.dx-sort-indicator):not(
    .dx-header-filter-indicator
  ) {
  width: 100%;
}

.dx-datagrid .dx-row.dx-data-row.dx-column-lines {
  position: relative;
}

dx-data-grid.parameters-grid {
  .dx-datagrid-focus-overlay {
    border: none !important;
  }
}

dx-data-grid .dx-sortable-without-handle tr:hover {
  &.dx-row-focused {
    .dx-command-select .dx-checkbox-container::after {
      color: var(--neutral-100, $neutral-100);
    }
  }
  .dx-command-select .dx-checkbox-container::after {
    content: "\eaa4";
    font-family: Lumen;
    font-size: 21px;
    position: absolute;
    top: calc(50% - 7px);
    left: 30px;
  }
  .dx-sortable-source {
    opacity: 0.3;
  }
}

.dx-sortable-dragging > * {
  box-shadow: none;
}

.dx-sortable-clone.dx-sortable-dragging {
  opacity: 1;
  .dx-row.dx-data-row {
    border-bottom: none;
  }
}

.drop-highlight {
  border: 1px dashed var(--text-light-gray, $text-light-gray);
  border-radius: 8px;
}

.grid-hide-headers .dx-datagrid-headers {
  display: none;
}

.dashboard-grid {
  .dx-datagrid {
    .dx-row.dx-column-lines.dx-header-row > td:not(:last-child) {
      border-right: none;
    }
    .dx-row.dx-column-lines.dx-header-row:hover {
      td:not(:last-child) {
        border-right: 0.5px solid var(--neutral-80, $neutral-80);
      }
    }
    .dx-row.dx-data-row.dx-column-lines {
      border-bottom: none;
    }
    .dx-datagrid-table [class*="column"] + [class*="column"]:last-child {
      border-top: none;
    }
    .dx-row.dx-column-lines.dx-header-row {
      background: transparent;
    }
    .dx-data-row:hover {
      background-color: var(--neutral-90, $neutral-90);
    }
  }
}

.dashboard-grid-attachments {
  text-align: center;
  font-family: Roboto-Medium;
  font-size: 0.75rem;
  padding: 2px 4px;
  min-width: 22px;
  border-radius: 4px;
  float: right;
  background-color: var(--neutral-90, $neutral-90);
}

.parameters-grid {
  .dx-row.dx-column-lines.dx-header-row > td:not(:last-child) {
    border-right: none;
  }
  .dx-row.dx-column-lines.dx-header-row {
    background: none;
  }
  .dx-datagrid-headers {
    border-bottom: 1px solid var(--neutral-80, $neutral-80);
  }
  .dx-row.dx-data-row.dx-column-lines {
    border-bottom: none;
    color: var(--text-dark-gray, $text-dark-gray);
  }
  .dx-datagrid-content
    .dx-datagrid-table
    [class*="column"]
    + [class*="column"]:last-child {
    border-top: none;
  }
}

@media (min-width: 1200px) {
  dx-data-grid:not(.dropDownBoxClassGrid):not(.gridEshop)
    tbody
    .dx-row.dx-data-row.dx-column-lines
    td:last-of-type {
    padding-right: 0;
    & > * {
      padding-right: 12px;
    }
  }
}

.dropDownBoxClassGrid .dx-datagrid table.dx-datagrid-table {
  border-collapse: separate;
}

.config-text-box i::before {
  font-size: 1rem !important;
}
.config-text-box.dx-state-readonly .dx-texteditor-buttons-container {
  display: flex;
}

dx-data-grid.edit-inline-grid {
  tr {
    .editable {
      padding: 1px;
    }
  }
  tr:not(.editable-row.dx-row-focused) {
    td > div {
      padding-left: 4px;
      padding-right: 4px;
    }
  }

  .dx-texteditor.dx-editor-outlined {
    background: transparent !important;
    border: none !important;
    &:not(.input-required) {
      border: none !important;
      outline: none !important;
    }
  }
  .dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-row-lines) > td,
  .dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-row-lines) {
    &.editable-row,
    &.editable-row tr,
    &.editable-row td {
      background-color: var(--neutral-85, $neutral-85) !important;
      color: black !important;
    }
  }
  > tr:last-child > td {
    background-color: var(--neutral-85, $neutral-85);
  }
  // .editable-row.dx-row-focused {
  //   tr, td{
  //     background-color: var(--neutral-85, $neutral-85) !important;
  //     color: black !important;
  //   }
  // }
  // w edycji
  tr.editable-row {
    &.dx-row-focused {
      dx-text-box,
      dx-number-box {
        color: black;
      }
    }
    .td {
      background-color: var(--neutral-85, $neutral-85);
    }
    .editable > div {
      min-height: 28px;
      line-height: 26px;
      border: 1px solid var(--neutral-50);
      background: var(
        --ds-color-ui-state-text-table-focus,
        $ds-color-ui-state-text-table-focus
      );
      border-radius: 4px;
      padding-left: 4px;
      padding-right: 4px;
      .dx-texteditor-input {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .is-required {
      dx-text-box,
      dx-text-box.dx-state-hover.dx-texteditor,
      dx-text-box.dx-state-focused.dx-texteditor,
      dx-number-box,
      dx-number-box.dx-state-hover.dx-texteditor,
      dx-number-box.dx-state-focused.dx-texteditor {
        border: none !important;
        box-shadow: none !important;
        background: transparent;
        outline: none !important;
      }
      .dx-state-focused,
      .dx-state-hover {
        border: none !important;
        outline: none !important;
      }
    }
  }
  // focus nie w edycji
  tr.dx-row-focused:not(.editable-row) {
    .editable > div {
      min-height: 28px;
      line-height: 26px;
      border: 1px solid var(--neutral-50, $neutral-50);
      border-radius: 4px;
      padding-left: 4px;
      padding-right: 4px;
    }
  }
  .editable .dx-texteditor-input {
    padding: 7px;
  }

  .is-required .is-error {
    border: 1px solid var(--theme-negative-30, $theme-negative-30) !important;
    background-color: var(--theme-negative-100, $theme-negative-100) !important;
    border-radius: 4px;
    min-height: 26px;
  }
}

.grid-btns-dropdown {
  .dx-icon.dx-icon-spindown.dx-icon-right {
    display: none;
  }
}

.version-menu .dx-submenu .dx-menu-item {
  border-radius: 6px;
  display: inline-block;
  position: relative;
  height: auto;
  width: 100%;
  font-family: "Roboto";
  font-style: normal;
  font-size: 0.75rem;
  line-height: 14px;
}

.version-menu .dx-submenu .dx-menu-item-content {
  padding: 6px 4px 6px 12px;
}

.version-menu .dx-submenu .ng-star-inserted {
  padding: 0px 0px !important;
  .text-left {
    text-align: left;
    margin-top: -4px;
  }
}

.dx-datagrid > .dx-datagrid-headers,
.dx-datagrid > .dx-datagrid-rowsview,
.dx-datagrid > .dx-datagrid-total-footer {
  background-color: var(--ds-body-background-100, $ds-body-background-100);
}

.dx-popup-wrapper {
  .dx-datagrid > .dx-datagrid-headers,
  .dx-datagrid > .dx-datagrid-rowsview,
  .dx-datagrid > .dx-datagrid-total-footer {
    background-color: var(
      --ds-background-tiles-panels,
      $ds-background-tiles-panels
    );
  }
}

.dx-datagrid .dx-datagrid-content-fixed .dx-datagrid-table .dx-col-fixed {
  background-color: var(--ds-body-background-100, $ds-body-background-100);
}
.dx-datagrid-borders .dx-datagrid-rowsview,
.dx-datagrid-headers + .dx-datagrid-rowsview,
.dx-datagrid-rowsview.dx-datagrid-after-headers {
  border-top: 1px solid var(--neutral-80, $neutral-80);
}

.dx-datagrid-table
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(
    .dx-row-removed
  ):not(.dx-edit-row):not(.dx-row-focused)
  > td:not(.dx-focused) {
  color: var(--text-black, $text-black);
}

.dx-datagrid-rowsview .dx-row.dx-group-row {
  &:not(.dx-row-focused) {
    background-color: var(--neutral-80, $neutral-80);
    color: var(--text-black, $text-black);
  }
  &.dx-row-focused {
    color: var(
      --ds-color-ui-state-text-table-focus,
      $ds-color-ui-state-text-table-focus
    );
  }
}

.dx-datagrid-rowsview .dx-row-focused.dx-group-row .dx-datagrid-group-closed,
.dx-datagrid-rowsview .dx-row-focused.dx-group-row .dx-datagrid-group-opened,
.dx-datagrid-rowsview .dx-row-focused.dx-group-row .dx-datagrid-summary-item {
  color: var(
    --ds-color-ui-state-text-table-focus,
    $ds-color-ui-state-text-table-focus
  );
}

.dx-datagrid-rowsview .dx-row.dx-group-row td {
  border-top-color: var(--neutral-60, $neutral-30);
  border-bottom-color: var(--neutral-60, $neutral-30);
}

.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover
  > td.dx-focused,
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover
  > tr
  > td.dx-focused,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td.dx-focused,
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused)
  > tr
  > td.dx-focused {
  background-color: transparent;
  color: var(--text-black, $text-black);
}

.registers_warehouseDocuments .dx-row.dx-column-lines.dx-header-row {
  height: 12px !important;
}

.registers_warehouseDocuments
  .dx-datagrid
  .dx-row.dx-column-lines.dx-header-row
  > td {
  border-right: 0.5px solid var(--neutral-80, $neutral-80) !important;
  border-bottom: 0.5px solid var(--neutral-80, $neutral-80) !important;
}

.registers_warehouseDocuments .dx-datagrid-summary-item {
  text-align: right !important;
}
