/* brand */
$brand-00: #a2161e;
$brand-10: #bc1a22;
$brand-20: #dc1e28;
$brand-30: #ea6269;
$brand-70: #f8c9cc;
$brand-80: #fadbdd;
$brand-90: #f8f1f1;
$brand-neutral-00: #25292b;
$brand-neutral-10: #30393f;
$brand-85: #f5eded;

/* UI Colors / neutral */
$neutral-10: #000000;
$ds-color-ui-neutral-10: $neutral-10;

$neutral-15: #222222;
$ds-color-ui-neutral-15: $neutral-15;

$neutral-20: #333333;
$ds-color-ui-neutral-20: $neutral-20;

$neutral-30: #4c4c4c;
$ds-color-ui-neutral-30: $neutral-30;

$neutral-40: #666666;
$ds-color-ui-neutral-40: $neutral-40;

$neutral-40-opacity-90: rgba($neutral-40, 0.9);
$ds-color-ui-neutral-40-opacity-90: $neutral-40-opacity-90;

$neutral-50: #b3b3b3;
$ds-color-ui-neutral-50: $neutral-50;

$neutral-60: #cccccc;
$ds-color-ui-neutral-60: $neutral-60;

$neutral-65: #c3c3c3;
$ds-color-ui-neutral-65: $neutral-65;

$neutral-70: #dddddd;
$ds-color-ui-neutral-70: $neutral-70;

$neutral-80: #e5e5e5;
$ds-color-ui-neutral-80: $neutral-80;

$neutral-85: #ededed;
$ds-color-ui-neutral-85: $neutral-85;

$neutral-90: #f5f5f5;
$ds-color-ui-neutral-90: $neutral-90;

$neutral-95: #fafafa;
$ds-color-ui-neutral-95: $neutral-95;

$neutral-100: #ffffff;
$ds-color-ui-neutral-100: $neutral-100;

$neutral-logo: #30393f;
$ds-color-ui-neutral-logo: $neutral-logo;

/* UI Colors / background */
$ds-background-navibar-search: #ffffff1a;
$ds-background-navigation-page-header: #ffffff;
$ds-background-tiles-panels: #ffffff;
$ds-background-page-header-tabs: #f5f5f5;
$ds-background-dropdown-menu: #ffffff;
$ds-background-box: #fafafa;

/* UI Colors / body */
$ds-overlay-background-98: #fafafa;
$ds-body-background-100: #ffffff;
$ds-body-stroke-DM-only: #ffffff;

/* UI Colors / overlay */
$ds-overlay-background: #00000099;

/* UI Colors / text */
$text-brand: #bc1a22;
$text-brand-inverted: #ffffff;
$text-brand-outlined: #30393f;
$text-black: #000000;
$text-dark-gray: #222222;
$text-gray: #4c4c4c;
$text-light-gray: #666666;
$text-white: #ffffff;
$text-info: #0074ad;
$text-positive: #3a863b;
$text-negative: #bc1a22;
$text-warning: #a86800;
$text-brand-neutral: #30393F;

/* UI Colors / icon */
$icons-brand: #bc1a22;
$icons-brand-inverted: #ffffff;
$icons-brand-outlined: #30393f;
$icons-black: #000000;
$icons-dark-gray: #222222;
$icons-gray: #4c4c4c;
$icons-light-gray: #666666;
$icons-white: #ffffff;
$icons-info: #0074ad;
$icons-positive: #3a863b;
$icons-negative: #bc1a22;
$icons-warning: #a86800;
$icons-red: #bf360c;
$icons-pink: #d81b60;
$icons-purple: #9c27b0;
$icons-deep-purple: #673ab7;
$icons-indigo: #3f51b5;
$icons-blue: #1976d2;
$icons-light-blue: #0277bd;
$icons-cyan: #00838f;
$icons-teal: #00796b;
$icons-green: #2e7d32;
$icons-light-green: #33691e;
$icons-lime: #827717;
$icons-orange: #a86800;
$icons-brown: #795548;
$icons-grey: #757575;
$icons-blue-grey: #546e7a;

/* UI Colors / brand */
$ds-color-ui-brand-primary: #bc1a22;
$ds-color-ui-brand-secondary: #bc1a22;
$ds-color-ui-brand-accent-primary: #f8f1f1;
$ds-color-ui-brand-accent-secondary: #fadbdd;
$ds-color-ui-brand-accent-tertiary: #f8c9cc;
$ds-color-ui-brand-outlined: #30393f;
$ds-color-ui-brand-navibar: #30393f;
$ds-color-ui-brand-form-element: #dc1e28;
$ds-color-ui-brand-logo: #dc1e28;

/* UI Colors / state */
$ds-color-ui-state-brand-hover: #a2161e;
$ds-color-ui-state-form-element-hover: #a2161e;
$ds-color-ui-state-brand-focus: #30393f;
$ds-color-ui-state-navibar-hover: #232a2f;
$ds-color-ui-state-neutral-hover: #f5f5f5;
$ds-color-ui-state-accent-hover: #f5eded;
$ds-color-ui-state-row-selected: #f8f1f1;
$ds-color-ui-state-row-selected-hover: #f5eded;
$ds-color-ui-state-row-focus: #666666;
$ds-color-ui-state-cell-focus: #4c4c4c;
$ds-color-ui-state-text-icon-disabled: #b3b3b3;
$ds-color-ui-state-text-table-focus: #ffffff;
$ds-color-ui-state-form-field-disabled: #FAFAFA;

/* UI Colors / themes */
/* $theme-info */
$theme-info-30: #0074ad;
$theme-info-50: #008cd2;
$theme-info-60: #00aaff;
$theme-info-85: #c2ebff;
$theme-info-90: #d6f1ff;
$theme-info-100: #ebf8ff;
/* theme-positive */
$theme-positive-30: #3a863b;
$theme-positive-50: #4caf4e;
$theme-positive-60: #71c172;
$theme-positive-85: #c6e6c7;
$theme-positive-90: #dcefdc;
$theme-positive-100: #f1f9f1;
/* theme-negative */
$theme-negative-30: #bc1a22;
$theme-negative-50: #dc1e28;
$theme-negative-60: #ea6269;
$theme-negative-85: #f8c9cc;
$theme-negative-90: #fadbdd;
$theme-negative-100: #f8f1f1;
/* theme-warning */
$theme-warning-30: #a86800;
$theme-warning-50: #ee9f07;
$theme-warning-60: #f9b42f;
$theme-warning-85: #fdeac4;
$theme-warning-90: #fef1d7;
$theme-warning-100: #fef8eb;

/* Effects Styles / Shadow */
$shadow-xxl: 0px 8px 60px 0 rgba(0, 0, 0, 0.15),
  0px 2px 1px 0 rgba(0, 0, 0, 0.06);
$shadow-xl: 0px 0px 2px 0 rgba(0, 0, 0, 0.09),
  0px 4px 2px -2px rgba(0, 0, 0, 0.09), 0px 3px 6px 0 rgba(0, 0, 0, 0.2);
$shadow-l: 0px 4px 10px 0 rgba(0, 0, 0, 0.1);
$shadow-m: 0px 4px 20px 0 rgba(0, 0, 0, 0.1), 0px 2px 1px 0 rgba(0, 0, 0, 0.06);
$shadow-s: 0px 2px 2px 0 rgba(0, 0, 0, 0.06),
  0px 3px 1px -2px rgba(0, 0, 0, 0.04), 0px 1px 5px 0 rgba(0, 0, 0, 0.14);
$shadow-xs: 0px 1px 2px 0 rgba(0, 0, 0, 0.1);

/* accent */
$accent-teal: #4db6ac;
$accent-light-green: #aed581;
$accent-blue-grey: #90a4ae;
$accent-red: #e57373;
$accent-light-blue: #4fc3f7;
$accent-orange: #ffb74d;
$accent-green: #81c784;
$accent-pink: #f06292;
$accent-purple: #ba68c8;
$accent-deep-purple: #9575cd;
$accent-indigo: #7986cb;
$accent-blue: #64b5f6;
$accent-cyan: #4dd0e1;
$accent-lime: #dce775;
$accent-brown: #a1887f;
$accent-grey: #e0e0e0;

/* tags */
/* tag fill color */
$tagfill-red: #ffcdd2;
$tagfill-pink: #f8bbd0;
$tagfill-purple: #e1bee7;
$tagfill-deep-purple: #d1c4e9;
$tagfill-indigo: #c5cae9;
$tagfill-blue: #bbdefb;
$tagfill-light-blue: #b3e5fc;
$tagfill-cyan: #b2ebf2;
$tagfill-teal: #b2dfdb;
$tagfill-green: #c8e6c9;
$tagfill-light-green: #dcedc8;
$tagfill-lime: #f0f4c3;
$tagfill-yellow: #fff9c4;
$tagfill-amber: #ffecb3;
$tagfill-orange: #ffe0b2;
$tagfill-deep-orange: #ffccbc;
$tagfill-brown: #d7ccc8;
$tagfill-grey: #f5f5f5;
$tagfill-blue-grey: #cfd8dc;
$tagfill-white: #ffffff;
$tagfill-deep-teal: #b2dfdb;
$tagfill-deep-indigo: #c5cae9;

/* tag text color */
$tagtxt-red: #741111;
$tagtxt-pink: #6e0c40;
$tagtxt-purple: #3c1071;
$tagtxt-deep-purple: #151c66;
$tagtxt-indigo: #151c66;
$tagtxt-blue: #0b3a83;
$tagtxt-light-blue: #01467d;
$tagtxt-cyan: #004d51;
$tagtxt-teal: #003e34;
$tagtxt-green: #164c1a;
$tagtxt-light-green: #295518;
$tagtxt-lime: #524b0f;
$tagtxt-yellow: #6f390a;
$tagtxt-amber: #733200;
$tagtxt-orange: #7c2c00;
$tagtxt-deep-orange: #782208;
$tagtxt-brown: #321f1c;
$tagtxt-grey: #1b1b1b;
$tagtxt-blue-grey: #1f282d;
$tagtxt-dark: #272e33;
$tagtxt-deep-teal: #003e34;
$tagtxt-deep-indigo: #151c66;

/* other */
$abs-brands-cloud-20: #00aaa0;
$abs-brands-cloud-70: #c2ebe8;

$globals-tags-100-md-green: #c8e6c9;
$globals-dedicated-font-for-tags-green: #164c1a;

$globals-tags-100-md-yellow: #fff9c4;
$globals-dedicated-font-for-tags-yellow: #6f390a;

$globals-tags-100-md-blue: #bbdefb;
$globals-dedicated-font-for-tags-blue: #0b3a83;

$globals-tags-100-md-cyan: #b2ebf2;
$globals-dedicated-font-for-tags-cyan: #004d51;

$globals-tags-100-md-light-blue: #b3e5fc;
$globals-dedicated-font-for-tags-light-blue: #01467d;

$globals-tags-100-md-deep-orange: #ffccbc;
$globals-dedicated-font-for-tags-deep-orange: #782208;

$globals-tags-100-md-orange: #ffe0b2;
$globals-dedicated-font-for-tags-orange: #7c2c00;

$globals-tags-100-md-amber: #ffecb3;
$globals-dedicated-font-for-tags-amber: #733200;

$globals-tags-100-md-lime: #f0f4c3;
$globals-dedicated-font-for-tags-lime: #524b0f;

$globals-tags-100-md-teal: #b2dfdb;
$globals-dedicated-font-for-tags-teal: #003e34;

$globals-tags-100-md-light-green: #dcedc8;
$globals-dedicated-font-for-tags-light-green: #295518;

$globals-tags-100-md-red: #ffcdd2;
$globals-dedicated-font-for-tags-red: #741111;

$globals-tags-100-md-blue-grey: #cfd8dc;
$globals-dedicated-font-for-tags-blue-grey: #1f282d;

$globals-tags-100-md-brown: #d7ccc8;
$globals-dedicated-font-for-tags-brown: #321f1c;

$globals-tags-100-md-indigo: #c5cae9;
$globals-dedicated-font-for-tags-indigo: #151c66;

$globals-dedicated-font-for-tags-pink: #6e0c40;

$globals-tags-100-md-purple: #e1bee7;
$globals-dedicated-font-for-tags-purple: #3c1071;

$globals-tags-100-md-deep-purple: #d1c4e9;
$globals-dedicated-font-for-tags-deep-purple: #281676;

$teal-900: #004d40;
$teal-900-round: #e0f2f1;

//avatarfill

$avatartext-red: #b71c1c;
$avatartext-pink: #880e4f;
$avatartext-purple: #4a148c;
$avatartext-deep-purple: #311b92;
$avatartext-indigo: #1a237e;
$avatartext-blue: #0d47a1;
$avatartext-light-blue: #01579b;
$avatartext-cyan: #006064;
$avatartext-teal: #004d40;
$avatartext-green: #1b5e20;
$avatartext-light-green: #33691e;
$avatartext-lime: #827717;
$avatartext-yellow: #bf5e08;
$avatartext-amber: #cc5a03;
$avatartext-orange: #d04b02;
$avatartext-deep-orange: #bf360c;
$avatartext-brown: #3e2723;
$avatartext-gray: #212121;
$avatartext-blue-gray: #263238;

$avatarfill-red: #ffebee;
$avatarfill-pink: #fce4ec;
$avatarfill-purple: #f3e5f5;
$avatarfill-deep-purple: #ede7f6;
$avatarfill-indigo: #e8eaf6;
$avatarfill-blue: #e3f2fd;
$avatarfill-light-blue: #e1f5fe;
$avatarfill-cyan: #e0f7fa;
$avatarfill-teal: #e0f2f1;
$avatarfill-green: #e8f5e9;
$avatarfill-light-green: #f1f8e9;
$avatarfill-lime: #f9fbe7;
$avatarfill-yellow: #fffde7;
$avatarfill-amber: #fff8e1;
$avatarfill-orange: #fff3e0;
$avatarfill-deep-orange: #fbe9e7;
$avatarfill-brown: #efebe9;
$avatarfill-gray: #fafafa;
$avatarfill-blue-gray: #eceff1;

@mixin colorThemeTransition() {
  transition-property: background-color, color, border-color !important;
  transition-duration: 0s !important;
}
