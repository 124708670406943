$main-font-size: 16px;

/* headers */
h1{
    font-size: 1.625 * $main-font-size;
    font-weight: 600;
    line-height: 32px;
}

h2{
    font-size: 1.375 * $main-font-size;
    font-weight: 700;
    line-height: 28px;
}

h3{
    font-size: 1.125 * $main-font-size;
    font-weight: 600;
    line-height: 22px;
}

h4{
    font-size: $main-font-size;
    font-weight: 600;
    line-height: 20px;
}

h5{
    font-size: 0.875 * $main-font-size;
    font-weight: 600;
    line-height: 18px;
}

.subheader-short{
    font-size: 0.875 * $main-font-size;
    line-height: 18px;
    font-weight: 500;
}

.subheader-long{
    font-size: 0.875 * $main-font-size;
    line-height: 22px;
    font-weight: 500;
}

.body-short{
    font-size: 0.875 * $main-font-size;
    line-height: 18px;
    font-weight: 400;
}

.body-long{
    font-size: 0.875 * $main-font-size;
    line-height: 22px;
    font-weight: 400;
}

.caption-short{
    font-size: 0.75 * $main-font-size;
    line-height: 16px;
    font-weight: 500;
}

.caption-long{
    font-size: 0.75 * $main-font-size;
    line-height: 18px;
    font-weight: 500;
}

.label{
    font-size: 0.75 * $main-font-size;
    line-height: 16px;
    font-weight: 400;
}

.legal-1{
    font-size: 0.688 * $main-font-size;
    line-height: 24px;
    font-weight: 400;
}

.legal-2{
    font-size: 0.563 * $main-font-size;
    line-height: 12px;
    font-weight: 400;
}