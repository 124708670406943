@use "../colors" as *;
@use "../fonts" as *;

@mixin colorThemeLight() {
  /* brand */
  --brand-00: #{$brand-00};
  --brand-10: #{$brand-10};
  --brand-20: #{$brand-20};
  --brand-30: #{$brand-30};
  --brand-70: #{$brand-70};
  --brand-80: #{$brand-80};
  --brand-90: #{$brand-90};
  --brand-neutral-00: #{$brand-neutral-00};
  --brand-neutral-10: #{$brand-neutral-10};
  --brand-85: #{$brand-85};

  /* UI Colors / neutral */
  --neutral-10: #{$neutral-10};
  --ds-color-ui-neutral-10: #{$neutral-10};
  --neutral-20: #{$neutral-20};
  --ds-color-ui-neutral-20: #{$neutral-20};
  --neutral-30: #{$neutral-30};
  --ds-color-ui-neutral-30: #{$neutral-30};
  --neutral-40: #{$neutral-40};
  --ds-color-ui-neutral-40: #{$neutral-40};
  --neutral-40-opacity-90: rgba($neutral-40, 0.9);
  --neutral-50: #{$neutral-50};
  --ds-color-ui-neutral-50: #{$neutral-50};
  --neutral-60: #{$neutral-60};
  --ds-color-ui-neutral-60: #{$neutral-60};
  --neutral-70: #{$neutral-70};
  --ds-color-ui-neutral-70: #{$neutral-70};
  --neutral-80: #{$neutral-80};
  --ds-color-ui-neutral-80: #{$neutral-80};
  --neutral-85: #{$neutral-85};
  --ds-color-ui-neutral-85: #{$neutral-85};
  --neutral-90: #{$neutral-90};
  --ds-color-ui-neutral-90: #{$neutral-90};
  --neutral-95: #{$neutral-95};
  --ds-color-ui-neutral-95: #{$neutral-95};
  --neutral-100: #{$neutral-100};
  --ds-color-ui-neutral-100: #{$neutral-100};
  --neutral-logo: #{$neutral-logo};
  --ds-color-ui-neutral-logo: #{$neutral-logo};

  /* UI Colors / background */
  --ds-background-navibar-search: #{$ds-background-navibar-search};
  --ds-background-navigation-page-header: #{$ds-background-navigation-page-header};
  --ds-background-tiles-panels: #{$ds-background-tiles-panels};
  --ds-background-page-header-tabs: #{$ds-background-page-header-tabs};
  --ds-background-dropdown-menu: #{$ds-background-dropdown-menu};
  --ds-background-box: #{$ds-background-box};

  /* UI Colors / body */
  --ds-overlay-background-98: #{$ds-overlay-background-98};
  --ds-body-background-100: #{$ds-body-background-100};
  --ds-body-stroke-DM-only: #{ds-body-stroke-DM-only};

  /* UI Colors / overlay */
  --ds-overlay-background: #{$ds-overlay-background};

  /* UI Colors / text */
  --text-brand: #{$text-brand};
  --text-brand-inverted: #{$text-brand-inverted};
  --text-brand-outlined: #{$text-brand-outlined};
  --text-black: #{$text-black};
  --text-dark-gray: #{$text-dark-gray};
  --text-gray: #{$text-gray};
  --text-light-gray: #{$text-light-gray};
  --text-white: #{$text-white};
  --text-info: #{$text-info};
  --ds-color-text-info-strong: #0470a9; /* WEW-9538 */
  --text-positive: #{$text-positive};
  --ds-color-text-positive-strong: #2b772c; /* WEW-9538 */
  --text-negative: #{$text-negative};
  --ds-color-text-negative-strong: #bc1a22; /* WEW-9538 */
  --text-warning: #{$text-warning};
  --ds-color-text-warning-strong: #a06000; /* WEW-9538 */
  --text-brand-neutral: #{$text-brand-neutral};

  /* UI Colors / icon */
  --icons-brand: #{$icons-brand};
  --icons-brand-inverted: #{$icons-brand-inverted};
  --icons-brand-outlined: #{$icons-brand-outlined};
  --icons-black: #{$icons-black};
  --icons-dark-gray: #{$icons-dark-gray};
  --icons-gray: #{$icons-gray};
  --icons-light-gray: #{$icons-light-gray};
  --icons-white: #{$icons-white};
  --icons-info: #{$icons-info};
  --ds-color-icon-info-strong: #0470a9; /* WEW-9538 */
  --icons-positive: #{$icons-positive};
  --ds-color-icon-positive-strong: #2b772c; /* WEW-9538 */
  --icons-negative: #{$icons-negative};
  --ds-color-icon-negative-strong: #bc1a22; /* WEW-9538 */
  --icons-warning: #{$icons-warning};
  --ds-color-icon-warning-strong: #a06000; /* WEW-9538 */
  --icons-red: #{$icons-red};
  --icons-pink: #{$icons-pink};
  --icons-purple: #{$icons-purple};
  --icons-deep-purple: #{$icons-deep-purple};
  --icons-indigo: #{$icons-indigo};
  --icons-blue: #{$icons-blue};
  --icons-light-blue: #{$icons-light-blue};
  --icons-cyan: #{$icons-cyan};
  --icons-teal: #{$icons-teal};
  --icons-green: #{$icons-green};
  --icons-light-green: #{$icons-light-green};
  --icons-lime: #{$icons-lime};
  --icons-orange: #{$icons-orange};
  --icons-brown: #{$icons-brown};
  --icons-grey: #{$icons-grey};
  --icons-blue-grey: #{$icons-blue-grey};

  /* UI Colors / brand */
  --ds-color-ui-brand-primary: #{$ds-color-ui-brand-primary};
  --ds-color-ui-brand-secondary: #{$ds-color-ui-brand-secondary};
  --ds-color-ui-brand-accent-primary: #{$ds-color-ui-brand-accent-primary};
  --ds-color-ui-brand-accent-secondary: #{$ds-color-ui-brand-accent-secondary};
  --ds-color-ui-brand-accent-tertiary: #{$ds-color-ui-brand-accent-tertiary};
  --ds-color-ui-brand-outlined: #{$ds-color-ui-brand-outlined};
  --ds-color-ui-brand-navibar: #{$ds-color-ui-brand-navibar};
  --ds-color-ui-brand-form-element: #{$ds-color-ui-brand-form-element};
  --ds-color-ui-brand-logo: #{$ds-color-ui-brand-logo};

  /* UI Colors / state */
  --ds-color-ui-state-brand-hover: #{$ds-color-ui-state-brand-hover};
  --ds-color-ui-state-form-element-hover: #{$ds-color-ui-state-form-element-hover};
  --ds-color-ui-state-brand-focus: #{$ds-color-ui-state-brand-focus};
  --ds-color-ui-state-navibar-hover: #{$ds-color-ui-state-navibar-hover};
  --ds-color-ui-state-neutral-hover: #{$ds-color-ui-state-neutral-hover};
  --ds-color-ui-state-accent-hover: #{$ds-color-ui-state-accent-hover};
  --ds-color-ui-state-row-selected: #{$ds-color-ui-state-row-selected};
  --ds-color-ui-state-row-selected-hover: #{$ds-color-ui-state-row-selected-hover};
  --ds-color-ui-state-row-focus: #{$ds-color-ui-state-row-focus};
  --ds-color-ui-state-cell-focus: #{$ds-color-ui-state-cell-focus};
  --ds-color-ui-state-text-icon-disabled: #{$ds-color-ui-state-text-icon-disabled};
  --ds-color-ui-state-text-table-focus: #{$ds-color-ui-state-text-table-focus};
  --ds-color-ui-state-form-field-disabled: #{$ds-color-ui-state-form-field-disabled};

  /* UI Colors / themes */
  --theme-info-30: #{$theme-info-30};
  --theme-info-50: #{$theme-info-50};
  --theme-info-60: #{$theme-info-60};
  --theme-info-85: #{$theme-info-85};
  --theme-info-90: #{$theme-info-90};
  --theme-info-100: #{$theme-info-100};

  --theme-positive-30: #{$theme-positive-30};
  --theme-positive-50: #{$theme-positive-50};
  --theme-positive-60: #{$theme-positive-60};
  --theme-positive-85: #{$theme-positive-85};
  --theme-positive-90: #{$theme-positive-90};
  --theme-positive-100: #{$theme-positive-100};

  --theme-negative-30: #{$theme-negative-30};
  --theme-negative-50: #{$theme-negative-50};
  --theme-negative-60: #{$theme-negative-60};
  --theme-negative-85: #{$theme-negative-85};
  --theme-negative-90: #{$theme-negative-90};
  --theme-negative-100: #{$theme-negative-100};

  --theme-warning-30: #{$theme-warning-30};
  --theme-warning-50: #{$theme-warning-50};
  --theme-warning-60: #{$theme-warning-60};
  --theme-warning-85: #{$theme-warning-85};
  --theme-warning-90: #{$theme-warning-90};
  --theme-warning-100: #{$theme-warning-100};

  /* Effects Styles / Shadow */
  --shadow-xxl: #{$shadow-xxl};
  --shadow-xl: #{$shadow-xl};
  --shadow-l: #{$shadow-l};
  --shadow-m: #{$shadow-m};
  --shadow-s: #{$shadow-s};
  --shadow-xs: #{$shadow-xs};

  /*        */
  /*        */
  /* accent */
  --accent-teal: #{$accent-teal};
  --accent-light-green: #{$accent-light-green};
  --accent-blue-grey: #{$accent-blue-grey};
  --accent-red: #{$accent-red};
  --accent-light-blue: #{$accent-light-blue};
  --accent-orange: #{$accent-orange};
  --accent-green: #{$accent-green};
  --accent-pink: #{$accent-pink};
  --accent-purple: #{$accent-purple};
  --accent-deep-purple: #{$accent-deep-purple};
  --accent-indigo: #{$accent-indigo};
  --accent-blue: #{$accent-blue};
  --accent-cyan: #{$accent-cyan};
  --accent-lime: #{$accent-lime};
  --accent-brown: #{$accent-brown};
  --accent-grey: #{$accent-grey};

  /* icons */
  --icons-teal: #{$icons-teal};
  --icons-light-green: #{$icons-light-green};
  --icons-blue-grey: #{$icons-blue-grey};
  --icons-red: #{$icons-red};
  --icons-light-blue: #{$icons-light-blue};
  --icons-orange: #{$icons-orange};
  --icons-green: #{$icons-green};
  --icons-pink: #{$icons-pink};
  --icons-purple: #{$icons-purple};
  --icons-deep-purple: #{$icons-deep-purple};
  --icons-indigo: #{$icons-indigo};
  --icons-blue: #{$icons-blue};
  --icons-cyan: #{$icons-cyan};
  --icons-lime: #{$icons-lime};
  --icons-brown: #{$icons-brown};
  --icons-grey: #{$icons-grey};

  --tagfill-red: #{$tagfill-red};
  --tagfill-pink: #{$tagfill-pink};
  --tagfill-purple: #{$tagfill-purple};
  --tagfill-deep-purple: #{$tagfill-deep-purple};
  --tagfill-indigo: #{$tagfill-indigo};
  --tagfill-blue: #{$tagfill-blue};
  --tagfill-light-blue: #{$tagfill-light-blue};
  --tagfill-cyan: #{$tagfill-cyan};
  --tagfill-teal: #{$tagfill-teal};
  --tagfill-green: #{$tagfill-green};
  --tagfill-light-green: #{$tagfill-light-green};
  --tagfill-lime: #{$tagfill-lime};
  --tagfill-yellow: #{$tagfill-yellow};
  --tagfill-amber: #{$tagfill-amber};
  --tagfill-orange: #{$tagfill-orange};
  --tagfill-deep-orange: #{$tagfill-deep-orange};
  --tagfill-brown: #{$tagfill-brown};
  --tagfill-grey: #{$tagfill-grey};
  --tagfill-blue-grey: #{$tagfill-blue-grey};
  --tagfill-white: #{$tagfill-white};
  --tagfill-deep-teal: #{$tagfill-deep-teal};
  --tagfill-deep-indigo: #{$tagfill-deep-indigo};

  --tagtxt-red: #{$tagtxt-red};
  --tagtxt-pink: #{$tagtxt-pink};
  --tagtxt-purple: #{$tagtxt-purple};
  --tagtxt-deep-purple: #{$tagtxt-deep-purple};
  --tagtxt-indigo: #{$tagtxt-indigo};
  --tagtxt-blue: #{$tagtxt-blue};
  --tagtxt-light-blue: #{$tagtxt-light-blue};
  --tagtxt-cyan: #{$tagtxt-cyan};
  --tagtxt-teal: #{$tagtxt-teal};
  --tagtxt-green: #{$tagtxt-green};
  --tagtxt-light-green: #{$tagtxt-light-green};
  --tagtxt-lime: #{$tagtxt-lime};
  --tagtxt-yellow: #{$tagtxt-yellow};
  --tagtxt-amber: #{$tagtxt-amber};
  --tagtxt-orange: #{$tagtxt-orange};
  --tagtxt-deep-orange: #{$tagtxt-deep-orange};
  --tagtxt-brown: #{$tagtxt-brown};
  --tagtxt-grey: #{$tagtxt-grey};
  --tagtxt-blue-grey: #{$tagtxt-blue-grey};
  --tagtxt-dark: #{$tagtxt-dark};
  --tagtxt-deep-teal: #{$tagtxt-deep-teal};
  --tagtxt-deep-indigo: #{$tagtxt-deep-indigo};

  --abs-brands-cloud-20: #{$abs-brands-cloud-20};
  --abs-brands-cloud-70: #{$abs-brands-cloud-70};

  --globals-tags-100-md-green: #{$globals-tags-100-md-green};
  --globals-dedicated-font-for-tags-green: #{$globals-dedicated-font-for-tags-green};

  --globals-tags-100-md-yellow: #{$globals-tags-100-md-yellow};
  --globals-dedicated-font-for-tags-yellow: #{$globals-dedicated-font-for-tags-yellow};

  --globals-tags-100-md-blue: #{$globals-tags-100-md-blue};
  --globals-dedicated-font-for-tags-blue: #{$globals-dedicated-font-for-tags-blue};

  --globals-tags-100-md-cyan: #{$globals-tags-100-md-cyan};
  --globals-dedicated-font-for-tags-cyan: #{$globals-dedicated-font-for-tags-cyan};

  --globals-tags-100-md-light-blue: #{$globals-tags-100-md-light-blue};
  --globals-dedicated-font-for-tags-light-blue: #{$globals-dedicated-font-for-tags-light-blue};

  --globals-tags-100-md-deep-orange: #{$globals-tags-100-md-deep-orange};
  --globals-dedicated-font-for-tags-deep-orange: #{$globals-dedicated-font-for-tags-deep-orange};

  --globals-tags-100-md-orange: #{$globals-tags-100-md-orange};
  --globals-dedicated-font-for-tags-orange: #{$globals-dedicated-font-for-tags-orange};

  --globals-tags-100-md-amber: #{$globals-tags-100-md-amber};
  --globals-dedicated-font-for-tags-amber: #{$globals-dedicated-font-for-tags-amber};

  --globals-tags-100-md-lime: #{$globals-tags-100-md-lime};
  --globals-dedicated-font-for-tags-lime: #{$globals-dedicated-font-for-tags-lime};

  --globals-tags-100-md-teal: #{$globals-tags-100-md-teal};
  --globals-dedicated-font-for-tags-teal: #{$globals-dedicated-font-for-tags-teal};

  --globals-tags-100-md-light-green: #{$globals-tags-100-md-light-green};
  --globals-dedicated-font-for-tags-light-green: #{$globals-dedicated-font-for-tags-light-green};

  --globals-tags-100-md-red: #{$globals-tags-100-md-red};
  --globals-dedicated-font-for-tags-red: #{$globals-dedicated-font-for-tags-red};

  --globals-tags-100-md-blue-grey: #{$globals-tags-100-md-blue-grey};
  --globals-dedicated-font-for-tags-blue-grey: #{$globals-dedicated-font-for-tags-blue-grey};

  --globals-tags-100-md-brown: #{$globals-tags-100-md-brown};
  --globals-dedicated-font-for-tags-brown: #{$globals-dedicated-font-for-tags-brown};

  --globals-tags-100-md-indigo: #{$globals-tags-100-md-indigo};
  --globals-dedicated-font-for-tags-indigo: #{$globals-dedicated-font-for-tags-indigo};

  --globals-dedicated-font-for-tags-pink: #{$globals-dedicated-font-for-tags-pink};

  --globals-tags-100-md-purple: #{$globals-tags-100-md-purple};
  --globals-dedicated-font-for-tags-purple: #{$globals-dedicated-font-for-tags-purple};

  --globals-tags-100-md-deep-purple: #{$globals-tags-100-md-deep-purple};
  --globals-dedicated-font-for-tags-deep-purple: #{$globals-dedicated-font-for-tags-deep-purple};

  --teal-900: #{$teal-900};
  --teal-900-round: #{$teal-900-round};

  --avatartext-red: #{$avatartext-red};
  --avatartext-pink: #{$avatartext-pink};
  --avatartext-purple: #{$avatartext-purple};
  --avatartext-deep-purple: #{$avatartext-deep-purple};
  --avatartext-indigo: #{$avatartext-indigo};
  --avatartext-blue: #{$avatartext-blue};
  --avatartext-light-blue: #{$avatartext-light-blue};
  --avatartext-cyan: #{$avatartext-cyan};
  --avatartext-teal: #{$avatartext-teal};
  --avatartext-green: #{$avatartext-green};
  --avatartext-light-green: #{$avatartext-light-green};
  --avatartext-lime: #{$avatartext-lime};
  --avatartext-yellow: #{$avatartext-yellow};
  --avatartext-amber: #{$avatartext-amber};
  --avatartext-orange: #{$avatartext-orange};
  --avatartext-deep-orange: #{$avatartext-deep-orange};
  --avatartext-brown: #{$avatartext-brown};
  --avatartext-gray: #{$avatartext-gray};
  --avatartext-blue-gray: #{$avatartext-blue-gray};

  --avatarfill-red: #{$avatarfill-red};
  --avatarfill-pink: #{$avatarfill-pink};
  --avatarfill-purple: #{$avatarfill-purple};
  --avatarfill-deep-purple: #{$avatarfill-deep-purple};
  --avatarfill-indigo: #{$avatarfill-indigo};
  --avatarfill-blue: #{$avatarfill-blue};
  --avatarfill-light-blue: #{$avatarfill-light-blue};
  --avatarfill-cyan: #{$avatarfill-cyan};
  --avatarfill-teal: #{$avatarfill-teal};
  --avatarfill-green: #{$avatarfill-green};
  --avatarfill-light-green: #{$avatarfill-light-green};
  --avatarfill-lime: #{$avatarfill-lime};
  --avatarfill-yellow: #{$avatarfill-yellow};
  --avatarfill-amber: #{$avatarfill-amber};
  --avatarfill-orange: #{$avatarfill-orange};
  --avatarfill-deep-orange: #{$avatarfill-deep-orange};
  --avatarfill-brown: #{$avatarfill-brown};
  --avatarfill-gray: #{$avatarfill-gray};
  --avatarfill-blue-gray: #{$avatarfill-blue-gray};
}
