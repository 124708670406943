@use "./fonts" as *;
@use "./colors" as *;

.dx-toast-wrapper:first-of-type {
  margin-top: 70px;
}

.dx-toast-wrapper {
  margin-right: 14px;
}

.dx-toast-success {
  width: 370px;
  max-width: 370px;
  background-color: var(
    --ds-background-dropdown-menu,
    $ds-background-dropdown-menu
  ) !important;
  background-image: url("../img/success.png");
  background-position: 15px 10px;
  background-repeat: no-repeat;
  border: 1px solid var(--theme-positive-50, $theme-positive-50);
  border-left: 6px solid var(--theme-positive-50, $theme-positive-50);
  color: var(--neutral-30, $neutral-30);
  box-shadow: 0px 10px 15px rgba(68, 68, 68, 0.2);
  border-radius: 12px;
}

.dx-toast-message {
  white-space: pre-line;
}

.dx-toast-info {
  width: 370px;
  max-width: 370px;
  background-color: var(
    --ds-background-dropdown-menu,
    $ds-background-dropdown-menu
  ) !important;
  background-image: url("../img/info.png");
  background-position: 15px 10px;
  background-repeat: no-repeat;
  border: 1px solid var(--theme-info-50, $theme-info-50);
  border-left: 6px solid var(--theme-info-50, $theme-info-50);
  color: var(--neutral-30, $neutral-30);
  box-shadow: 0px 10px 15px rgba(68, 68, 68, 0.2);
  border-radius: 12px;
}

.dx-toast-error {
  $fill: var(--theme-negative-50, $theme-negative-50);
  width: 370px;
  max-width: 370px;
  background-color: var(
    --ds-background-dropdown-menu,
    $ds-background-dropdown-menu
  ) !important;
  background-image: url("../img/error.png");
  background-position: 15px 10px;
  background-repeat: no-repeat;
  border: 1px solid var(--theme-negative-50, theme-negative-50);
  border-left: 6px solid var(--theme-negative-50, $theme-negative-50);
  color: var(--neutral-30, $neutral-30);
  box-shadow: 0px 10px 15px rgba(68, 68, 68, 0.2);
  border-radius: 12px;

}

.dx-toast-warning {
  width: 370px;
  max-width: 370px;
  background-color: var(
    --ds-background-dropdown-menu,
    $ds-background-dropdown-menu
  ) !important;
  background-image: url("../img/warning.png");
  background-position: 15px 10px;
  background-repeat: no-repeat;
  border: 1px solid var(--theme-warning-50, $theme-warning-50);
  border-left: 6px solid var(--theme-warning-50, $theme-warning-50);
  color: var(--neutral-30, $neutral-30);
  box-shadow: 0px 10px 15px rgba(68, 68, 68, 0.2);
  border-radius: 12px;
}

.dx-toast-message {
  width: 300px;
  left: 46px;
  font-family: $Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8125rem;
  line-height: 15px;
  color: var(--neutral-30, $neutral-30);
}

.dx-toast-reminder {
  width: 343px;
  max-width: 350px;
  background-color: var(--neutral-100, $neutral-100) !important;
  border: 1px solid var(--theme-warning-50, $theme-warning-50);
  border-left: 6px solid var(--theme-warning-50, $theme-warning-50);
  color: var(--neutral-30, $neutral-30);
  box-shadow: 0px 10px 15px rgb(68 68 68 / 20%);
  border-radius: 12px;
  .icon-reminder {
    margin-left: 5px;
    width: 21px;
    height: 21px;
    &::before {
      color: var(--theme-warning-30, $theme-warning-30);
      content: "\e7f7";
      font-size: 18px;
      width: 21px;
      height: 21px;
    }
  }
  .title-text {
    font-family: Roboto-Medium;
    font-size: 14px;
    line-height: 17px;
    color: var(--neutral-30, $neutral-30);
  }
  .content-text {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
    color: var(--neutral-30, $neutral-30);
  }
  .go-to-task {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    text-decoration: none;
    color: var(--neutral-30, $neutral-30);
    &:hover {
      text-decoration: underline;
    }
  }
  .time-text {
    font-weight: 400;
  }
  .time-box {
    background: var(--theme-warning-85, $theme-warning-85);
    border-radius: 4px;
    padding: 2px 4px;
    color: var(--neutral-10, $neutral-10);
    font-family: Roboto-Medium;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 14px;
  }
  .close-icon {
    border-radius: 6px;
    padding: 3px;
    &:hover {
      background-color: var(--neutral-85, $neutral-85);
    }
    &:focus {
      border: 1px solid var(--neutral-30, $neutral-30) !important;
    }
    &::before {
      font-size: 18px;
      -webkit-text-stroke-width: 0;
    }
  }
  .content {
    margin-bottom: 10px;
  }
}

.dx-toast-icon {
  width: 21px;
  height: 21px;
  background-image: none !important;
  margin-right: 20px;
}

@media (max-width: 1000px) {
  .dx-toast-message {
    display: inline-block;
    flex: 1 1 auto;
    word-break: break-word;
  }
}
