@use "./colors" as *;
@use "./fonts" as *;

$border-radius: 4px;
$button-height: 26px;
$focus-border-width: 2px;

@mixin state(
  $bg-color: transparent,
  $border-color: transparent,
  $font-color: var(--neutral-30, $neutral-30),
  $border-style: solid,
  $border-width: $focus-border-width,
  $border-radius: $border-radius,
  $outline-style: solid,
  $outline-width: $focus-border-width,
  $outline-color: transparent,
  $outline-offset: -$focus-border-width
) {
  border: $border-width $border-style $border-color !important;
  color: $font-color !important;
  background-color: $bg-color !important;
  outline: $outline-width $outline-style $outline-color !important;
  outline-offset: $outline-offset !important;

  &:not(.chips-btn):not(.btn-filter-box) {
    border-radius: $border-radius;
  }
}

/* default button */
@mixin btn(
  $bg-color: transparent,
  $bg-color-hover: var(--ds-color-ui-state-neutral-hover),
  $border-color: transparent,
  $font-color: var(--text-gray, $text-gray),
  $border-radius: $border-radius,
  $border-width: $focus-border-width,
  $border-style: solid
) {
  @include state(
    $bg-color: $bg-color,
    $border-color: $border-color,
    $font-color: $font-color,
    $border-radius: $border-radius,
    $border-width: $border-width,
    $border-style: $border-style
  );
  font-family: $Roboto;
  max-height: 100%;

  &:not(.dx-widget):not(.chips-btn):not(.btn-filter-box) {
    border-radius: $border-radius;
    height: 26px;
  }
  // Transparent
  &.dx-state-active,
  &:active,
  &.dx-state-hover,
  &:hover {
    @include btn-hover(
      $border-radius: $border-radius,
      $border-width: $border-width,
      $bg-color: $bg-color-hover
    );
  }

  &:not(.dx-dropdownbutton-action):not(.dx-dropdownbutton-toggle):not(
      .btn-dx-top-menu-folder-grid
    ):not(.user-panel-header-active):not(.user-panel-header) {
    &.dx-button-mode-text {
      &.dx-state-focused,
      &:focus {
        @include btn-focus(
          $border-radius: $border-radius,
          $border-width: $border-width
        );
      }
      &.dx-state-focused.dx-state-active,
      &.dx-state-focused.dx-state-hover,
      &.dx-state-focused-active {
        @include btn-focused-active(
          $border-radius: $border-radius,
          $border-width: $border-width
        );
      }
      &.dx-state-disabled,
      &[disabled],
      &[aria-disabled="true"] {
        @include btn-disabled(
          $border-radius: $border-radius,
          $border-width: $border-width
        );
      }
    }

    &.dx-button-mode-contained {
      &.dx-state-focused,
      &:focus {
        @include btn-focus(
          $border-radius: $border-radius,
          $border-width: $border-width,
          $bg-color: $bg-color
        );

        &.dx-button-success {
          @include btn-success-focus(
            $border-radius: $border-radius,
            $border-width: $border-width
          );
        }

        &.dx-button-danger {
          @include btn-danger-focus(
            $border-radius: $border-radius,
            $border-width: $border-width
          );
        }
      }
      &.dx-state-focused.dx-state-active,
      &.dx-state-focused.dx-state-hover,
      &.dx-state-focused-active {
        @include btn-focused-active(
          $border-radius: $border-radius,
          $border-width: $border-width,
          $bg-color: $bg-color
        );

        &.dx-button-success {
          @include btn-success-focused-active(
            $border-radius: $border-radius,
            $border-width: $border-width
          );
        }

        &.dx-button-danger {
          @include btn-danger-focused-active(
            $border-radius: $border-radius,
            $border-width: $border-width
          );
        }
      }
      &.dx-state-disabled,
      &[disabled],
      &[aria-disabled="true"] {
        @include btn-disabled(
          $border-radius: $border-radius,
          $border-width: $border-width
        );
      }
    }
    &.dx-button-mode-outlined {
      @include btn-outline($border-radius: $border-radius, $border-width: 1px);
      &.dx-state-disabled,
      &[disabled],
      &[aria-disabled="true"] {
        @include btn-outline-disabled(
          $border-radius: $border-radius,
          $border-width: 1px
        );
      }
    }
  }
}

@mixin btn-focus(
  $border-color:
    var(--ds-color-ui-state-brand-focus, $ds-color-ui-state-brand-focus),
  $border-radius: $border-radius,
  $border-width: $focus-border-width,
  $outline-color:
    var(--ds-color-ui-state-brand-focus, $ds-color-ui-state-brand-focus),
  $border-style: inset,
  $bg-color: transparent
) {
  @include state(
    $border-color: $border-color,
    $border-style: $border-style,
    $border-width: $border-width,
    $border-radius: $border-radius,
    $outline-color: $outline-color,
    $outline-width: $border-width,
    $bg-color: $bg-color
  );
}

@mixin btn-hover(
  $border-radius: $border-radius,
  $border-width: $focus-border-width,
  $bg-color: var(--ds-color-ui-state-neutral-hover)
) {
  @include state($bg-color: $bg-color, $border-radius: $border-radius);
}

@mixin btn-focused-active(
  $border-radius: $border-radius,
  $border-width: $focus-border-width,
  $bg-color: var(--ds-color-ui-state-neutral-hover)
) {
  @include state(
    $bg-color: $bg-color,
    $border-color:
      var(--ds-color-ui-state-brand-focus, $ds-color-ui-state-brand-focus),
    $border-style: inset,
    $border-width: $border-width,
    $border-radius: $border-radius,
    $outline-color:
      var(--ds-color-ui-state-brand-focus, $ds-color-ui-state-brand-focus),
    $outline-width: $border-width
  );
}

@mixin btn-disabled(
  $border-radius: $border-radius,
  $border-width: $focus-border-width
) {
  @include state(
    $border-color: transparent,
    $border-radius: $border-radius,
    $font-color:
      var(
        --ds-color-ui-state-text-icon-disabled,
        $ds-color-ui-state-text-icon-disabled
      )
  );
  .dx-button-text,
  .dx-button-content {
    color: var(
      --ds-color-ui-state-text-icon-disabled,
      $ds-color-ui-state-text-icon-disabled
    ) !important;
  }
}

/* confirm button */
@mixin btn-success(
  $border-radius: $border-radius,
  $border-width: $focus-border-width
) {
  @include state(
    $bg-color: var(--brand-10, $brand-10),
    $border-color: transparent,
    $font-color: var(--text-brand-inverted, $text-brand-inverted),
    $border-radius: $border-radius,
    $border-width: $border-width
  );
  // background-color: var(--brand-10, $brand-10) !important;
  // border-color: transparent;
  // border-radius: $border-radius !important;
  // color: var(--text-brand-inverted, $text-brand-inverted) !important;

  &.dx-state-active,
  &:active,
  &.dx-state-hover,
  &:hover {
    @include btn-success-hover(
      $border-radius: $border-radius,
      $border-width: $border-width
    );
  }

  &.dx-state-focused,
  &:focus {
    @include btn-success-focus(
      $border-radius: $border-radius,
      $border-width: $border-width
    );
  }

  &.dx-state-focused.dx-state-active,
  &.dx-state-focused.dx-state-hover,
  &.dx-state-focused-active {
    @include btn-success-focused-active(
      $border-radius: $border-radius,
      $border-width: $border-width
    );
  }

  &.dx-state-disabled,
  &[disabled],
  &[aria-disabled="true"] {
    @include btn-success-disabled(
      $border-radius: $border-radius,
      $border-width: $border-width
    );
  }
}

/* confirm button states */
@mixin btn-success-hover(
  $border-radius: $border-radius,
  $border-width: $focus-border-width
) {
  @include state(
    $bg-color: var(--brand-00, $brand-00),
    $border-color: var(--brand-00, $brand-00),
    $border-radius: $border-radius,
    $font-color: var(--text-brand-inverted, $text-brand-inverted),
    $border-width: $border-width
  );
}

@mixin btn-success-focus(
  $border-radius: $border-radius,
  $border-width: $focus-border-width
) {
  @include state(
    $bg-color: var(--brand-10, $brand-10),
    $border-color:
      var(--ds-color-ui-state-brand-focus, $ds-color-ui-state-brand-focus),
    $font-color: var(--text-brand-inverted, $text-brand-inverted),
    $border-style: inset,
    $border-width: $border-width,
    $border-radius: $border-radius,
    $outline-color:
      var(--ds-color-ui-state-brand-focus, $ds-color-ui-state-brand-focus)
  );
}

@mixin btn-success-focused-active(
  $border-radius: $border-radius,
  $border-width: $focus-border-width
) {
  @include state(
    $bg-color: var(--brand-00, $brand-00),
    $border-color:
      var(--ds-color-ui-state-brand-focus, $ds-color-ui-state-brand-focus),
    $font-color: var(--text-brand-inverted, $text-brand-inverted),
    $border-style: inset,
    $border-width: $border-width,
    $border-radius: $border-radius,
    $outline-color:
      var(--ds-color-ui-state-brand-focus, $ds-color-ui-state-brand-focus)
  );
}

@mixin btn-success-disabled(
  $border-radius: $border-radius,
  $border-width: $focus-border-width
) {
  @include state(
    $border-color: var(--neutral-90, $neutral-90),
    $border-radius: $border-radius,
    $border-width: $border-width,
    $bg-color: var(--neutral-90, $neutral-90),
    $font-color:
      var(
        --ds-color-ui-state-text-icon-disabled,
        $ds-color-ui-state-text-icon-disabled
      )
  );

  .dx-icon {
    &:before {
      color: var(
        --ds-color-ui-state-text-icon-disabled,
        $ds-color-ui-state-text-icon-disabled
      ) !important;
    }
  }

  .dx-button-text,
  .dx-button-content {
    color: var(
      --ds-color-ui-state-text-icon-disabled,
      $ds-color-ui-state-text-icon-disabled
    ) !important;
  }
}

/* cancel button neutral state*/
@mixin btn-danger(
  $border-radius: $border-radius,
  $border-width: $focus-border-width
) {
  @include state(
    $bg-color: var(--neutral-80, $neutral-80),
    $border-color: var(--neutral-80, $neutral-80),
    $font-color: var(--text-gray, $text-gray),
    $border-radius: $border-radius,
    $border-width: $border-width
  );
  // background-color: var(--neutral-80, $neutral-80);
  // border-color: var(--neutral-80, $neutral-80);
  // border-radius: $border-radius;
  // color: var(--text-gray, $text-gray);
  // font-family: $Roboto;

  &.dx-state-active,
  &:active,
  &.dx-state-hover,
  &:hover {
    @include btn-danger-hover(
      $border-radius: $border-radius,
      $border-width: $border-width
    );
  }

  &.dx-state-focused,
  &:focus {
    @include btn-danger-focus(
      $border-radius: $border-radius,
      $border-width: $border-width
    );
  }

  &.dx-state-focused.dx-state-active,
  &.dx-state-focused.dx-state-hover,
  &.dx-state-focused-active {
    @include btn-danger-focused-active(
      $border-radius: $border-radius,
      $border-width: $border-width
    );
  }

  &.dx-state-disabled,
  &[disabled],
  &[aria-disabled="true"] {
    @include btn-danger-disabled(
      $border-radius: $border-radius,
      $border-width: $border-width
    );
  }
}

/* cancel button states*/
@mixin btn-danger-hover(
  $border-radius: $border-radius,
  $border-width: $focus-border-width
) {
  @include state(
    $bg-color: var(--neutral-60, $neutral-60),
    $border-color: var(--neutral-60, $neutral-60),
    $border-radius: $border-radius,
    $font-color: var(--text-gray, $text-gray),
    $border-width: $border-width
  );
}

@mixin btn-danger-focus(
  $border-radius: $border-radius,
  $border-width: $focus-border-width
) {
  @include state(
    $bg-color: var(--neutral-80, $neutral-80),
    $border-color:
      var(--ds-color-ui-state-brand-focus, $ds-color-ui-state-brand-focus),
    $font-color: var(--text-gray, $text-gray),
    $border-style: inset,
    $border-width: $border-width,
    $border-radius: $border-radius,
    $outline-color:
      var(--ds-color-ui-state-brand-focus, $ds-color-ui-state-brand-focus)
  );
}

@mixin btn-danger-focused-active(
  $border-radius: $border-radius,
  $border-width: $focus-border-width
) {
  @include state(
    $bg-color: var(--neutral-60, $neutral-60),
    $border-color:
      var(--ds-color-ui-state-brand-focus, $ds-color-ui-state-brand-focus),
    $font-color: var(--text-gray, $text-gray),
    $border-style: inset,
    $border-width: $border-width,
    $border-radius: $border-radius,
    $outline-color:
      var(--ds-color-ui-state-brand-focus, $ds-color-ui-state-brand-focus)
  );
}

@mixin btn-danger-disabled(
  $border-radius: $border-radius,
  $border-width: $focus-border-width
) {
  @include state(
    $border-color: var(--neutral-90, $neutral-90),
    $bg-color: var(--neutral-90, $neutral-90),
    $border-width: $border-width,
    $border-radius: $border-radius,
    $font-color:
      var(
        --ds-color-ui-state-text-icon-disabled,
        $ds-color-ui-state-text-icon-disabled
      )
  );
  .dx-button-text,
  .dx-button-content {
    color: var(
      --ds-color-ui-state-text-icon-disabled,
      $ds-color-ui-state-text-icon-disabled
    ) !important;
  }
}

/* outlined button neutral state */
@mixin btn-outline($border-radius: $border-radius, $border-width: 1px) {
  @include state(
    $bg-color: transparent,
    $border-color: transparent,
    $border-width: $border-width,
    $font-color: var(--text-gray, $text-gray),
    $border-radius: $border-radius
  );
  outline-offset: -1px;
  &:not(.dx-dropdownbutton-action):not(.dx-dropdownbutton-toggle):not(
      .dx-state-disabled
    ):not([disabled]):not([aria-disabled="true"]) {
    border: $border-width solid
      var(--ds-color-ui-brand-outlined, $ds-color-ui-brand-outlined) !important;
  }

  .dx-button-text,
  .dx-button-content {
    color: var(--text-brand-outlined, $text-brand-outlined) !important;
  }
  &.dx-state-active,
  &:active,
  &.dx-state-hover,
  &:hover {
    @include btn-outline-hover(
      $border-radius: $border-radius,
      $border-width: $border-width
    );
  }

  &:not(.dx-dropdownbutton-action):not(.dx-dropdownbutton-toggle) {
    &.dx-state-focused,
    &:focus {
      @include btn-outline-focus(
        $border-radius: $border-radius,
        $border-width: $border-width
      );
    }

    &.dx-state-focused.dx-state-active,
    &.dx-state-focused.dx-state-hover,
    &.dx-state-focused-active {
      @include btn-outline-focused-active(
        $border-radius: $border-radius,
        $border-width: $border-width
      );
    }
  }

  &.dx-state-disabled,
  &[disabled],
  &[aria-disabled="true"] {
    @include btn-outline-disabled(
      $border-radius: $border-radius,
      $border-width: $border-width
    );
  }
}

@mixin btn-outline-hover($border-radius: $border-radius, $border-width: 1px) {
  @include state(
    $bg-color: var(--neutral-90, $neutral-90),
    $border-color: transparent,
    $font-color: var(--text-brand-outlined, $text-brand-outlined),
    $border-width: $border-width,
    $border-style: solid,
    $border-radius: $border-radius,
    $outline-color: transparent,
    $outline-width: 1px,
    $outline-offset: -1px
  );
}

@mixin btn-outline-focus($border-radius: $border-radius, $border-width: 1px) {
  @include state(
    $border-color:
      var(--ds-color-ui-state-brand-focus, $ds-color-ui-state-brand-focus),
    $border-style: inset,
    $border-width: $border-width,
    $border-radius: $border-radius,
    $font-color: var(--text-brand-outlined, $text-brand-outlined),
    $outline-color:
      var(--ds-color-ui-state-brand-focus, $ds-color-ui-state-brand-focus)
  );
  // outline: 1px solid
  //   var(--ds-color-ui-state-brand-focus, $ds-color-ui-state-brand-focus) !important;
}

@mixin btn-outline-focused-active(
  $border-radius: $border-radius,
  $border-width: 1px
) {
  @include state(
    $bg-color: var(--neutral-90, $neutral-90),
    $border-color:
      var(--ds-color-ui-state-brand-focus, $ds-color-ui-state-brand-focus),
    $border-style: inset,
    $border-width: $border-width,
    $border-radius: $border-radius,
    $font-color: var(--text-brand-outlined, $text-brand-outlined),
    $outline-color:
      var(--ds-color-ui-state-brand-focus, $ds-color-ui-state-brand-focus),
    $outline-width: 2px,
    $outline-offset: -2px
  );
}

@mixin btn-outline-disabled(
  $border-radius: $border-radius,
  $border-width: 1px
) {
  // outline-color: var(
  //   --ds-color-ui-state-text-icon-disabled,
  //   $ds-color-ui-state-text-icon-disabled
  // ) !important;
  @include state(
    $border-color:
      var(
        --ds-color-ui-state-text-icon-disabled,
        $ds-color-ui-state-text-icon-disabled
      ),
    $bg-color: transparent,
    $font-color:
      var(
        --ds-color-ui-state-text-icon-disabled,
        $ds-color-ui-state-text-icon-disabled
      ),
    $border-width: $border-width,
    $border-radius: $border-radius
  );
  .dx-button-text,
  .dx-button-content {
    color: var(
      --ds-color-ui-state-text-icon-disabled,
      $ds-color-ui-state-text-icon-disabled
    ) !important;
  }
}

@mixin dropdown-button(
  $border-radius: $border-radius,
  $border-width: $focus-border-width
) {
  height: $button-height;
  @include btn();
  &.dx-state-active,
  &:active,
  &.dx-state-hover,
  &:hover {
    @include state(
      $bg-color: var(--ds-color-ui-state-neutral-hover),
      $border-color: var(--ds-color-ui-state-neutral-hover),
      $border-radius: $border-radius,
      $border-width: $border-width
    );

    &.border-active {
      @include state(
        $bg-color: var(--ds-color-ui-state-neutral-hover),
        $border-color: var(--ds-color-ui-state-brand-focus),
        $border-radius: $border-radius,
        $border-width: $border-width,
        $border-style: inset
      );
    }
  }

  &.dx-state-focused,
  &:focus,
  &:has(.dx-button.dx-state-focused) {
    @include btn-focus(
      $border-radius: $border-radius,
      $border-width: $border-width
    );
    .dx-button.dx-state-focused {
      border-color: transparent !important;
    }
  }

  &.dx-state-focused.dx-state-active,
  &.dx-state-focused.dx-state-hover,
  &.dx-state-focused-active {
    @include btn-focused-active(
      $border-radius: $border-radius,
      $border-width: $border-width
    );
    @include state($border-width: 0px, $border-radius: $border-radius);
  }

  &.dx-state-disabled,
  &[disabled],
  &[aria-disabled="true"] {
    @include btn-disabled(
      $border-radius: $border-radius,
      $border-width: $border-width
    );
  }
  &:has(.dx-dropdownbutton-action):has(.dx-dropdownbutton-toggle) {
    .dx-dropdownbutton-action,
    .dx-dropdownbutton-toggle {
      &:hover,
      &.dx-state-hover {
        background-color: var(--neutral-80, $neutral-80) !important;
      }
    }
  }
}

// Mixin dla podstawowych właściwości przycisku tekstowego
@mixin text-button-base {
  background-color: transparent !important;
  border-color: transparent !important;
  border-width: 2px !important;
  border-radius: $border-radius !important;
  font-family: $Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 0.81rem;
  line-height: 15px;
  height: 26px;
  width: auto;
  position: relative;
  z-index: 2;
}

// Mixin dla hover efektu z podkreśleniem tekstu
@mixin hover-with-underline {
  border-width: 1px !important;
  border-color: transparent !important;
  .dx-button-text {
    text-decoration: underline;
  }
}

// Mixin dla dropdowneditor
@mixin dropdown-editor-states {
  &.dx-dropdowneditor-active {
    background-color: #fff;
    border: 2px inset transparent !important;
    input {
      color: var(--neutral-30, $neutral-30);
    }
    .dx-dropdowneditor-icon {
      &:before {
        color: var(--neutral-30, $neutral-30);
      }
    }
  }
}

/* CUSTOM BUTTON */

@mixin btn-customize(
  $border-radius: $border-radius,
  $border-width: $focus-border-width,
  $bg-color: transparent,
  $bg-color-hover: transparent,
  $border-color: transparent,
  $border-color-focus: transparent,
  $font-color: var(--text-brand-inverted, $text-brand-inverted)
) {
  @include btn($bg-color: $bg-color);
  @include state(
    $bg-color: $bg-color,
    $border-color: $border-color,
    $font-color: $font-color,
    $border-radius: $border-radius,
    $border-width: $border-width
  );

  & > .dx-button-content > .dx-icon,
  & > .dx-button-content > .dx-button-text {
    color: $font-color !important;
  }
  // background-color: var(--brand-10, $brand-10) !important;
  // border-color: transparent;
  // border-radius: $border-radius !important;
  // color: var(--text-brand-inverted, $text-brand-inverted) !important;

  &.dx-state-active,
  &:active,
  &.dx-state-hover,
  &:hover {
    @include btn-custom-hover(
      $border-radius: $border-radius,
      $border-width: $border-width,
      $bg-color-hover: $bg-color-hover
    );
  }

  &.dx-state-focused,
  &:focus {
    @include btn-custom-focus(
      $border-radius: $border-radius,
      $border-width: $border-width,
      $bg-color: $bg-color,
      $font-color: $font-color
    );
  }

  &.dx-state-focused.dx-state-active,
  &.dx-state-focused.dx-state-hover,
  &.dx-state-focused-active {
    @include btn-custom-focused-active(
      $border-radius: $border-radius,
      $border-width: $border-width
    );
  }

  &.dx-state-disabled,
  &[disabled],
  &[aria-disabled="true"] {
    @include btn-custom-disabled(
      $border-radius: $border-radius,
      $border-width: $border-width
    );
  }
}

/* confirm button states */
@mixin btn-custom-hover(
  $border-radius: $border-radius,
  $border-width: $focus-border-width,
  $bg-color-hover: var(--brand-00, $brand-00),
  $font-color: var(--text-brand-inverted, $text-brand-inverted)
) {
  @include state(
    $bg-color: $bg-color-hover,
    $border-color: $bg-color-hover,
    $border-radius: $border-radius,
    $font-color: $font-color,
    $border-width: $border-width
  );
}

@mixin btn-custom-focus(
  $border-radius: $border-radius,
  $border-width: $focus-border-width,
  $bg-color: var(--brand-10, $brand-10),
  $font-color: var(--text-brand-inverted, $text-brand-inverted),
  $border-color:
    var(--ds-color-ui-state-brand-focus, $ds-color-ui-state-brand-focus),
  $outline-color:
    var(--ds-color-ui-state-brand-focus, $ds-color-ui-state-brand-focus)
) {
  @include state(
    $bg-color: $bg-color,
    $border-color: $border-color,
    $font-color: $font-color,
    $border-style: inset,
    $border-width: $border-width,
    $border-radius: $border-radius,
    $outline-color: $border-color
  );
}

@mixin btn-custom-focused-active(
  $border-radius: $border-radius,
  $border-width: $focus-border-width
) {
  @include state(
    $bg-color: var(--brand-00, $brand-00),
    $border-color:
      var(--ds-color-ui-state-brand-focus, $ds-color-ui-state-brand-focus),
    $font-color: var(--text-brand-inverted, $text-brand-inverted),
    $border-style: inset,
    $border-width: $border-width,
    $border-radius: $border-radius,
    $outline-color:
      var(--ds-color-ui-state-brand-focus, $ds-color-ui-state-brand-focus)
  );
}

@mixin btn-custom-disabled(
  $border-radius: $border-radius,
  $border-width: $focus-border-width
) {
  @include state(
    $border-color: var(--neutral-90, $neutral-90),
    $border-radius: $border-radius,
    $border-width: $border-width,
    $bg-color: var(--neutral-90, $neutral-90),
    $font-color:
      var(
        --ds-color-ui-state-text-icon-disabled,
        $ds-color-ui-state-text-icon-disabled
      )
  );

  .dx-icon {
    &:before {
      color: var(
        --ds-color-ui-state-text-icon-disabled,
        $ds-color-ui-state-text-icon-disabled
      ) !important;
    }
  }

  .dx-button-text,
  .dx-button-content {
    color: var(
      --ds-color-ui-state-text-icon-disabled,
      $ds-color-ui-state-text-icon-disabled
    ) !important;
  }
}
