/* You can add global styles to this file, and also import other style files */
@use "codemirror/lib/codemirror";
@use "codemirror/theme/icecoder";

@use "./assets/scss/accordion.scss";
@use "./assets/scss/grid.scss";
@use "./assets/scss/dxScheduler.scss";
@use "./assets/scss/dxInput.scss";
@use "./assets/scss/notifier.scss";
@use "./assets/scss/button.scss";
@use "./assets/scss/menu.scss";
@use "./assets/scss/dragula.scss";

@use "./assets/scss/mobile.scss";
@use "./assets/scss/keyboard.scss";
@use "./assets/scss/fonts" as *;
@use "./assets/scss/colors";
@use "./assets/scss/typography.scss";
@use "./assets/scss/shadows.scss";
@use "./assets/scss/corners.scss";
@use "./assets/scss/popup.scss";
@use "./assets/scss/dxList.scss";
@use "./assets/scss/tabs.scss";
@use "./assets/scss/dxForm.scss";
@use "./assets/scss/roboto.scss";
@use "./assets/scss/switch.scss";
@use "./assets/scss/sidePanel.scss";
@use "./assets/scss/kanban.scss";
@use "./assets/scss/iconBtn.scss";
@use "./assets/scss/dxTreeList.scss";
@use "./assets/scss/dashboard.scss";
@use "./assets/scss/shepherd.scss";
@use "./assets/scss/dxFileUploaderIMG";
@use "./assets/scss/ngxPhotoEditorModule.scss";
@use "./assets/scss/wapro-assistant.scss";
@use "./app/gdpr/gdpr/documents/documents.component";
@use "./app/libraries/contractors-routes/routes-map/routes-map.component.scss" as *;
@use "./assets/scss/mixins" as *;
@use "./assets/scss/themes/themeLight" as *;
@use "./assets/scss/themes/themeDark" as *;

// bootstrap START
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body:not(.color-theme-preloading) {
  @include colorThemeTransition();
}

body.color-theme-light {
  @include colorThemeLight();
}

body.color-theme-dark {
  @include colorThemeDark();
  * {
    color-scheme: dark;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--text-gray, $text-gray);
  text-align: left;
  background-color: var(
    --ds-body-background-100,
    $ds-body-background-100
  ) !important;

  &.modal-open {
    @media (max-width: 1000px) {
      overflow-y: hidden;
    }
  }
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.d-inline-block {
  display: inline-block !important;
  cursor: pointer;
}

hr {
  margin: 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 1rem;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 576px) {
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

// bootstrap END

.dx-overlay-content.dx-loadpanel-content {
  width: 100vw !important;
  left: 0 !important;
  transform: none !important;
  height: 100vh !important;
  background: none !important;
  border: none !important;
  padding: 0 !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}

.dx-datagrid .dx-overlay-content.dx-loadpanel-content {
  background: var(--neutral-100, $neutral-100) !important;
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
}

// .dx-datagrid-bottom-load-panel {
//  height: 100% !important;
//  width: 100%;
//  display: flex;
//  align-items: center;
//  justify-content: center;
//  position: absolute;
//  left: 0;
//  right: 0;
//  top: 0;
//  bottom: 0;
//  margin: auto;
//  .dx-loadindicator {
//    width: 30px;
//    height: 30px;
//  }
// }
//powoduje błąd na gridzie - lista banków

*:focus {
  outline: none;
}

:root {
  --dynamic-font-size: 16px;
}

$font-size: var(--dynamic-font-size);

html {
  font-size: $font-size;
}

@media (min-width: 1201px) {
  body {
    overflow-y: hidden;
  }
}

.full-screen {
  width: 100%;
  height: 100%;
}

.noDataToDisplay {
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: var(--text-light-gray, $text-light-gray);
}

.error-box {
  padding: 4px 10px;
  background: var(--theme-negative-100, $theme-negative-100);
  border: 1px solid var(--theme-negative-85, $theme-negative-85);
  border-radius: 4px;
  width: 100%;
}

.warnign-box {
  padding: 4px 10px;
  background: var(--theme-warning-100, $theme-warning-100);
  border: 1px solid var(--theme-warning-85, $theme-warning-85);
  border-radius: 4px;
  width: 100%;
}

.p-shorcut {
  color: var(--neutral-50, $neutral-50);
  text-align: right;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  width: 250px;
  position: relative;
  top: -17px;
  margin-bottom: 0px;
  margin-bottom: -15px;
}

.main-menu .dx-submenu .dx-icon {
  display: none !important;
}

.main-menu .dx-submenu .dx-scrollable-container {
  width: 100% !important;
}

.main-menu .dx-menu-item {
  width: 70px;
}

.info-box {
  padding: 4px 10px;
  background: var(--theme-info-100, $theme-info-90);
  border: 1px solid var(--theme-info-85, $theme-info-85);
  border-radius: 4px;
  width: 100%;
}

.red-text {
  color: #bc1a22;
}

.no-copy {
  user-select: none; /* Blokuje zaznaczanie */
  -webkit-user-select: none; /* Dla przeglądarek WebKit (Chrome, Safari) */
  -moz-user-select: none; /* Dla Firefoksa */
  -ms-user-select: none; /* Dla IE/Edge */
}

.p-warning-text-box {
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  line-height: 18px;
  color: var(--text-gray, $text-gray);
  margin-bottom: 0px;
  display: inline-block;
}

.p-error-text-box {
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: var(--text-gray, $text-gray);
  margin-bottom: 0px;
  display: inline-block;
}

.box-type-document {
  width: 42px;
  height: 53px;
  position: absolute;
  left: 0px;
  top: 0px;
  border-radius: 0px 0px 8px 0px;
  padding: 13px 0px;
  text-align: center;
  z-index: 1;

  &_position-ver {
    padding-left: 15px;
    align-items: center;
    display: flex;
    width: 100%;
    height: 68px;
    box-shadow: var(--shadow-l);
  }

  &.with-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    top: 12px;
    left: 16px;

    i {
      position: absolute;
      z-index: 31;
      font-size: 21px;
      left: 10px;
      top: 8px;
    }

    i:before {
      color: inherit;
      margin: 0;
      font-size: inherit;
    }
  }

  p {
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 0px;
  }
}

.icon-warning-box {
  display: inline-block;
  color: var(--icons-orange, $icons-orange) !important;
  position: relative;
  top: 2px;
  margin-right: 2px;
}

.icon-error-box {
  display: inline-block;
  color: var(--text-brand, $text-brand) !important;
  position: relative;
  top: 2px;
  margin-right: 2px;
}

body {
  // background-color: var(--neutral-100, $neutral-100); // Overriden by THEME
  font-size: 10px;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 10px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $neutral-100;
  background: var(--neutral-100, $neutral-100); //THEME
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--neutral-60, $neutral-60);
  height: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--neutral-60, $neutral-60);
  height: 10px;
}

h2 {
  font-family: $GeomanistBook;
  font-size: 3rem;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  margin-bottom: 9px;
  color: var(--neutral-10, $neutral-10);

  strong {
    color: var(--theme-negative-30, $theme-negative-30);
    font-style: normal;
    font-weight: normal;
  }
}

h2,
h3,
h4,
h5,
h6 {
  font-weight: normal !important;
  font-family: $Roboto-Bold;
}

.a-link {
  text-decoration-line: none;
  font-family: "Roboto";
  font-style: normal;
  font-size: 0.75rem;
  line-height: 14px;
  font-weight: normal;

  &:hover {
    text-decoration-line: none;
  }
}

h1 {
  font-family: $Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 3.75rem;
  line-height: 72px;
  color: var(--text-gray, $text-gray);

  strong {
    font-family: $InterBold;
    font-weight: normal;
  }
}

*::-webkit-scrollbar {
  width: 2px;
}

.btn-filter-box b {
  margin-left: 5px;
}

.dx-radiogroup-horizontal .dx-collection {
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 17px;
  column-gap: 0px;
}

.addition-operation-content.dx-dropdownbutton-popup-wrapper.dx-dropdowneditor-overlay
  > .dx-overlay-content {
  background: var(--ds-background-dropdown-menu, $ds-background-dropdown-menu);
  box-sizing: border-box;
  border: 1px solid var(--neutral-80, $neutral-80);
  box-shadow: var(--shadow-m);
  border-radius: 8px;
  min-width: 502px !important;
  padding: 15px 10px;
}

.dx-scrollbar-vertical.dx-scrollbar-hoverable {
  width: 4px;
  padding-top: 2px;
  transition: none;
  /* transition: width .2s linear .15s,background-color .2s linear .15s; */
}

.dx-menu-item-has-submenu {
  .dx-scrollable-container {
    -webkit-tap-highlight-color: transparent;
    overflow: hidden;
    width: 100%;
    height: 100%;
    max-height: 500px;
  }
}

.dx-menu-item-expanded {
  .dx-menu-item-wrapper {
    max-height: 30px;
  }

  .dx-scrollable .dx-icon {
    display: none !important;
  }

  .dx-scrollable-container {
    -webkit-tap-highlight-color: transparent;
    overflow: hidden;
    width: 100%;
    height: 100%;
    max-height: 500px;
  }
}

.dx-scrollbar-horizontal.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active,
.dx-scrollbar-horizontal.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active
  .dx-scrollable-scroll,
.dx-scrollbar-horizontal.dx-scrollbar-hoverable.dx-state-hover,
.dx-scrollbar-horizontal.dx-scrollbar-hoverable.dx-state-hover
  .dx-scrollable-scroll {
  height: 12px;

  .dx-scrollable-scroll-content {
    background-color: var(--neutral-60, $neutral-60) !important;
  }
}

.dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active,
.dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active
  .dx-scrollable-scroll,
.dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-state-hover,
.dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-state-hover
  .dx-scrollable-scroll {
  width: 12px;

  .dx-scrollable-scroll-content {
    background-color: var(--neutral-60, $neutral-60) !important;
  }
}

.payment-form-currency {
  height: 38px !important;
}

.dx-scrollable-scroll-content {
  width: 100%;
  height: 100%;
  background: var(--neutral-70, $neutral-70);
  border-radius: 12px;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
}

a.confirme-info-message-link {
  text-decoration: none;
  font-family: "Roboto";
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;

  &_textDecoration {
    text-decoration: underline;
  }

  &_bussines-link {
    color: var(--text-brand, $text-brand) !important;
  }

  &_trialModeBL {
    color: var(--text-gray, $text-gray) !important;
    text-decoration: underline;
  }

  &:hover {
    text-decoration: underline;
  }
}

a {
  color: var(--neutral-40, $neutral-40);
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 0.81rem;
  line-height: 22px;
  text-decoration-line: underline;

  &:hover {
    color: var(--neutral-30, $neutral-30);
  }
}

.dx-submenu {
  background: var(--neutral-100, $neutral-100);
  margin-right: 5px;
  // width: 252px !important;
  border: 1px solid var(--neutral-80, $neutral-80) !important;
  box-shadow: var(--shadow-m) !important;
  // border-radius: 0px !important;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  min-width: 210px;
  padding: 6px 6px;
  border-radius: 8px;
  height: auto !important;
}

.menu-panel-user {
  .dx-submenu {
    background: var(--neutral-100, $neutral-100);
    margin-right: 5px;
    height: auto;
    width: 152px !important;
    border: 1px solid var(--neutral-80, $neutral-80) !important;
    box-shadow: var(--shadow-m) !important;
    border-radius: 0px !important;
    font-size: 0.75rem;
    position: relative;
    left: 0px;
    top: -6px;
    border-radius: 8px !important;
    padding: 5px 5px !important;
  }
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 15px;
}

.dx-context-menu-content-delimiter {
  background-color: transparent;
}

.h1-logo {
  font-family: $GeomanistBook;
  font-style: normal;
  font-weight: normal;
  font-size: 3.75rem;
  line-height: 72px;
  color: var(--neutral-30, $neutral-30);

  strong {
    color: var(--theme-negative-30, $theme-negative-30);
    font-style: normal;
    font-weight: normal;
    font-family: $InterBold;
    font-weight: normal;
  }
}

.dx-drawer-content {
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 32px);
  top: 0;
}

.top-menu-panel {
  height: 37px;
  width: calc(100%);
  margin-bottom: 12px;
  background: var(
    --ds-background-navigation-page-header,
    $ds-background-navigation-page-header
  );
  padding-top: 5px;
  padding-left: 5px;
  padding-bottom: 5px;
  margin-left: 0px;
  box-shadow: var(--shadow-m);
  white-space: nowrap;
  position: sticky;
  z-index: 2;
  overflow-x: hidden;
  overflow-y: hidden;
  top: 0px;

  @media (max-width: 1000px) {
    box-sizing: border-box;
    width: 100vw;
    position: sticky;
    z-index: 10;
    top: 40px;
    height: 46px;
  }

  .dx-scrollview-content {
    display: flex;
  }
}

app-custom-dropdown-box {
  margin-bottom: 10px;
}

.dx-datagrid-focus-overlay {
  border: none;
}

.dx-menu-item.dx-state-focused {
  background-color: transparent;
}

.dx-menu-item.dx-state-focused {
  color: var(--neutral-30, $neutral-30);
}

.top-menu .dx-menu-item.dx-state-focused .dx-menu-item-content {
  border-radius: 6px;
  height: 30px;
  border: 1px solid var(--neutral-30, $neutral-30) !important;
}

.main-panel {
  height: 100vh;
}

.center-panel-scroll-verticall {
  padding-top: 0px;
  padding-left: 10px;
  padding-right: 8px;
}

.dx-overlay-shader {
  pointer-events: auto;
  background: rgba(48, 57, 63, 0.75) !important;
}

body.color-theme-dark {
  .dx-overlay-shader {
    background: rgba(0, 0, 0, 0.75) !important;
  }
}

.dx-layout-manager
  .dx-label-h-align
  .dx-field-item-label
  .dx-field-item-label-content {
  display: block;
  text-align: right !important;
}

.dx-tooltip-wrapper .dx-overlay-content .dx-popup-content {
  display: inline-block;
  padding: 6px;
  font-size: 11px;
  border-radius: 4px;
  line-height: 14px;
  background: var(--neutral-10, $neutral-10);
  white-space: nowrap;
  color: var(--neutral-100, $neutral-100);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.dx-popover-wrapper.dx-position-bottom .dx-popover-arrow,
.dx-popover-wrapper.dx-position-top .dx-popover-arrow {
  //inset: -9px auto auto 48.7625px!important;
  width: 20px;
  height: 10px;
}

.dx-popover-wrapper .dx-popover-arrow::after {
  width: 10px;
  height: 10px;
}

.c-frame-row {
  h5 {
    position: relative;
    font-family: $Roboto-Medium;
    color: var(--text-light-gray, $text-light-gray);
    font-size: 0.75rem;
    line-height: 16px;
    width: 100%;
    border-bottom: 1px solid var(--neutral-80, $neutral-80);
    padding-bottom: 9px;
    margin-bottom: 16px;
    //margin-top: 24px;
  }
}

.dx-tooltip-wrapper.dx-popover-wrapper .dx-popover-arrow::after {
  background: var(--neutral-10, $neutral-10);
}

.dx-gallery-wrapper {
  margin-top: 12px;
}

.input-required .dx-texteditor-input-container::after {
  pointer-events: none;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  font-size: 1rem;
  font-family: Lumen;
  color: var(--text-brand, $text-brand);
  content: "\e002";
  left: calc(100% - 25px);
}

.BL-warning {
  position: relative;
}

.dx-texteditor-input-container:hover.text-danger {
  display: inline-block;
}

.text-danger {
  background-color: var(--text-brand, $text-brand);
  padding: 15px 10px 15px 10px;
  left: 85px;
  top: -15px;
  color: var(--neutral-100, $neutral-100) !important;
  border-radius: 6px;
  position: relative;
  display: none;
  margin-bottom: -39px;
  font-size: 0.625rem;
  width: auto;
  z-index: 10000;
}

.input-warning {
  border: 1px solid var(--theme-warning-50, $theme-warning-50) !important;
  background: var(--theme-warning-85, $theme-warning-85) !important;
}

.input-warning .dx-texteditor-input-container::after {
  pointer-events: none;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  font-size: 1rem;
  font-family: Lumen;
  color: var(--theme-warning-50, $theme-warning-50);
  content: "\e002";
  left: calc(100% - 25px);
}

.input-required {
  border: 1px solid var(--theme-negative-30, $theme-negative-30) !important;
  background: var(--theme-negative-100, $theme-negative-100) !important;
}

.input-required .dx-clear-button-area {
  display: none;
}

.input-required .dx-dropdowneditor-icon::before {
  color: var(--theme-negative-30, $theme-negative-30) !important;
}

.pad-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.summary-amount-correction {
  strong {
    font-family: "Roboto";
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    text-align: right !important;
    color: var(--text-brand, $text-brand) !important;
  }
}

.count-container {
  border: 1px solid var(--neutral-80, $neutral-80);
  background-color: var(
    --ds-background-tiles-panels,
    $ds-background-tiles-panels
  );
  padding: 10px;
  height: 59px;
  border-radius: 6px;

  p {
    font-family: $Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 14px;
    color: var(--text-dark-gray, $text-dark-gray);
    margin-top: 0;
    margin-bottom: 0;
  }

  p.total {
    margin-bottom: 0px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--text-light-gray, $text-light-gray) !important;
    margin-right: 7px;
    display: inline-block;
  }
}

.correction-label {
  font-family: "Roboto";
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  text-align: right !important;
  color: var(--neutral-30, $neutral-30) !important;

  strong {
    font-family: "Roboto";
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    text-align: right !important;
    color: var(--neutral-10, $neutral-10) !important;
  }
}

.summary-count-container {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: var(--text-light-gray, $text-light-gray) !important;
}

.difrentValue {
  margin-right: -4px;
  color: var(--neutral-10, $neutral-10);
  background-color: var(--tagfill-red, $tagfill-red);
  padding: 0px 4px 0px;
  border-radius: 4px;
  display: inline-block;
}

//.dx-row-focused .difrentValue {
//  color: orange !important;
//}

.count-container-correction {
  border: 1px solid var(--neutral-80, $neutral-80);
  background-color: var(--neutral-95, $neutral-95);
  padding: 10px;
  height: 59px;
  border-radius: 6px;

  p {
    font-family: $Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 14px;
    color: var(--text-dark-gray, $text-dark-gray);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.full-width,
.full-width .dx-widget {
  max-width: 100% !important;
  width: 100% !important;
}

.p-switch-text {
  position: relative;
  top: 2px;
  font-family: $Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 0.81rem;
  line-height: 15px;
  color: var(--brand-neutral-10, $brand-neutral-10);
  display: inline-block;
}

.gross-prices {
  color: var(--text-brand, $text-brand) !important;
}

.views input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  display: inline-block;
}

.views label {
  width: 20px;
  display: inline-block !important;
  padding: 2px 2px;
  cursor: pointer;
}

.checkmark {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 2px 2px;
  font-size: 1.31rem;
  line-height: 21px;
  margin-left: -10px;

  &::before {
    font-size: 1.31rem;
    line-height: 21px;
  }
}

.top-menu-popup {
  overflow: hidden;
  height: 35px;
  white-space: nowrap;
  overflow: hidden;
  z-index: 2;
  position: relative;
  background: var(--ds-background-tiles-panels, $ds-background-tiles-panels);
}

.left-side-panel,
.right-side-panel {
  position: relative;
  top: -40px;
  background: var(--neutral-95, $neutral-95);
  height: calc(100vh - 73px);
  padding: 0 10px;
  padding-top: 10px;
  margin-top: -10px !important;
}

.left-side-panel {
  // position: relative;
  // top: -13px;
  right: 15px;
  left: 0px;
  border-right: 1px solid var(--neutral-80, $neutral-80);
  background: var(--neutral-95, $neutral-95);
  // min-width: 0 !important;
  padding-left: 15px;
}

.right-side-panel {
  // position: relative;
  // top: -40px;
  right: 0px;
  left: 15px;
  border-left: 1px solid var(--neutral-80, $neutral-80);
  // background: var(--neutral-95, $neutral-95);
  // height: calc(100vh - 73px);
  // padding: 0 10px;
  padding-right: 15px;
  // padding-top: 10px;
  // margin-top: -10px !important;
}

.bottom-side-panel {
  position: relative;
  background: var(--ds-body-background-100, $ds-body-background-100);
  bottom: 0px;
  left: 0px;
  width: calc(100% + 5px);
  border-top: 1px solid var(--neutral-80, $neutral-80);
  min-height: 38px;
  margin-top: 4px;
  margin-left: -5px;
  padding-left: 6px;
  &_ingredientsDelivery {
    width: 100%;
  }
}

.bottom-icon-resize-up {
  width: 50px;
  height: 15px;
  background: var(--neutral-90, $neutral-90);
  border: 1px solid var(--neutral-80, $neutral-80);
  box-sizing: border-box;
  border-radius: 6px;

  &:hover {
    background: var(--neutral-85, $neutral-85);
  }

  top: -15px;
  position: absolute;
  right: calc(50% - 10px);
  z-index: 30000;

  &:active {
    cursor: row-resize;
  }
}

.bottom-icon-resize-down {
  width: 50px;
  height: 15px;
  background: var(--neutral-90, $neutral-90);
  border: 1px solid var(--neutral-80, $neutral-80);
  box-sizing: border-box;
  border-radius: 6px;

  &:hover {
    background: var(--neutral-85, $neutral-85);
  }

  top: -15px;
  position: absolute;
  right: calc(50% - 10px);
  z-index: 10001;

  &:active {
    cursor: row-resize;
  }
}

.views input:checked ~ .checkmark {
  padding: 2px 2px;
  border: 1px solid var(--brand-neutral-10, $brand-neutral-10) !important;
  border-radius: 6px !important;
}

mwlResizable {
  box-sizing: border-box; // required for the enableGhostResize option to work
}

.resize-handle-top,
.resize-handle-bottom {
  position: absolute;
  z-index: 10000;
  height: 5px;
  cursor: row-resize;
  width: 100%;
}

.resize-handle-top {
  top: 0;
  z-index: 10000;
}

.resize-handle-bottom {
  bottom: 0;
  z-index: 10000;
}

.resize-handle-left,
.resize-handle-right {
  position: absolute;
  height: 100%;
  cursor: col-resize;
  width: 5px;
}

.resize-handle-left {
  left: 0;
}

.resize-handle-right {
  right: 0;
}

.disabled_a {
  pointer-events: none;
  cursor: default;
}

.apps-box {
  width: 82px;
  height: 82px;
  border-radius: 8px;
  margin-bottom: 14px;
  box-sizing: border-box;
  padding-top: 16px;

  &:hover {
    cursor: pointer;
    background-color: var(--neutral-90, $neutral-90);
  }
}

.hexagon-red {
  width: 32px;
  height: 32px;
  margin-left: 25px;
  position: relative;
  float: left;
  text-align: right !important;
  margin-right: 25px;
  background-image: url("./assets/img/wapro_icon_red_48x48.svg");
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 8px;

  p {
    position: absolute;
    bottom: -9px;
    right: 3px;
    font-family: GeomanistBook;
    font-style: normal;
    font-size: 0.75rem;
    line-height: 94.5%;
    color: var(--text-brand-inverted, $text-brand-inverted);
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    text-transform: lowercase;
  }
}

.name-apps {
  font-family: "Roboto";
  color: var(--neutral-30, $neutral-30);
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 0.75rem;
}

@media (max-width: 1200px) {
  .text-danger {
    left: 0px;
    top: -15px;
  }
}

.columns-settings {
  background: transparent !important;
  position: absolute;
  right: 10px;
  top: -5px;
}

td.dx-command-select {
  width: 44px !important;
  min-width: 44px !important;
  max-width: 44px !important;
}

/* tree view - nowe */
.check-boxes-panel {
  position: relative;
  padding-left: 8px;
  // top: 20px;
}

.treeview-nests {
  // height: calc(100% - 150px) !important;
  margin-top: 10px;
  // padding-left: 10px;
  .dx-treeview-search {
    position: absolute;
    top: 0px;
    left: 115px;
    width: 320px;
    // position: absolute;
    // top: 10px;
    // position: absolute;
    // top: 15px;
    // right: 20px;
    // //width: calc(100% - 35px);
    // width: 320px;
    // max-width: none;
  }

  .dx-treeview-node.dx-state-selected {
    span {
      color: var(--text-black, $text-black);
    }

    > .dx-item {
      background-color: var(
        --ds-color-ui-state-row-focus,
        $ds-color-ui-state-row-focus
      );
    }
  }

  // .dx-scrollable.dx-visibility-change-handler.dx-scrollable-vertical.dx-scrollable-simulated {
  //   margin-top: 25px;
  // }
}

dx-tree-view {
  .dx-treeview-search {
    height: 26px !important;
    border-radius: 13px !important;
    border: 1px solid var(--neutral-50, $neutral-50) !important;
    font-size: 0.75rem;

    .dx-icon.dx-icon-search {
      display: none;
    }

    .dx-placeholder {
      top: -2px;
      left: -20px;
    }

    input.dx-texteditor-input {
      color: var(--neutral-30, $neutral-30);
      padding-left: 10px;
      padding-top: 8.5px;
    }

    .dx-icon.dx-icon-clear {
      color: var(--text-brand, $text-brand);
      margin-top: -16px !important;
      margin-left: -10px;
    }
  }

  .dx-scrollable-content > ul.dx-treeview-node-container {
    > li {
      // font-weight: 500;

      ul li {
        font-weight: 400;
        // height: 28px;
      }
    }
  }

  // .dx-treeview-node-container.dx-treeview-node-container-opened{
  //   li.dx-treeview-node{
  //     height: 28px;
  //   }
  // }

  .dx-treeview-toggle-item-visibility.dx-treeview-toggle-item-visibility-opened::before {
    font-family: Lumen;
    content: "\e5c5";
    font-size: 16px;
    width: 18px;
    height: 18px;
    padding-right: 2px;
  }

  .dx-treeview-toggle-item-visibility::before {
    font-family: Lumen;
    content: "\ea1d";
    font-size: 16px;
    width: 18px;
    height: 18px;
    padding-right: 2px;
  }
}

.permisionTree {
  .dx-treeview-node {
    .dx-treeview-node-container {
      padding-left: 20px;
    }

    padding-left: 0;

    .dx-item {
      height: 28px !important;
      padding-top: 5px !important;
    }

    li.dx-treeview-node .dx-item {
      height: 28px;

      > .dx-item-content.dx-treeview-item-content {
        height: 17px !important;
        margin-left: 5px;
      }
    }

    .dx-treeview-toggle-item-visibility {
      position: absolute;
      left: 8px;
      top: 9px;
      height: 18px;
      width: 18px;
      margin-right: 2px;
    }

    .dx-treeview-item {
      padding: 6px 10px;
      padding-bottom: 15px;
    }

    .dx-treeview-item {
      min-height: 28px;
      height: 100% !important;
      position: relative;
      left: 0px;
      padding-left: 28px;
      top: 1px;
      width: 100%;
      display: table-cell;
      vertical-align: middle;
      width: 100vw;
    }

    .dx-item.dx-state-hover {
      background-color: var(
        --ds-color-ui-state-neutral-hover,
        $ds-color-ui-state-neutral-hover
      );
      // border-radius: 14px;
    }

    &.dx-state-focused {
      > .dx-item {
        color: var(--text-brand, $text-brand) !important;
        border: none !important;
        //border-radius: 14px !important;
      }
    }

    // &.dx-state-selected{
    //   > .dx-item{
    //     border-radius: 14px;
    //   }
    // }
  }
}

.dx-treeview-node {
  .dx-treeview-node-container {
    padding-left: 20px;
  }

  padding-left: 0;

  .dx-item {
    height: 28px !important;
  }

  li.dx-treeview-node .dx-item {
    height: 28px;

    > .dx-item-content.dx-treeview-item-content {
      height: auto;
      margin-left: 5px;
    }
  }

  .dx-treeview-toggle-item-visibility {
    position: absolute;
    left: 8px;
    top: 9px;
    height: 18px;
    width: 18px;
    margin-right: 2px;
  }

  .dx-treeview-item {
    padding: 5px 10px;
  }

  .dx-treeview-item {
    min-height: 28px;
    height: 100% !important;
    position: relative;
    left: 0px;
    padding-left: 28px;
    top: 1px;
    width: 100%;
    display: table-cell;
    vertical-align: middle;
    width: 100vw;
  }

  .dx-item.dx-state-hover {
    background-color: rgba(0, 0, 0, 0.04);
    // border-radius: 14px;
  }

  &.dx-state-focused {
    > .dx-item {
      border: none !important;
      //border-radius: 14px !important;
    }
  }

  // &.dx-state-selected{
  //   > .dx-item{
  //     border-radius: 14px;
  //   }
  // }
}

.left-side-panel dx-tab-panel {
  .dx-item.dx-tab {
    .dx-tab-text {
      font-size: 0.75rem;
      font-weight: 500;
    }
  }
}

dx-tab-panel {
  @media (min-width: 1001px) {
    .dx-tabs-scrollable {
      margin-right: 30px !important;
    }
  }

  .dx-tabs-nav-button-right {
    margin-right: 5px;
  }

  .dx-state-active {
    .dx-icon.dx-icon-chevronprev,
    .dx-icon.dx-icon-chevronnext {
      &::before {
        color: var(--text-brand, $text-brand);
      }
    }
  }

  .dx-icon.dx-icon-chevronprev {
    &::before {
      position: relative;
      top: -5px;
      left: 1px;
      width: 16px;
      height: 16px;
      font-family: Lumen;
      font-size: 16px;
      line-height: 16px;
      content: "\e317";
    }
  }

  .dx-icon.dx-icon-chevronnext {
    &::before {
      position: relative;
      top: -5px;
      left: 1px;
      width: 16px;
      height: 16px;
      font-family: Lumen;
      font-size: 16px;
      line-height: 16px;
      content: "\e318";
    }
  }
}

.dx-sortable-placeholder {
  border: 1px dashed var(--neutral-40, $neutral-40) !important;
  //border-radius: 14px !important;
  //background-color: var(--neutral-90, $neutral-90) !important;
  //background-color: var(--neutral-85, $neutral-85) !important;
  //z-index: 999;
  //border: none;
  background-color: var(--neutral-10, $neutral-10) !important;
  opacity: 0.05;
}

.dx-state-readonly .dx-texteditor-buttons-container {
  border-left: none;
  display: none;
}

.no-max-width {
  max-width: none !important;
}

.txtbot-as-dropdown-text {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.btn-as-dropdown-icon {
  border: 1px solid var(--neutral-70, $neutral-70) !important;
  border-left: 0 !important;
  border-radius: 6px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  height: 28px !important;
  position: absolute;

  > div {
    padding-top: 0;
  }

  i {
    padding-top: 0;
  }

  i::before {
    font-size: 18px !important;
  }
}

.dx-scheduler-appointment-tooltip-wrapper.dx-tooltip-wrapper.dx-popover-wrapper
  .dx-popover-arrow::after {
  background: var(--neutral-100, $neutral-100);
  border: none;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);
}

.dx-scheduler-appointment-tooltip-wrapper {
  .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-hover {
    background-color: var(--neutral-100, $neutral-100);
  }

  .dx-list:not(.dx-list-select-decorator-enabled)
    .dx-list-item.dx-state-focused {
    background: var(--neutral-100, $neutral-100);
    color: var(--neutral-30, $neutral-30);
  }
}

.btn-header {
  display: inline-block;
  width: 32px;
  height: 32px;
  background-color: var(--brand-neutral-10, $brand-neutral-10);
  box-shadow: var(--shadow-m);

  &:hover {
    cursor: pointer;
    background-color: var(--neutral-10, $neutral-10);
  }
}

.btn-header-active {
  display: inline-block;
  width: 32px;
  height: 32px;
  background-color: var(--neutral-100, $neutral-100);
  box-shadow: var(--shadow-m);

  &:hover {
    cursor: pointer;
    background-color: var(--neutral-100, $neutral-100) !important;
  }
}

.dx-list-next-button .dx-button {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 14px;
  padding: 0 3em;
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 4px;
}

.btn-header-active {
  background-color: var(--neutral-100, $neutral-100);
  box-shadow: var(--shadow-m);
  width: 32px;
  height: 32px;

  &:hover {
    cursor: pointer;
    background-color: var(--neutral-30, $neutral-30);
  }
}

.btn-header-active.icon:before,
.btn-header-active .icon:before {
  font-style: normal;
  color: var(--neutral-30, $neutral-30);
  font-size: 18px !important;
}

.btn-header-active.icon:hover::before {
  background-color: transparent;
  color: var(--neutral-100, $neutral-100) !important;
}

.queue.btn-header-active.icon:hover::before {
  background-color: transparent;
  color: var(--neutral-30, $neutral-30) !important;
}

.btn-header.icon:before,
.btn-header .icon:before {
  font-style: normal;
  font-size: 18px !important;
}

.btn-header.icon:hover::before {
  background-color: transparent;
}

.dx-progressbar-status {
  display: none;
  position: relative;
  top: 0;
  left: 0;
  width: auto;
  height: 20px;
  font-size: 0.75rem;
}

.dx-progressbar-range {
  position: relative;
  border: 1px solid var(--accent-teal, $accent-teal);
  background-color: var(--accent-teal, $accent-teal);
  margin-top: -1px;
  box-sizing: content-box;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.progress-bar-packing .dx-progressbar-range {
  display: block;
  background-color: var(--text-positive, $text-positive);
  width: 100%;
}

.progress-bar-packing-danger .dx-progressbar-range {
  display: block;
  background-color: var(--text-negative, $text-negative);
  width: 100%;
}

.dx-progressbar-percent {
  color: var(--text-light-gray, $text-light-gray);
  display: inline-block;
  margin-left: 10px;
}

.dx-progressbar-container {
  height: 4px;
  border: none;
  background-color: var(--neutral-85, $neutral-85);
  border-radius: 20px;
}

.right-header-btn {
  top: 0px;
  right: 0px;
  position: absolute;
  height: 26px;
  padding-left: 20px;
  padding-right: 6px;

  &.with-left-border {
    border-left: 1px solid var(--neutral-80, $neutral-80);
  }
}

.btn-dx-top-menu-folder:last-of-type {
  margin-right: 0;
}

.close-grid {
  &::before {
    font-size: 1rem !important;
    color: var(--neutral-60, $neutral-60);
  }
}

.close-grid-mobile {
  &::before {
    font-size: 20px !important;
    color: var(--neutral-60, $neutral-60);
    margin-right: 6px;
    position: relative;
    top: 3px;
  }
}

.p-toPay-net {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  text-align: right !important;
  color: var(--text-gray, $text-gray) !important;
}

.des-toPay {
  font-family: "Roboto";
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  text-align: right !important;
  color: var(--text-light-gray, $text-light-gray) !important;
}

.ok-grid {
  &::before {
    font-size: 1.125rem !important;
  }
}

.filter-box {
  display: inline-block;
  height: 26px;
  box-sizing: border-box;
  border-radius: 29px;
  background: rgba(0, 0, 0, 0.04);
  padding-left: 7px;
  padding-right: 7px;
  font-size: 0.75rem;
  line-height: 14px;
  padding-top: 5px;
  margin-right: 5px;
}

.check-grid {
  &::before {
    font-size: 1rem !important;
    color: var(--neutral-10, $neutral-10);
  }
}

.check-grid-mobile {
  &::before {
    font-size: 20px !important;
    color: var(--neutral-10, $neutral-10);
    margin-right: 6px;
    position: relative;
    top: 3px;
  }
}

.articleStatusT {
  padding: 2px 3px;
  height: 18px;
  min-width: 18px;
  box-sizing: border-box;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  margin: 0 auto;
  margin-right: 2px;
  background-color: var(--tagfill-blue-grey, $tagfill-blue-grey) !important;
  color: var(--tagtxt-blue-grey, $tagtxt-blue-grey);
}

.articleStatusU {
  padding: 2px 3px;
  height: 18px;
  min-width: 18px;
  box-sizing: border-box;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  margin: 0 auto;
  margin-right: 2px;
  background-color: var(--tagfill-light-blue, $tagfill-light-blue) !important;
  color: var(--tagtxt-light-blue, $tagtxt-light-blue);
}

.articleStatusP {
  padding: 2px 3px;
  height: 18px;
  min-width: 18px;
  box-sizing: border-box;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  margin: 0 auto;
  margin-right: 2px;
  background-color: var(--tagfill-yellow, $tagfill-yellow) !important;
  color: var(--tagtxt-yellow, $tagtxt-yellow);
}

.articleStatusO {
  padding: 2px 3px;
  height: 18px;
  min-width: 18px;
  box-sizing: border-box;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  margin: 0 auto;
  margin-right: 2px;
  background-color: var(--tagfill-deep-orange, $tagfill-deep-orange) !important;
  color: var(--tagtxt-deep-orange, $tagtxt-deep-orange);
}

.articleStatusM {
  padding: 2px 3px;
  height: 18px;
  min-width: 18px;
  box-sizing: border-box;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  margin: 0 auto;
  margin-right: 2px;
  background-color: var(--tagfill-deep-teal, $tagfill-deep-teal) !important;
  color: var(--tagtxt-deep-teal, $tagtxt-deep-teal);
}

.articleStatusZ {
  padding: 2px 3px;
  height: 18px;
  min-width: 18px;
  box-sizing: border-box;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  margin: 0 auto;
  margin-right: 2px;
  background-color: var(--tagfill-deep-indigo, $tagfill-deep-indigo) !important;
  color: var(--tagtxt-deep-indigo, $tagtxt-deep-indigo);
}

.stockForecastEqual0 {
  padding: 2px 3px;
  height: 18px;
  min-width: 18px;
  box-sizing: border-box;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  background-color: var(--tagfill-brown, $tagfill-brown) !important;
  color: var(--neutral-10, $neutral-10);
  margin: 0 auto;
  margin-right: -5px;
}

.stockForecastLeesThan0 {
  padding: 2px 3px;
  height: 18px;
  min-width: 18px;
  box-sizing: border-box;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  background-color: var(--tagfill-deep-orange, $tagfill-deep-orange) !important;
  color: var(--neutral-10, $neutral-10);
  margin: 0 auto;
  margin-right: -5px;
}

.statusN,
.statusZ,
.statusR,
.statusO,
.statusY,
.statusW,
.statusP,
.statusC,
.statusWkanban,
.statusNkanban,
.statusZkanban,
.statusAkanban {
  padding: 2px 3px;
  height: 18px;
  min-width: 18px;
  box-sizing: border-box;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  color: var(--neutral-100, $neutral-100);
  margin: 0 auto;
  margin-right: 2px;
}

.statusWkanban {
  background-color: var(--tagfill-light-green, $tagfill-light-green) !important;
  color: var(--tagtxt-light-green, $tagtxt-light-green);
}

.statusNkanban {
  background-color: var(--tagfill-blue-grey, $tagfill-blue-grey) !important;
  color: var(--tagtxt-blue-grey, $tagtxt-blue-grey);
}

.statusZkanban {
  color: var(--tagtxt-teal, $tagtxt-teal) !important;
  background-color: var(--tagfill-teal, $tagfill-teal);
}

.statusAkanban {
  color: var(--tagtxt-red, $tagtxt-red) !important;
  background-color: var(--tagfill-red, $tagfill-red);
}

.statusN {
  background-color: var(--accent-blue-grey, $accent-blue-grey) !important;
}

.statusZ {
  background-color: var(--accent-teal, $accent-teal) !important;
}

.statusR {
  background-color: var(--accent-light-green, $accent-light-green) !important;
}

.statusO {
  background-color: var(--accent-blue-grey, $accent-blue-grey) !important;
}

.statusY {
  background-color: var(--accent-red, $accent-red) !important;
}

.statusW {
  background-color: var(--tagfill-light-blue, $tagfill-light-blue) !important;
}

.statusP {
  background-color: var(--tagfill-green, $tagfill-green) !important;
}

.statusC {
  background-color: var(--tagfill-amber, $tagfill-amber) !important;
}

.statusNull {
  padding-left: 19px;
  padding-top: 1px;
}

app-custom-dropdown-box {
  display: inline-block;

  & + .dx-button.dx-button-secondary {
    min-width: 30px;
    height: 30px;
    flex: 0 0 30px;

    .icon:before {
      font-size: 18px;
    }

    &.dx-state-focused,
    &:focus {
      border: 2px inset transparent !important;
    }

    .dx-button-content {
      padding: 0;
    }
  }
}

.form-check {
  .dx-checkbox-container {
    width: 16px;
  }

  // &.no-padding{
  //   padding-left: 0 !important;
  //   .dx-field-item-label-content{
  //     max-width: 118px;
  //   }
  // }
}

.dx-tabpanel > .dx-tabpanel-tabs .dx-tabs {
  background-color: transparent;
}

@media (max-width: 1000px) {
  .dx-tabpanel-container {
    margin-top: 10px !important;
  }
}

dx-tab-panel.form-tab .dx-tabpanel-container {
  margin-top: 0 !important;
  padding-top: 24px !important;
}

.dx-tab.dx-state-hover {
  background-color: transparent;
}

.panel-mode {
  display: inline-block;
  box-sizing: border-box;
  width: 76px;
  position: relative;
  left: -38px;
  top: 5px;

  border-bottom: 1px solid var(--neutral-80, $neutral-80);

  &::after {
    content: "";
    background-color: var(--neutral-80, $neutral-80);
    position: absolute;
    width: 1px;
    height: 20px;
    top: 0px;
    left: 91px;
    display: block;
  }
}

// .dx-submenu .dx-menu-item-wrapper {
// height: 30px;
// }

.dx-menu-item.dx-state-hover {
  border-radius: 6px;
  background-color: var(--neutral-90, $neutral-90);
}

// .main-menu.dx-menu-base {
// max-width: 76px;
// }

.main-menu.dx-menu {
  position: relative;
  width: 76px;
}

.main-menu .dx-menu-item.dx-state-hover {
  border-radius: 4px;
  background-color: var(
    --ds-color-ui-state-neutral-hover,
    $ds-color-ui-state-neutral-hover
  ) !important;

  .submenu-icon-show {
    display: block;
  }
}

.main-menu .dx-menu-item-expanded {
  border-radius: 4px;
  background-color: var(--neutral-90, $neutral-90) !important;
}

.top-menu .dx-menu-item-expanded {
  background-color: transparent !important;

  .dx-menu-item-content {
    // background-color: var(--neutral-85, $neutral-85);
    border-radius: 8px 8px 0 0;
  }
}

.dx-menu-base .dx-menu-item-content {
  padding: 8px 15px 4px;
}

.menu-panel-user.dx-menu-base .dx-menu-item-content {
  padding: 4px 5px 4px;
}

.dx-context-menu .dx-menu-item {
  .icon {
    color: var(--neutral-30, $neutral-30) !important;
  }
}

.dx-context-menu
  .dx-menu-no-icons
  > .dx-menu-item-wrapper
  > .dx-menu-item
  > .dx-menu-item-content
  .dx-menu-item-text {
  font-family: "Roboto";
  font-style: normal;
  font-size: 0.75rem;
  line-height: 14px;
  font-weight: normal;
}

.label-mode {
  width: 38px;
  display: inline;

  &:hover {
    .icon {
      color: var(--text-brand, $text-brand) !important;
    }
  }

  /* HIDE RADIO */
  [type="checkbox"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* IMAGE STYLES */
  [type="checkbox"] + i {
    border-bottom: 1px solid var(--neutral-80, $neutral-80);
    cursor: pointer;

    &::before {
      font-size: 18px;
      margin-left: 9px;
      margin-top: 26px;
      position: relative;
      top: 3px;
    }
  }

  /* CHECKED STYLES */
  [type="checkbox"]:checked + i {
    border-bottom: 2px solid var(--text-brand, $text-brand);
  }
}

[hidden] {
  display: none !important;
}

/* HIDE RADIO */
[type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */
[type="radio"] + i {
  cursor: pointer;
}

/* CHECKED STYLES */
[type="radio"]:checked + i {
  border-bottom: 2px solid var(--text-brand, $text-brand);
}

.mode-active {
  &.btn-dx-top-menu .dx-icon::before {
    color: var(--text-brand, $text-brand);
  }

  &.btn-dx-top-menu:hover .dx-icon::before {
    color: var(--text-brand, $text-brand) !important;
  }
}

.hov-underline {
  font-family: $Roboto-Medium;
  cursor: pointer;
  color: var(--text-brand, $text-brand);

  &:hover {
    text-decoration: underline;
  }
}

.scroll-height .dx-scrollable-container {
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-height: 85vh !important;
}

.menu-search-list .dx-scrollable-container {
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-height: 100% !important;
}

.check-l .dx-field-item-content .dx-checkbox-icon {
  float: left;
}

.resize-bottop-panel {
  padding-top: 6px;
}

.check-cont-min .dx-checkbox-container {
  width: 40px;
}

.dx-checkbox-icon {
  font-size: 16px;
}

.dx-context-menu .dx-menu-item {
  border-radius: 6px;
  display: inline-block;
  position: relative;
  height: 30px;
  width: 100%;
  font-family: "Roboto";
  font-style: normal;
  font-size: 0.75rem;
  line-height: 14px;
}

// ::before {
//   content: "";
//   background-image: url('https://placekitten.com/1200/800');
//   background-size: cover;
//   position: absolute;
//   top: 0px;
//   right: 0px;
//   bottom: 0px;
//   left: 0px;
//   opacity: 0.75;
// }

.box-bg-none-even {
  position: relative;

  &::before {
    z-index: 0;
    background-image: url("./assets/img/empty-box.svg");
    content: "";
    //background-image: url('https://placekitten.com/1200/800');
    background-size: 100% 100%;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 25px;
    left: 0px;
  }

  // background-size: 100% 100%;
}

.opacity-bg::before {
  opacity: 0.3;
}

.box-bg-none-odd {
  position: relative;

  &::before {
    z-index: 0;
    background-image: url("./assets/img/empty-box-2.svg");
    content: "";
    background-size: 100% 100%;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 25px;
    left: 0px;
  }

  background-size: 100% 100%;
}

.dx-progressbar-range {
  position: relative;
  border: none;
  margin-top: -1px;
  box-sizing: content-box;
  border-radius: 20px;
  border-radius: 20px;
}

// nowe zadanie -> pozycja

.dx-loadindicator-icon .dx-loadindicator-segment {
  position: absolute;
  width: 19%;
  height: 30%;
  left: 44.5%;
  top: 37%;
  opacity: 0;
  background: none;
  border-radius: 0;
  box-shadow: none;
  animation: none;
}

.dx-loadindicator {
  background-color: transparent;
  width: 48px;
  height: 8px;
  padding: 0px 0px;
  margin-top: -5px;
}

.dx-loadindicator-icon {
  position: relative;
  top: 15%;
  left: 15%;
  width: 100%;
  background: url(./assets/img/secondary_loader.gif);
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
}

.dx-treeview .dx-empty-message {
  color: transparent;
  position: relative;
  width: 100%;
  margin: calc((100vh - 172px) / 2) auto 0;
  background: url(./assets/img/secondary_loader.gif) center no-repeat;
  background-size: 100px 4px !important;
}

.edocuments {
  .dx-fileuploader-wrapper {
    padding: 0px;
  }

  .dx-fileuploader-input-wrapper {
    padding: 0px 0;
    border: 0px dashed transparent;
  }

  .dx-fileuploader-input-wrapper::before {
    padding-top: 0em;
    margin-top: 0em;
  }

  .dx-fileuploader-input-wrapper::after {
    clear: both;
    padding-bottom: 0em;
    margin-bottom: 0em;
  }

  .dx-button-mode-contained {
    background-color: var(--neutral-100, $neutral-100);
    border-color: transparent;
    color: var(--text-dark-gray, $text-dark-gray);
    padding-left: 12px;
    padding-right: 12px;
    height: 26px;
  }

  .dx-state-hover.dx-fileuploader-button {
    background: var(--neutral-85, $neutral-85);
  }

  .dx-button-text {
    display: none;
  }

  .dx-button-mode-contained::after {
    font-family: "Lumen";
    content: "\e147";
    color: var(--neutral-30, $neutral-30);
    font-size: 1.125rem !important;
    -webkit-text-stroke-width: 0.012em !important;
    -webkit-font-smoothing: antialiased !important;
    position: relative;
    top: -3px;
    left: -22px;
    border-radius: 4px;
  }
}

.chips-box {
  position: relative;

  &.chips-pad-left-icon {
    padding-left: 15px;
  }

  .icon-pin {
    position: absolute;
    top: 5px;
    left: 6px;
    font-size: 14px !important;
  }

  .chips-close {
    top: 4px;
  }
}

.dxc-val-title text {
  font-family: Roboto !important;
  font-size: 0.75rem !important;
  color: var(--neutral-40, $neutral-40);
}

.dxc-val-grid path {
  stroke: var(--neutral-85, $neutral-85);
  stroke-dasharray: 8;
}

.dxc-axes-group {
  .dxc-val-axis,
  .dxc-arg-axis {
    path {
      stroke: var(--neutral-50, $neutral-50);
    }
  }
}

.no-max-width {
  max-width: none !important;
}

.header-document {
  background: var(--ds-background-box, $ds-background-box);
  border: 1px solid var(--neutral-80, $neutral-80);
  box-sizing: border-box;
  border-radius: 6px;
  margin: 16px 0px;
  padding: 10px 10px;
  transition-duration: 250ms;

  &_position-verification {
    margin-top: 10px;
  }
}

.p-header-document {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 14px;
  color: var(--text-light-gray, $text-light-gray);
  margin-bottom: 10px;
  display: inline-block;
  margin-left: 10px;
}

.header-document .dx-icon-spinup {
  float: right;
  position: relative;
  top: 3px;
  color: var(--text-brand, $text-brand);
  margin-right: 10px;
}

.icon-top-2-5 {
  position: relative;
  top: -3.5px;
}

.icon-top {
  position: relative;
  // top: -2px;
}

.header-document .absui-icon--arrow-drop-down-box {
  float: right;
  position: relative;
  top: -14px;
  color: var(--neutral-30, $neutral-30);
  margin-right: 10px;
}

.box-body {
  padding: 0px 0px;
  padding-right: 30px;
  border-right: 1px solid var(--neutral-80, $neutral-80);
  text-align: right;
  width: auto;
  //flex: 1 1 0;
  // min-width: 470px;
  // max-width: 540px;
}

.no-display {
  display: none !important;
}

@media (max-width: 575.98px) {
  .box-body {
    padding: 0px 35px;
  }
}

.justify-content-center-header {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min-content, 410px));
  grid-gap: 0px 15px;
  padding: initial;
  justify-content: center;
}

.justify-content-left-header {
  display: grid;
  grid-template-columns: minmax(min-content, 400px) minmax(min-content, 450px);
  grid-gap: 0px 15px;
  padding: initial;
  justify-content: left;
}

.p-toPay {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  text-align: right !important;
  color: var(--text-brand, $text-brand) !important;
}

.p-toPayDesc {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 0.75rem;
  line-height: 14px;
  text-align: right;
  color: var(--text-light-gray, $text-light-gray) !important;
}

.emptyState {
  text-align: center;
  text-align: -webkit-center;
  display: table-cell;
  vertical-align: middle;
}

.p-emptyState {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: var(--text-light-gray, $text-light-gray);

  strong {
    font-family: "Inter";
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 22px;
    text-align: center;
    color: var(--text-light-gray, $text-light-gray);
  }
}

.p-header-info {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 14px;
  color: var(--text-light-gray, $text-light-gray);
  margin-bottom: 0px;
}

.contener-pay {
  width: auto;
  min-width: 108px;
  padding-left: 15px;
  border-left: 1px solid var(--neutral-80, $neutral-80);
  float: right;
}

.dxc-tooltip {
  z-index: 9999 !important;
}

.dx-editor-cell.dx-editor-inline-block:not(.dx-command-select)::before {
  content: none;
}

.main-column-tooltip-src {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

//skeleotn loading

.pulse {
  //animation: pulse 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  animation: wave-lines 3s infinite linear;
  //@at-root: 0.5s;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes wave-lines {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.preloader-img {
  background-image: url("./assets/img/preloader.svg");
  width: 48px;
  height: 48px;
  background-size: 100% 100%;
}

.rel-100h {
  position: relative;
  height: 100%;
}

.form-group-inline-right .btn-dx-secondary {
  margin-bottom: 6px;
}

.inner-form-header-padding-top {
  padding-top: 18px;
}

// formularz - odstepy miedzy boxami

.popup-form {
  .flex-box-wrapper {
    gap: 6px 60px;
  }

  .flex-box-column {
    display: grid;
    gap: 24px 0;
    height: 100%;
  }

  .flex-box-column-content {
    gap: 6px 0;
    display: grid;

    .box-row {
      position: relative;
      min-height: 28px;
      // do testow
      // dx-check-box{
      //   height: 28px;
      // }
      // dx-drop-down-box{
      //   position: absolute;
      // }
    }
  }

  //usunięcie marginesów z dołu dla kontrolek i buttonów
  .form-group-inline-left,
  .form-group-inline-right {
    dx-select-box,
    dx-text-box,
    dx-number-box,
    dx-drop-down-box,
    dx-date-box,
    dx-text-area,
    dx-check-box {
      margin-bottom: 0 !important;
    }

    label {
      margin-bottom: 4px;
    }
  }

  .form-group-inline-right .btn-dx-secondary {
    margin-bottom: 0 !important;
  }

  .grid-row-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px 0px;
  }

  .grid-column-container {
    display: flex;
    gap: 0 60px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .flex-gap-element {
    gap: 6px 0;
  }
}

.dx-popover-arrow {
  width: 10px !important;
  top: -6px !important;
  background: var(--neutral-100, $neutral-100) !important;
  transform: rotate(-45deg) !important;
  border-top: 1px solid var(--neutral-80, $neutral-80) !important;
  border-right: 1px solid var(--neutral-80, $neutral-80) !important;
}

.dx-overlay-wrapper.dx-popup-wrapper.dx-popover-wrapper.dx-tooltip-wrapper.dx-popover-without-title.dx-position-bottom {
  .dx-popover-wrapper.dx-position-bottom .dx-popover-arrow,
  .dx-popover-wrapper.dx-position-top .dx-popover-arrow {
    width: 20px;
    height: 10px;
    background: transparent !important;
    transform: none !important;
    border-top: none !important;
    border-right: none !important;
  }
}

.marginRight {
  margin-right: 86px;
}

.displayBlockBtn {
  display: block;
  margin-bottom: 6px;
}

.moreOptionBtn {
  background: var(--neutral-100, $neutral-100);
  border: 1px solid var(--neutral-80, $neutral-80);
  box-sizing: border-box;
  box-shadow: 0px 6px 16px rgb(35 38 42 / 10%);
  border-radius: 8px 8px 8px 8px;
  position: absolute;
  padding: 24px 15px 24px 15px;
  width: 250px;
  max-height: calc(100vh - 40px);
  right: 145px;
  top: 40px;
  text-align: left;
  z-index: 10000;
}

.moreOptionBtnReadOnly {
  right: 80px !important;
}

.moreOptionBtnOpened {
  border: 1px solid var(--neutral-30, $neutral-30) !important;
  border-radius: 8px 8px 8px 8px;
}

.dx-tooltip-wrapper .dx-popover-arrow {
  width: 20px !important;
  top: -9px !important;
  background: transparent !important;
  transform: none !important;
  border-top: none !important;
  border-right: none !important;
}

.dx-position-top.dx-tooltip-wrapper .dx-popover-arrow {
  width: 20px !important;
  top: 29px !important;
  background: transparent !important;
  transform: none !important;
  border-top: none !important;
  border-right: none !important;
}

.bottom-panel-icon {
  cursor: pointer;
  display: inline-block;
  margin-top: 7px;
}

.bottom-panel-description {
  padding-left: 10px;
  padding-top: 20px;
}

.pointer {
  cursor: pointer;
}

.dx-tabpanel > .dx-tabpanel-tabs .dx-tabs-scrollable .dx-tabs-wrapper {
  display: table-row;
}

.editRowByMe {
  position: absolute;
  left: 1px;
  top: 3px;
  border-radius: 3px;
  width: 4px;
  height: 25px;
  background-color: var(--accent-red, $accent-red);
}

.editRowByAnotherUser {
  position: absolute;
  left: 1px;
  top: 3px;
  border-radius: 3px;
  width: 4px;
  height: calc(100% - 4px);
  background-color: var(--accent-light-blue, $accent-light-blue);
}

.greenStatus {
  position: absolute;
  left: 1px;
  top: 3px;
  border-radius: 3px;
  width: 4px;
  height: calc(100% - 4px);
  background-color: var(--accent-green, $accent-green);
}

.yellowStatus {
  position: absolute;
  left: 1px;
  top: 3px;
  border-radius: 3px;
  width: 4px;
  height: calc(100% - 4px);
  background-color: var(--accent-orange, $accent-orange);
}

.item-group-label {
  text-transform: capitalize;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 0.75rem;
  line-height: 14px;
  color: var(--text-light-gray, $text-light-gray);
}

.help-modal__container .help-modal {
  background-color: var(--ds-background-box, $ds-background-box) !important;
  box-shadow: var(--shadow-m) !important;

  .key {
    background-color: var(--neutral-90, $neutral-90);
    border-color: var(--neutral-60, $neutral-60);
  }
}

.help-modal__list .item {
  background-color: transparent !important;
  padding: 12px;
  border-bottom: 0.5px solid var(--neutral-80, $neutral-80);
}

.help-modal__list .description {
  min-width: 168px;
  font-family: "Roboto";
  font-style: normal;
  display: inline-block;
  font-size: 0.75rem;
  color: var(--text-dark-gray, $text-dark-gray) !important;
}

.item[_ngcontent-mtx-c294] {
  background-color: var(--neutral-10, $neutral-10) !important;
  padding: 12px;
}

.right-header-btn.panel-fix-pos {
  top: 0;
}

.dx-treeview-with-search > .dx-scrollable {
  height: 100%;
}

.d-flex-end {
  display: flex;
  justify-content: flex-end;
}

.mobile-text-area {
  width: 100% !important;
  max-width: 100% !important;

  textarea {
    width: 100% !important;
    max-width: 100% !important;
  }
}

ng-keyboard-shortcuts-help-item .item {
  font-size: 12px;
  font-family: Roboto;
}

.correctionInvoices.dx-dropdownbutton-popup-wrapper.dx-dropdowneditor-overlay
  > .dx-overlay-content {
  background: var(--ds-background-dropdown-menu, $ds-background-dropdown-menu);
  box-sizing: border-box;
  border: 1px solid var(--neutral-80, $neutral-80);
  box-shadow: var(--shadow-m);
  border-radius: 8px;
  min-width: 260px;
  padding: 5px 5px;
}

.dx-button.highlighted,
.dx-button.dx-state-hover.highlighted {
  border: 2px solid var(--theme-warning-50, $theme-warning-50) !important;
}

.dropDownBoxClassGrid .dx-row-focused {
  .dx-button:not(.btn-dx-top-menu-folder-grid) {
    &:hover,
    &.dx-state-hover {
      background-color: var(--neutral-30, $neutral-30) !important;
      border-color: var(--neutral-30, $neutral-30) !important;

      .dx-icon:before {
        color: var(--neutral-100, $neutral-100) !important;
      }
    }
  }
}

:fullscreen {
  @mixin fullscreen-button {
    $border-radius: 0;
    width: auto;
    height: 45px !important;
    min-width: 45px !important;
    background-color: transparent !important;
    border: 2px solid transparent !important;
    border-color: transparent;
    border-radius: $border-radius;
    box-shadow: none;
    margin-right: 6px;
    -moz-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;

    .dx-icon {
      margin-top: 0px !important;

      &::before {
        color: var(--neutral-30, $neutral-30);
        font-size: 25px !important;
        -webkit-text-stroke-width: 0.012em !important;
        -webkit-font-smoothing: antialiased !important;
      }
    }

    &:hover {
      height: 45px !important;
      background-color: var(--neutral-90, $neutral-90) !important;
      border-radius: $border-radius !important;
      border: 2px solid var(--neutral-90, $neutral-90) !important;
      border-color: var(--neutral-90, $neutral-90) !important;
    }

    &:focus-visible {
      height: 45px !important;
      background-color: transparent;
      border: 2px solid
        var(--ds-color-ui-state-brand-focus, $ds-color-ui-state-brand-focus) !important;
      border-radius: $border-radius !important;
      box-sizing: border-box;

      .dx-icon {
        &::before {
          color: var(--neutral-30, $neutral-30);
        }
      }
    }

    &:active {
      height: 45px !important;
      background-color: var(--neutral-80, $neutral-80) !important;
      border: 2px solid var(--neutral-80, $neutral-80) !important;
      border-radius: $border-radius !important;
      box-sizing: border-box !important;

      .dx-icon {
        &::before {
          color: var(
            --ds-color-ui-state-brand-focus,
            $ds-color-ui-state-brand-focus
          ) !important;
        }
      }

      .dx-button-text {
        color: var(
          --ds-color-ui-state-brand-focus,
          $ds-color-ui-state-brand-focus
        ) !important;
      }
    }
  }

  .top-menu-wrapper {
    display: none !important;
  }

  .dx-drawer-panel-content {
    display: none !important;
  }

  .dx-toolbar {
    display: none !important;
  }

  .btn-dx-top-menu {
    @include fullscreen-button();

    &:hover {
      .dx-button-text {
        color: var(--brand-neutral-10, $brand-neutral-10) !important;
      }
    }

    &:active {
      .dx-button-text {
        color: var(
          --ds-color-ui-state-brand-focus,
          $ds-color-ui-state-brand-focus
        ) !important;
      }
    }

    .dx-button-text {
      font-family: $Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 14px;
      margin-left: 0px;
      color: var(--text-light-gray, $text-light-gray);
    }
  }

  .btn-dx-top-menu-folder {
    @include fullscreen-button();

    .dx-button-content::after {
      display: none;
    }

    .dx-button-text {
      display: none;
    }
  }

  .scaner-container {
    display: inline-block;
    background-color: var(--neutral-80, $neutral-80);
    border-radius: 6px;
    height: 40px !important;
  }

  .inline-scaner-search-text-box {
    width: 320px;
    height: 35px !important;
    top: -6px !important;
    border-radius: 6px;
    border: 1px solid var(--neutral-100, $neutral-100);
    right: 1px;
    position: relative;
  }

  .scaner-mode-select {
    background: transparent !important;
    border-radius: 6px !important;
    height: 35px !important;
  }

  .dx-icon.dx-icon-barcode::before {
    content: "\eac5";
    font-family: Lumen;
    font-style: normal;
    font-size: 24px !important;
    position: relative;
  }

  .no-stroke-width::before {
    -webkit-text-stroke-width: 0;
    font-size: 21px !important;
    line-height: 16px;
    width: 16px;
    height: 16px;
    position: relative;
  }

  .left-inside-scaner-btn .dx-button-content i::before {
    font-size: 28px !important;
  }

  dx-select-box .dx-dropdowneditor-icon::before {
    content: "\e5c5";
    font-size: 28px !important;
    margin-left: -14px;
  }

  .dx-icon.dx-icon-scaner::before {
    content: "\eb88";
    font-family: Lumen;
    font-style: normal;
    position: relative;
    top: 0;
    font-size: 24px !important;
  }

  .btn-fullscreen {
    width: 40px;
    height: 40px;
    background-color: var(--neutral-80, $neutral-80);
    margin-left: 6px;
    float: right;
  }

  .dx-datagrid .dx-row.dx-data-row.dx-column-lines i::before {
    font-size: 15px;
    color: var(--neutral-10, $neutral-10) !important;
    left: 6px;
  }

  .amount-fullscreen {
    display: inline-block;
    font-size: 16px;
    margin-bottom: 0px;
    margin-top: 5px;
  }

  .top-menu-panel {
    margin-bottom: 0px;
    height: 50px;
    padding: 4px 10px;
    box-shadow: var(--shadow-m);
  }

  #content {
    height: 100vh !important;
  }

  .title-pop-up {
    position: relative;
    top: 0px;
    width: 100%;
    padding-right: 5px;
    right: 0;
    box-sizing: border-box;
    padding-bottom: 14px;
    padding-top: 0px;
  }

  .right-header-btn {
    top: 50px;
    right: 0px;
    position: fixed;
    height: 26px;
    padding-left: 20px;
    padding-right: 6px;
  }
}

.redHeader {
  background: var(
    --globals-tags-100-md-red,
    $globals-tags-100-md-red
  ) !important;

  p {
    color: var(
      --globals-dedicated-font-for-tags-red,
      $globals-dedicated-font-for-tags-red
    ) !important;
  }

  .p_document_name {
    margin-bottom: 0px !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    color: var(--text-light-gray, $text-light-gray) !important;
    width: 200px;
    position: absolute;
    top: 10px;
    text-align: left;
    left: 54px;
  }
}

.blueGreyHeader {
  background: var(
    --globals-tags-100-md-blue-grey,
    $globals-tags-100-md-blue-grey
  ) !important;

  p {
    color: var(
      --globals-dedicated-font-for-tags-blue-grey,
      $globals-dedicated-font-for-tags-blue-grey
    ) !important;
  }

  .p_document_name {
    margin-bottom: 0px !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    color: var(--text-light-gray, $text-light-gray) !important;
    width: 200px;
    position: absolute;
    top: 10px;
    text-align: left;
    left: 54px;
  }
}

.amberSignature {
  background: var(
    --globals-tags-100-md-amber,
    $globals-tags-100-md-amber
  ) !important;

  p {
    color: var(
      --globals-dedicated-font-for-tags-amber,
      $globals-dedicated-font-for-tags-amber
    ) !important;
  }

  .p_document_name {
    margin-bottom: 0px !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    color: var(--text-light-gray, $text-light-gray) !important;
    width: 200px;
    position: absolute;
    top: 10px;
    text-align: left;
    left: 54px;
  }
}

.deepOrangeSignature {
  background: var(
    --globals-tags-100-md-deep-orange,
    $globals-tags-100-md-deep-orange
  ) !important;

  p {
    color: var(
      --globals-dedicated-font-for-tags-deep-orange,
      $globals-dedicated-font-for-tags-deep-orange
    ) !important;
  }

  .p_document_name {
    margin-bottom: 0px !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    color: var(--text-light-gray, $text-light-gray) !important;
    width: 200px;
    position: absolute;
    top: 10px;
    text-align: left;
    left: 54px;
  }
}

.limeSignature {
  background: var(
    --globals-tags-100-md-lime,
    $globals-tags-100-md-lime
  ) !important;

  p {
    color: var(
      --globals-dedicated-font-for-tags-lime,
      $globals-dedicated-font-for-tags-lime
    ) !important;
  }

  .p_document_name {
    margin-bottom: 0px !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    color: var(--text-light-gray, $text-light-gray) !important;
    width: 200px;
    position: absolute;
    top: 10px;
    text-align: left;
    left: 54px;
  }
}

.orangeSignature {
  background: var(
    --globals-tags-100-md-orange,
    $globals-tags-100-md-orange
  ) !important;

  p {
    color: var(
      --globals-dedicated-font-for-tags-orange,
      $globals-dedicated-font-for-tags-orange
    ) !important;
  }

  .p_document_name {
    margin-bottom: 0px !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    color: var(--text-light-gray, $text-light-gray) !important;
    width: 200px;
    position: absolute;
    top: 10px;
    text-align: left;
    left: 54px;
  }
}

.brownSignature {
  background: var(
    --globals-tags-100-md-brown,
    $globals-tags-100-md-brown
  ) !important;

  p {
    color: var(
      --globals-dedicated-font-for-tags-brown,
      $globals-dedicated-font-for-tags-brown
    ) !important;
  }

  &.with-icon {
    i {
      color: var(
        --globals-dedicated-font-for-tags-brown,
        $globals-dedicated-font-for-tags-brown
      ) !important;
    }
  }

  .p_document_name {
    margin-bottom: 0px !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    color: var(--text-light-gray, $text-light-gray) !important;
    width: 200px;
    position: absolute;
    top: 10px;
    text-align: left;
    left: 54px;
  }
}

.indigoSignature {
  background: var(
    --globals-tags-100-md-indigo,
    $globals-tags-100-md-indigo
  ) !important;

  p {
    color: var(
      --globals-dedicated-font-for-tags-indigo,
      $globals-dedicated-font-for-tags-indigo
    ) !important;
  }

  .p_document_name {
    margin-bottom: 0px !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    color: var(--text-light-gray, $text-light-gray) !important;
    width: 200px;
    position: absolute;
    top: 10px;
    text-align: left;
    left: 54px;
  }
}

.blueSignature {
  background: var(
    --globals-tags-100-md-blue,
    $globals-tags-100-md-blue
  ) !important;

  p {
    color: var(
      --globals-dedicated-font-for-tags-blue,
      $globals-dedicated-font-for-tags-blue
    ) !important;
  }

  .p_document_name {
    margin-bottom: 0px !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    color: var(--text-light-gray, $text-light-gray) !important;
    width: 200px;
    position: absolute;
    top: 10px;
    text-align: left;
    left: 54px;
  }
}

.cyanSignature {
  background: var(
    --globals-tags-100-md-cyan,
    $globals-tags-100-md-cyan
  ) !important;

  p {
    color: var(
      --globals-dedicated-font-for-tags-cyan,
      $globals-dedicated-font-for-tags-cyan
    ) !important;
  }

  .p_document_name {
    margin-bottom: 0px !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    color: var(--text-light-gray, $text-light-gray) !important;
    width: 200px;
    position: absolute;
    top: 10px;
    text-align: left;
    left: 54px;
  }
}

.tealSignature {
  background: var(
    --globals-tags-100-md-teal,
    $globals-tags-100-md-teal
  ) !important;

  p {
    color: var(
      --globals-dedicated-font-for-tags-teal,
      $globals-dedicated-font-for-tags-teal
    ) !important;
  }

  .p_document_name {
    margin-bottom: 0px !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    color: var(--text-light-gray, $text-light-gray) !important;
    width: 200px;
    position: absolute;
    top: 10px;
    text-align: left;
    left: 54px;
  }
}

.lightGreen {
  background: var(
    --globals-tags-100-md-light-green,
    $globals-tags-100-md-light-green
  ) !important;

  p {
    color: var(
      --globals-dedicated-font-for-tags-light-green,
      $globals-dedicated-font-for-tags-light-green
    ) !important;
  }

  .p_document_name {
    margin-bottom: 0px !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    color: var(--text-light-gray, $text-light-gray) !important;
    width: 200px;
    position: absolute;
    top: 10px;
    text-align: left;
    left: 54px;
  }
}

.yellowSignature {
  background: var(
    --globals-tags-100-md-yellow,
    $globals-tags-100-md-yellow
  ) !important;

  p {
    color: var(
      --globals-dedicated-font-for-tags-yellow,
      $globals-dedicated-font-for-tags-yellow
    ) !important;
  }

  .p_document_name {
    margin-bottom: 0px !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    color: var(--text-light-gray, $text-light-gray) !important;
    width: 200px;
    position: absolute;
    top: 10px;
    text-align: left;
    left: 54px;
  }
}

.BusinesslinkHeader {
  background: var(--tagfill-teal, $tagfill-teal) !important;

  p {
    color: var(--tagtxt-teal, $tagtxt-teal) !important;
  }

  .p_document_name {
    margin-bottom: 0px !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    color: var(--text-light-gray, $text-light-gray) !important;
    width: 200px;
    position: absolute;
    top: 10px;
    text-align: left;
    left: 54px;
  }
}

.BufferHeader {
  background: var(--tagfill-blue-grey, $tagfill-blue-grey) !important;

  p {
    color: var(--tagtxt-blue-grey, $tagtxt-blue-grey) !important;
  }

  .p_document_name {
    margin-bottom: 0px !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    color: var(--text-light-gray, $text-light-gray) !important;
    width: 200px;
    position: absolute;
    top: 10px;
    text-align: left;
    left: 54px;
  }
}

.greenSignature {
  background: var(
    --globals-tags-100-md-green,
    $globals-tags-100-md-green
  ) !important;

  p,
  &:not(.menuSignature) i {
    color: var(
      --globals-dedicated-font-for-tags-green,
      $globals-dedicated-font-for-tags-green
    ) !important;
  }

  .p_document_name {
    margin-bottom: 0px !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    color: var(--text-light-gray, $text-light-gray) !important;
    width: 200px;
    position: absolute;
    top: 10px;
    text-align: left;
    left: 54px;
  }
}

.pinkSignature {
  background: var(--tagfill-pink, $tagfill-pink) !important;

  p {
    color: var(
      --globals-dedicated-font-for-tags-pink,
      $globals-dedicated-font-for-tags-pink
    ) !important;
  }

  .p_document_name {
    margin-bottom: 0px !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    color: var(--text-light-gray, $text-light-gray) !important;
    width: 200px;
    position: absolute;
    top: 10px;
    text-align: left;
    left: 54px;
  }
}

.purpleSignature {
  background: var(
    --globals-tags-100-md-purple,
    $globals-tags-100-md-purple
  ) !important;

  p {
    color: var(
      --globals-dedicated-font-for-tags-purple,
      $globals-dedicated-font-for-tags-purple
    ) !important;
  }

  .p_document_name {
    margin-bottom: 0px !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    color: var(--text-light-gray, $text-light-gray) !important;
    width: 200px;
    position: absolute;
    top: 10px;
    text-align: left;
    left: 54px;
  }
}

.lightBlueSignature {
  background: var(
    --globals-tags-100-md-light-blue,
    $globals-tags-100-md-light-blue
  ) !important;

  p {
    color: var(
      --globals-dedicated-font-for-tags-light-blue,
      $globals-dedicated-font-for-tags-light-blue
    ) !important;
  }

  .p_document_name {
    margin-bottom: 0px !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    color: var(--text-light-gray, $text-light-gray) !important;
    width: 200px;
    position: absolute;
    top: 10px;
    text-align: left;
    left: 54px;
  }
}

.deepPurple {
  background: var(
    --globals-tags-100-md-deep-purple,
    $globals-tags-100-md-deep-purple
  ) !important;

  p {
    color: var(
      --globals-dedicated-font-for-tags-deep-purple,
      $globals-dedicated-font-for-tags-deep-purple
    ) !important;
  }

  .p_document_name {
    margin-bottom: 0px !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    color: var(--text-light-gray, $text-light-gray) !important;
    width: 200px;
    position: absolute;
    top: 10px;
    text-align: left;
    left: 54px;
  }
}

.active {
  .BusinesslinkHeader {
    span,
    .icon {
      color: var(--tagtxt-teal, $tagtxt-teal) !important;
    }
  }

  .BufferHeader {
    span,
    .icon {
      color: var(--tagtxt-blue-grey, $tagtxt-blue-grey) !important;
    }
  }

  .redHeader {
    span,
    .icon {
      color: var(
        --globals-dedicated-font-for-tags-red,
        $globals-dedicated-font-for-tags-red
      ) !important;
    }
  }

  .blueGreyHeader {
    span,
    .icon {
      color: var(
          --globals-dedicated-font-for-tags-blue,
          $globals-dedicated-font-for-tags-blue
        ) -grey !important;
    }
  }

  .amberSignature {
    span,
    .icon {
      color: var(
        --globals-dedicated-font-for-tags-amber,
        $globals-dedicated-font-for-tags-amber
      ) !important;
    }
  }

  .deepOrangeSignature {
    span,
    .icon {
      color: var(
        --globals-dedicated-font-for-tags-deep-orange,
        $globals-dedicated-font-for-tags-deep-orange
      ) !important;
    }
  }

  .limeSignature {
    span,
    .icon {
      color: var(
        --globals-dedicated-font-for-tags-lime,
        $globals-dedicated-font-for-tags-lime
      ) !important;
    }
  }

  .orangeSignature {
    span,
    .icon {
      color: var(
        --globals-dedicated-font-for-tags-orange,
        $globals-dedicated-font-for-tags-orange
      ) !important;
    }
  }

  .brownSignature {
    span,
    .icon {
      color: var(
        --globals-dedicated-font-for-tags-brown,
        $globals-dedicated-font-for-tags-brown
      ) !important;
    }
  }

  .indigoSignature {
    span,
    .icon {
      color: var(
        --globals-dedicated-font-for-tags-indigo,
        $globals-dedicated-font-for-tags-indigo
      ) !important;
    }
  }

  .blueSignature {
    span,
    .icon {
      color: var(
        --globals-dedicated-font-for-tags-blue,
        $globals-dedicated-font-for-tags-blue
      ) !important;
    }
  }

  .cyanSignature {
    span,
    .icon {
      color: var(
        --globals-dedicated-font-for-tags-cyan,
        $globals-dedicated-font-for-tags-cyan
      ) !important;
    }
  }

  .tealSignature {
    span,
    .icon {
      color: var(
        --globals-dedicated-font-for-tags-teal,
        $globals-dedicated-font-for-tags-teal
      ) !important;
    }
  }

  .lightGreen {
    span,
    .icon {
      color: var(
        --globals-dedicated-font-for-tags-light-green,
        $globals-dedicated-font-for-tags-light-green
      ) !important;
    }
  }

  .yellowSignature {
    span,
    .icon {
      color: var(
        --globals-dedicated-font-for-tags-yellow,
        $globals-dedicated-font-for-tags-yellow
      ) !important;
    }
  }

  .greenSignature {
    span,
    .icon {
      color: var(
        --globals-dedicated-font-for-tags-green,
        $globals-dedicated-font-for-tags-green
      ) !important;
    }
  }

  .pinkSignature {
    span,
    .icon {
      color: var(
        --globals-dedicated-font-for-tags-pink,
        $globals-dedicated-font-for-tags-pink
      ) !important;
    }
  }

  .purpleSignature {
    span,
    .icon {
      color: var(
        --globals-dedicated-font-for-tags-purple,
        $globals-dedicated-font-for-tags-purple
      ) !important;
    }
  }

  .lightBlueSignature {
    span,
    .icon {
      color: var(
        --globals-dedicated-font-for-tags-light-blue,
        $globals-dedicated-font-for-tags-light-blue
      ) !important;
    }
  }

  .deepPurple {
    span,
    .icon {
      color: var(
        --globals-dedicated-font-for-tags-deep-purple,
        $globals-dedicated-font-for-tags-deep-purple
      ) !important;
    }
  }
}

body.color-theme-dark .active {
  .BufferHeader {
    span,
    .icon {
      color: var(--tagfill-blue-grey, $tagfill-blue-grey) !important;
    }
  }
  .BusinesslinkHeader {
    span,
    .icon {
      color: var(--tagfill-teal, $tagfill-teal) !important;
    }
  }
  .greenSignature {
    span,
    .icon {
      color: var(
        --globals-tags-100-md-green,
        $globals-tags-100-md-green
      ) !important;
    }
  }
  .pinkSignature {
    span,
    .icon {
      color: var(--tagfill-pink, $tagfill-pink) !important;
    }
  }
  .purpleSignature {
    span,
    .icon {
      color: var(
        --globals-tags-100-md-purple,
        $globals-tags-100-md-purple
      ) !important;
    }
  }
  .redHeader {
    span,
    .icon {
      color: var(
        --globals-tags-100-md-red,
        $globals-tags-100-md-red
      ) !important;
    }
  }
  .blueGreyHeader {
    span,
    .icon {
      color: var(
        --globals-tags-100-md-blue-grey,
        $globals-tags-100-md-blue-grey
      ) !important;
    }
  }
  .amberSignature {
    span,
    .icon {
      color: var(
        --globals-tags-100-md-amber,
        $globals-tags-100-md-amber
      ) !important;
    }
  }
  .deepOrangeSignature {
    span,
    .icon {
      color: var(
        --globals-tags-100-md-deep-orange,
        $globals-tags-100-md-deep-orange
      ) !important;
    }
  }
  .limeSignature {
    span,
    .icon {
      color: var(
        --globals-tags-100-md-lime,
        $globals-tags-100-md-lime
      ) !important;
    }
  }
  .orangeSignature {
    span,
    .icon {
      color: var(
        --globals-tags-100-md-orange,
        $globals-tags-100-md-orange
      ) !important;
    }
  }
  .brownSignature {
    span,
    .icon {
      color: var(
        --globals-tags-100-md-brown,
        $globals-tags-100-md-brown
      ) !important;
    }
  }
  .indigoSignature {
    span,
    .icon {
      color: var(
        --globals-tags-100-md-indigo,
        $globals-tags-100-md-indigo
      ) !important;
    }
  }
  .blueSignature {
    span,
    .icon {
      color: var(
        --globals-tags-100-md-blue,
        $globals-tags-100-md-blue
      ) !important;
    }
  }
  .lightBlueSignature {
    span,
    .icon {
      color: var(
        --globals-tags-100-md-light-blue,
        $globals-tags-100-md-light-blue
      ) !important;
    }
  }
  .cyanSignature {
    span,
    .icon {
      color: var(
        --globals-tags-100-md-cyan,
        $globals-tags-100-md-cyan
      ) !important;
    }
  }
  .tealSignature {
    span,
    .icon {
      color: var(
        --globals-tags-100-md-teal,
        $globals-tags-100-md-teal
      ) !important;
    }
  }
  .lightGreen {
    span,
    .icon {
      color: var(
        --globals-tags-100-md-light-green,
        $globals-tags-100-md-light-green
      ) !important;
    }
  }
  .yellowSignature {
    span,
    .icon {
      color: var(
        --globals-tags-100-md-yellow,
        $globals-tags-100-md-yellow
      ) !important;
    }
  }
  .deepPurple {
    span,
    .icon {
      color: var(
        --globals-tags-100-md-deep-purple,
        $globals-tags-100-md-deep-purple
      ) !important;
    }
  }
}

.dx-context-menu .dx-state-focused {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

td.amount-formatter-overflow {
  position: relative;
  text-overflow: clip !important;

  &:after {
    content: attr(data-after-content);
  }

  & > .dx-template-wrapper {
    visibility: hidden;
    display: none;
  }
}

.dynamic-filter-container dx-select-box,
.dynamic-filter-container dx-drop-down-box {
  max-width: 100% !important;
  width: 100% !important;
}

.dx-item.dx-list-item.dx-state-focused {
  border-radius: 4px;
  border: 2px solid
    var(--ds-color-ui-state-brand-focus, $ds-color-ui-state-brand-focus) !important;
  //background: var(--neutral-100, $neutral-100) !important;
}

.dx-menu-item.dx-item.dx-state-focused {
  border-radius: 4px !important;
  border: 2px solid
    var(--ds-color-ui-state-brand-focus, $ds-color-ui-state-brand-focus) !important;
  background: var(--neutral-100, $neutral-100) !important;
}

dx-item.dx-menu-item.dx-state-focused
  .top-menu-item.text-left.dx-template-wrapper.dx-item-content.dx-menu-item-content.active {
  border: none !important;
}

.top-menu .dx-menu-item.dx-state-focused .dx-menu-item-content {
  border: none !important;
}

.seperator {
  width: 1px;
  height: 20px;
  background: var(--neutral-80, $neutral-80);
  position: absolute;
  margin-top: 1.5px;
}

.dx-popup-content .title-pop-up .seperator {
  margin-top: 6px !important;
}

.separator-new {
  width: 1px;
  height: 20px;
  background-color: var(--neutral-80, $neutral-80);
  position: static;
  margin: auto 6px;
}

.icon-tag {
  border-radius: 50%;
  padding: 6px;

  &_red {
    color: var(--avatartext-red, $avatartext-red) !important;
    background-color: var(--avatarfill-red, $avatarfill-red);
  }

  &_pink {
    color: var(--avatartext-pink, $avatartext-pink) !important;
    background-color: var(--avatarfill-pink, $avatarfill-pink);
  }

  &_purple {
    color: var(--avatartext-purple, $avatartext-purple) !important;
    background-color: var(--avatarfill-purple, $avatarfill-purple);
  }

  &_deep-purple {
    color: var(--avatartext-deep-purple, $avatartext-deep-purple) !important;
    background-color: var(--avatarfill-deep-purple, $avatarfill-deep-purple);
  }

  &_indigo {
    color: var(--avatartext-indigo, $avatartext-indigo) !important;
    background-color: var(--avatarfill-indigo, $avatarfill-indigo);
  }

  &_blue {
    color: var(--avatartext-blue, $avatartext-blue) !important;
    background-color: var(--avatarfill-blue, $avatarfill-blue);
  }

  &_light-blue {
    color: var(--avatartext-light-blue, $avatartext-light-blue) !important;
    background-color: var(--avatarfill-light-blue, $avatarfill-light-blue);
  }

  &_cyan {
    color: var(--avatartext-cyan, $avatartext-cyan) !important;
    background-color: var(--avatarfill-cyan, $avatarfill-cyan);
  }

  &_teal {
    color: var(--avatartext-teal, $avatartext-teal) !important;
    background-color: var(--avatarfill-teal, $avatarfill-teal);
  }

  &_green {
    color: var(--avatartext-green, $avatartext-green) !important;
    background-color: var(--avatarfill-green, $avatarfill-green);
  }

  &_light-green {
    color: var(--avatartext-light-green, $avatartext-light-green) !important;
    background-color: var(--avatarfill-light-green, $avatarfill-light-green);
  }

  &_lime {
    color: var(--avatartext-lime, $avatartext-lime) !important;
    background-color: var(--avatarfill-lime, $avatarfill-lime);
  }

  &_yellow {
    color: var(--avatartext-yellow, $avatartext-yellow) !important;
    background-color: var(--avatarfill-yellow, $avatarfill-yellow);
  }

  &_amber {
    color: var(--avatartext-amber, $avatartext-amber) !important;
    background-color: var(--avatarfill-amber, $avatarfill-amber);
  }

  &_orange {
    color: var(--avatartext-orange, $avatartext-orange) !important;
    background-color: var(--avatarfill-orange, $avatarfill-orange);
  }

  &_deep-orange {
    color: var(--avatartext-deep-orange, $avatartext-deep-orange) !important;
    background-color: var(--avatarfill-deep-orange, $avatarfill-deep-orange);
  }

  &_brown {
    color: var(--avatartext-brown, $avatartext-brown) !important;
    background-color: var(--avatarfill-brown, $avatarfill-brown);
  }

  &_gray {
    color: var(--avatartext-gray, $avatartext-gray) !important;
    background-color: var(--avatarfill-gray, $avatarfill-gray);
  }

  &_gray-car {
    color: var(--avatartext-gray, $avatartext-gray) !important;
    background-color: var(--avatarfill-gray, $avatarfill-blue-gray);
  }

  &_blue-gray {
    color: var(--avatartext-blue, $avatartext-blue) -gray !important;
    background-color: var(--avatarfill-blue-gray, $avatarfill-blue-gray);
  }
}

.BLError#dateBoxError {
  border-radius: 4px;
  border: 1px solid var(--text-negative, $text-negative) !important;
  background: var(--brand-90, $brand-90) !important;
}

.dx-treeview-expander-icon-stub {
  display: none;
  flex-shrink: 0;
  width: 22px;
  height: 22px;
}

.dx-datagrid
  .dx-row.dx-data-row.dx-column-lines
  i.dx-numberbox-spin-up-icon::before,
i.numberbox-spin-down-icon::before {
  font-size: 15px;
  color: var(--neutral-10, $neutral-10) !important;
  left: 7px;
}

.dx-icon-print::before {
  font-family: "Lumen";
  content: "\e8ad";
  font-size: 18px;
  color: var(--text-gray, $text-gray);
}

.gridPositionVer
  .dx-bordered-top-view.dx-bordered-bottom-view.dx-datagrid-rowsview {
  background-color: var(--neutral-95, $neutral-95);
}

.gridPositionVerification {
  .dx-loadindicator-icon {
    top: 0;
    left: 0;
    width: 100px;
    background: url(./assets/img/secondary_loader.gif);
    background-position: center;
    background-repeat: no-repeat;
    height: 4px;
  }

  .dx-loadindicator {
    background-color: transparent;
    width: 100px;
    height: 15px;
    padding: 0px 0px;
    margin-top: 0px;
  }
}

.route_text-area .dx-texteditor-container {
  height: 80px;
}

.dx-overlay-wrapper {
  z-index: 1000 !important;
}

.ol-rotate-reset {
  width: 200px;
}

.dx-widget {
  z-index: 100;
  color: var(--text-light-gray, $text-light-gray);
}

.status {
  height: 18px;
  padding: 0 4px;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  border-radius: 4px;
}

.O {
  color: var(--ds-tag-paired-text-blue-gray, #1f282d);
  background: var(--ds-tag-paired-fill-blue-gray, #cfd8dc);
}

.R {
  color: var(--ds-tag-paired-text-blue, #0b3a83);
  background: var(--ds-tag-paired-fill-blue, #bbdefb);
}

.Z {
  color: var(--ds-tag-paired-text-green, #164c1a);
  background: var(--ds-tag-paired-fill-green, #c8e6c9);
}

.A {
  color: var(--ds-tag-paired-text-red, #741111);
  background: var(--ds-tag-paired-fill-red, #ffcdd2);
}

.ol-control button {
  height: 30px;
  width: 30px;
}

.ol-rotate.ol-hidden {
  opacity: 1;
  visibility: visible;
}

.ol-zoom .ol-zoom-in {
  margin-bottom: 3px;
  border-radius: 2px 2px 0 0;
}

.route-mobile .dx-list-item-after-bag .dx-list-reorder-handle {
  position: relative;
  margin-top: -41px;
  border-radius: var(--pill, 20px);
  background: var(--theme-neutral-90, #f5f5f5);
  &::before {
    //content: "\eaa2";
    text-align: center;
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-bottom: 11px;
    padding-top: 8px;
    width: 100vh;
  }
}

.route-mobile .dx-item.dx-list-item {
  position: relative;
  margin-bottom: 8px;
}

.route-users .dx-list-item-content::before {
  display: none;
}

.ol-rotate-reset::before {
  display: inline-block;
  width: 21px;
  height: 24px;
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMC41IDIwQzEyLjk4NTMgMjAgMTUgMTcuOTg1MyAxNSAxNS41QzE1IDEzLjAxNDcgMTIuOTg1MyAxMSAxMC41IDExQzguMDE0NzIgMTEgNiAxMy4wMTQ3IDYgMTUuNUM2IDE3Ljk4NTMgOC4wMTQ3MiAyMCAxMC41IDIwWk05LjA5OTYxIDEzQzguODIzNDcgMTMgOC41OTk2MSAxMy4yMjM5IDguNTk5NjEgMTMuNVYxNy41QzguNTk5NjEgMTcuNzc2MSA4LjgyMzQ3IDE4IDkuMDk5NjEgMThDOS4zNzU3NSAxOCA5LjU5OTYxIDE3Ljc3NjEgOS41OTk2MSAxNy41VjE1LjA0MDVMMTEuNTM4MiAxNy43ODgyQzExLjYzMTggMTcuOTIxIDExLjc4NDIgMTggMTEuOTQ2NyAxOEgxMS45ODg1QzEyLjI2NDYgMTggMTIuNDg4NSAxNy43NzYxIDEyLjQ4ODUgMTcuNVYxMy41QzEyLjQ4ODUgMTMuMjIzOSAxMi4yNjQ2IDEzIDExLjk4ODUgMTNDMTEuNzEyNCAxMyAxMS40ODg1IDEzLjIyMzkgMTEuNDg4NSAxMy41VjE1Ljk4MzJMOS41MzMyOCAxMy4yMTE4QzkuNDM5NiAxMy4wNzkgOS4yODcyMyAxMyA5LjEyNDcyIDEzSDkuMDk5NjFaIiBmaWxsPSJibGFjayIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTExLjE1OTIgMS4yMjU5MkMxMC45NjM2IDAuNzc3NjU3IDEwLjMwOTcgMC43NTM5MTggMTAuMTA3NyAxLjIyNzkxTDYuMTk5NzMgMTAuMzk5OEM1Ljk5MTYgMTAuODg4MyA2LjUwNDcgMTEuMzc0OCA2Ljk4MTU4IDExLjEzODlMMTAuNjQ1OCA5LjMyNTA4TDE0LjM2OTYgMTEuMDkxNkMxNC44NDczIDExLjMxODIgMTUuMzUwNCAxMC44MzI2IDE1LjEzODUgMTAuMzQ2OEwxMS4xNTkyIDEuMjI1OTJaTTEwLjYzNjkgMi4xNTc2MUw3LjI3NzE5IDEwLjA0MjdMMTAuMzQwMiA4LjUyNjU0QzEwLjUyOTEgOC40MzMxNiAxMC43NSA4LjQzMjMgMTAuOTM5MiA4LjUyMjA1TDE0LjA1OTQgMTAuMDAyMkwxMC42MzY5IDIuMTU3NjFaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K");
}

.ol-compass {
  display: none;
}

@media (max-width: 1000px) {
  .ol-scale-bar {
    display: none !important;
  }
}

// dynamic font-size helper class .fs-xx, where xx is font-size in px. e.g. .fs-12.
@for $i from 1 through 100 {
  .fs-#{$i} {
    font-size: #{$i}px;
  }
}

dx-scroll-view.menu-bar {
  i::before {
    color: var(--neutral-30, #4c4c4c) !important;
  }
  .dx-state-disabled .dx-button .dx-icon:before,
  .dx-state-disabled.dx-button .dx-icon:before {
    color: var(
      --ds-color-ui-state-text-icon-disabled,
      $ds-color-ui-state-text-icon-disabled
    ) !important;
  }
}

// 1. Mixin dla flexboxa - często powtarzające się właściwości flex
@mixin flex-container($direction: row, $justify: flex-start, $align: stretch) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

// 2. Mixin dla kolumn - powtarzający się wzorzec dla klas .col-*
@mixin column-width($width) {
  -ms-flex: 0 0 $width;
  flex: 0 0 $width;
  max-width: $width;
}

// 3. Mixin dla czcionek - powtarzające się właściwości fontów
@mixin font-settings($size, $weight: 400, $line-height: 1.5) {
  font-family: Roboto;
  font-size: $size;
  font-weight: $weight;
  line-height: $line-height;
}

// 4. Mixin dla kolorów avatara - powtarzający się wzorzec
@mixin avatar-color($color) {
  color: var(--avatartext-#{$color}) !important;
  background-color: var(--avatarfill-#{$color});
}

// Przykład użycia:
.avatar {
  &_blue {
    @include avatar-color('blue');
  }
  &_green {
    @include avatar-color('green');
  }
}

