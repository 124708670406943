@use "../colors" as *;

@mixin colorThemeDark() {
  /* brand */
  --brand-00: #a2161e;
  --brand-10: #bc1a22;
  --brand-20: #dc1e28;
  --brand-30: #ea6269;
  --brand-70: #f8c9cc;
  --brand-80: #fadbdd;
  --brand-85: #f5eded;
  --brand-90: #fdedee;
  --brand-neutral-00: #25292b;
  --brand-neutral-10: #30393f;

  /* UI Colors / neutral */
  --neutral-10: var(--ds-color-ui-neutral-10);
  --ds-color-ui-neutral-10: #fafafa;
  --neutral-20: #f5f5f5;
  --ds-color-ui-neutral-20: #f5f5f5;
  --neutral-30: #d9d9d9;
  --ds-color-ui-neutral-30: #d9d9d9;
  --neutral-40: #adadad;
  --ds-color-ui-neutral-40: #adadad;
  --neutral-40-opacity-90: #adadade6; /* brak w palecie ux */
  --neutral-50: #666666;
  --ds-color-ui-neutral-50: #666666;
  --neutral-60: #4c4c4c;
  --ds-color-ui-neutral-60: #4c4c4c;
  --neutral-70: #474747;
  --ds-color-ui-neutral-70: #474747;
  --neutral-80: #404040;
  --ds-color-ui-neutral-80: #404040;
  --neutral-85: #3b3b3b;
  --ds-color-ui-neutral-85: #3b3b3b;
  --neutral-90: #333333;
  --ds-color-ui-neutral-90: #333333;
  --neutral-95: #2e2e2e;
  --ds-color-ui-neutral-95: #2e2e2e;
  --neutral-100: #222222;
  --ds-color-ui-neutral-100: #222222;
  --neutral-logo: #30393f;
  --ds-color-ui-neutral-logo: #30393f;

  /* UI Colors / background */
  --ds-background-navibar-search: #ffffff1a;
  --ds-background-navigation-page-header: #333333;
  --ds-background-tiles-panels: #2e2e2e;
  --ds-background-page-header-tabs: #262626;
  --ds-background-dropdown-menu: #262626;
  --ds-background-box: #333333;

  /* UI Colors / body */
  --ds-overlay-background-98: #262626;
  --ds-body-background-100: #2e2e2e;
  --ds-body-stroke-DM-only: #404040;

  /* UI Colors / overlay */
  --ds-overlay-background: #00000099;

  /* UI Colors / text */
  --text-brand: #ed787e;
  --text-brand-inverted: #ffffff;
  --text-brand-outlined: #ffffff;
  --text-black: #ffffff;
  --text-dark-gray: #fafafa;
  --text-gray: #d9d9d9;
  --text-white: #000000;
  --text-light-gray: #adadad;
  --text-info: #00bbff;
  --ds-color-text-info-strong: #3ADAFF; /* WEW-9538 */
  --text-positive: #4db24f;
  --ds-color-text-positive-strong: #75DA77; /* WEW-9538 */
  --text-negative: #ed787e;
  --ds-color-text-negative-strong: #FF8C92; /* WEW-9538 */
  --text-warning: #f8a507;
  --ds-color-text-warning-strong: #FFB517; /* WEW-9538 */
  --text-brand-neutral: #ffffff;

  /* UI Colors / icon */
  --icons-brand: #ed787e;
  --icons-brand-inverted: #ffffff;
  --icons-brand-outlined: #ffffff;
  --icons-black: #ffffff;
  --icons-dark-gray: #fafafa;
  --icons-gray: #d9d9d9;
  --icons-light-gray: #adadad;
  --icons-white: #000000;
  --icons-info: #00bbff;
  --ds-color-icon-info-strong: #3ADAFF; /* WEW-9538 */
  --icons-positive: #4db24f;
  --ds-color-icon-positive-strong: #75DA77; /* WEW-9538 */
  --icons-negative: #ed787e;
  --ds-color-icon-negative-strong: #FF8C92; /* WEW-9538 */
  --icons-warning: #f8a507;
  --ds-color-icon-warning-strong: #FFB517; /* WEW-9538 */
  --icons-red: #e57373;
  --icons-pink: #f06292;
  --icons-purple: #f06292;
  --icons-deep-purple: #9575cd;
  --icons-indigo: #7986cb;
  --icons-blue: #64b5f6;
  --icons-light-blue: #4fc3f7;
  --icons-cyan: #4dd0e1;
  --icons-teal: #4db6ac;
  --icons-green: #81c784;
  --icons-light-green: #aed581;
  --icons-lime: #dce775;
  --icons-orange: #ffb74d;
  --icons-brown: #a1887f;
  --icons-grey: #e0e0e0;
  --icons-blue-grey: #90a4ae;

  /* UI Colors / brand */
  --ds-color-ui-brand-primary: #bc1a22;
  --ds-color-ui-brand-secondary: #ed787e;
  --ds-color-ui-brand-accent-primary: #333333;
  --ds-color-ui-brand-accent-secondary: #630d12;
  --ds-color-ui-brand-accent-tertiary: #7e1117;
  --ds-color-ui-brand-outlined: #ffffff;
  --ds-color-ui-brand-navibar: #30393f;
  --ds-color-ui-brand-form-element: #ed787e;
  --ds-color-ui-brand-logo: #dc1e28;

  /* UI Colors / state */
  --ds-color-ui-state-brand-hover: #a2161e;
  --ds-color-ui-state-form-element-hover: #f08f94;
  --ds-color-ui-state-brand-focus: #ed787e;
  --ds-color-ui-state-navibar-hover: #000000;
  --ds-color-ui-state-neutral-hover: #3b3b3b;
  --ds-color-ui-state-accent-hover: #510b0f;
  --ds-color-ui-state-row-selected: #333333;
  --ds-color-ui-state-row-selected-hover: #3b3b3b;
  --ds-color-ui-state-row-focus: #4c4c4c;
  --ds-color-ui-state-cell-focus: #666666;
  --ds-color-ui-state-text-icon-disabled: #666666;
  --ds-color-ui-state-text-table-focus: #ffffff;
  --ds-color-ui-state-form-field-disabled: #333333;

  /* UI Colors / themes */
  /* theme-info */
  --theme-info-30: #00bbff;
  --theme-info-50: #0074ad;
  --theme-info-60: #007099;
  --theme-info-85: #00698f;
  --theme-info-90: #2e596f;
  --theme-info-100: #2e424c;
  /* theme-positive */
  --theme-positive-30: #4db24f;
  --theme-positive-50: #3a863b;
  --theme-positive-60: #2e6b30;
  --theme-positive-85: #2b642c;
  --theme-positive-90: #365939;
  --theme-positive-100: #364637;
  /* theme-negative */
  --theme-negative-30: #ed787e;
  --theme-negative-50: #b0555b;
  --theme-negative-60: #8d3d42;
  --theme-negative-85: #793338;
  --theme-negative-90: #73292e;
  --theme-negative-100: #4d2f31;
  /* theme-warning */
  --theme-warning-30: #f8a507;
  --theme-warning-50: #c27800;
  --theme-warning-60: #8b5d04;
  --theme-warning-85: #8b5d04;
  --theme-warning-90: #6a4b1e;
  --theme-warning-100: #4f402a;

  /* Effects Styles / Shadow */
  --shadow-xxl: 0px 8px 60px 0 rgba(0, 0, 0, 0.15),
    0px 2px 1px 0 rgba(0, 0, 0, 0.06);
  --shadow-xl: 0px 0px 2px 0 rgba(0, 0, 0, 0.2),
    0px 4px 2px -2px rgba(0, 0, 0, 0.2), 0px 3px 10px 0 rgba(0, 0, 0, 0.4);
  --shadow-l: 0px 4px 20px 0 rgba(0, 0, 0, 0.2);
  --shadow-m: 0px 4px 30px 0 rgba(0, 0, 0, 0.3),
    0px 2px 1px 0 rgba(0, 0, 0, 0.3);
  --shadow-s: 0px 2px 4px 0 rgba(0, 0, 0, 0.16),
    0px 3px 1px -2px rgba(0, 0, 0, 0.14), 0px 1px 8px 0 rgba(0, 0, 0, 0.2);
  --shadow-xs: 0px 1px 4px 0 rgba(0, 0, 0, 0.2);

  /* accent */
  --accent-teal: #4db6ac;
  --accent-light-green: #aed581;
  --accent-blue-grey: #90a4ae;
  --accent-red: #e57373;
  --accent-light-blue: #4fc3f7;
  --accent-orange: #ffb74d;
  --accent-deep-orange: #ff8a65;
  --accent-green: #81c784;
  --accent-pink: #f06292;
  --accent-purple: #ba68c8;
  --accent-deep-purple: #9575cd;
  --accent-indigo: #7986cb;
  --accent-blue: #64b5f6;
  --accent-cyan: #4dd0e1;
  --accent-lime: #dce775;
  --accent-brown: #a1887f;
  --accent-grey: #e0e0e0;
  --accent-yellow: #fff176;
  --accent-amber: #ffd54f;

  /* tags */
  /* tag fill color */
  --tagfill-red: #ffcdd2;
  --tagfill-pink: #f8bbd0;
  --tagfill-purple: #e1bee7;
  --tagfill-deep-purple: #d1c4e9;
  --tagfill-indigo: #c5cae9;
  --tagfill-blue: #bbdefb;
  --tagfill-light-blue: #b3e5fc;
  --tagfill-cyan: #b2ebf2;
  --tagfill-teal: #c8e6c9;
  --tagfill-green: #dcedc8;
  --tagfill-light-green: #dcedc8;
  --tagfill-lime: #f0f4c3;
  --tagfill-yellow: #fff9c4;
  --tagfill-amber: #ffecb3;
  --tagfill-orange: #ffe0b2;
  --tagfill-deep-orange: #ffccbc;
  --tagfill-brown: #d7ccc8;
  --tagfill-grey: #f5f5f5;
  --tagfill-blue-grey: #cfd8dc;
  --tagfill-white: #ffffff;
  --tagfill-deep-teal: #b2dfdb;
  --tagfill-deep-indigo: #c5cae9;

  /* tag text color */
  --tagtxt-red: #741111;
  --tagtxt-pink: #6e0c40;
  --tagtxt-purple: #3c1071;
  --tagtxt-deep-purple: #151c66;
  --tagtxt-indigo: #151c66;
  --tagtxt-blue: #0b3a83;
  --tagtxt-light-blue: #01467d;
  --tagtxt-cyan: #004d51;
  --tagtxt-teal: #003e34;
  --tagtxt-green: #164c1a;
  --tagtxt-light-green: #295518;
  --tagtxt-lime: #524b0f;
  --tagtxt-yellow: #6f390a;
  --tagtxt-amber: #733200;
  --tagtxt-orange: #7c2c00;
  --tagtxt-deep-orange: #782208;
  --tagtxt-brown: #321f1c;
  --tagtxt-grey: #1b1b1b;
  --tagtxt-blue-grey: #1f282d;
  --tagtxt-dark: #272e33;
  --tagtxt-deep-teal: #003e34;
  --tagtxt-deep-indigo: #151c66;

  /* other */
  --abs-brands-cloud-20: #00aaa0;
  --abs-brands-cloud-70: #c2ebe8;

  --globals-tags-100-md-green: #c8e6c9;
  --globals-dedicated-font-for-tags-green: #164c1a;

  --globals-tags-100-md-yellow: #fff9c4;
  --globals-dedicated-font-for-tags-yellow: #6f390a;

  --globals-tags-100-md-blue: #bbdefb;
  --globals-dedicated-font-for-tags-blue: #0b3a83;

  --globals-tags-100-md-cyan: #b2ebf2;
  --globals-dedicated-font-for-tags-cyan: #004d51;

  --globals-tags-100-md-light-blue: #b3e5fc;
  --globals-dedicated-font-for-tags-light-blue: #01467d;

  --globals-tags-100-md-deep-orange: #ffccbc;
  --globals-dedicated-font-for-tags-deep-orange: #782208;

  --globals-tags-100-md-orange: #ffe0b2;
  --globals-dedicated-font-for-tags-orange: #7c2c00;

  --globals-tags-100-md-amber: #ffecb3;
  --globals-dedicated-font-for-tags-amber: #733200;

  --globals-tags-100-md-lime: #f0f4c3;
  --globals-dedicated-font-for-tags-lime: #524b0f;

  --globals-tags-100-md-teal: #b2dfdb;
  --globals-dedicated-font-for-tags-teal: #003e34;

  --globals-tags-100-md-light-green: #dcedc8;
  --globals-dedicated-font-for-tags-light-green: #295518;

  --globals-tags-100-md-red: #ffcdd2;
  --globals-dedicated-font-for-tags-red: #741111;

  --globals-tags-100-md-blue-grey: #cfd8dc;
  --globals-dedicated-font-for-tags-blue-grey: #1f282d;

  --globals-tags-100-md-brown: #d7ccc8;
  --globals-dedicated-font-for-tags-brown: #321f1c;

  --globals-tags-100-md-indigo: #c5cae9;
  --globals-dedicated-font-for-tags-indigo: #151c66;

  --globals-dedicated-font-for-tags-pink: #6e0c40;

  --globals-tags-100-md-purple: #e1bee7;
  --globals-dedicated-font-for-tags-purple: #3c1071;

  --globals-tags-100-md-deep-purple: #d1c4e9;
  --globals-dedicated-font-for-tags-deep-purple: #281676;

  --teal-900: #004d40;
  --teal-900-round: #e0f2f1;

  //avatarfill

  --avatartext-red: #b71c1c;
  --avatartext-pink: #880e4f;
  --avatartext-purple: #4a148c;
  --avatartext-deep-purple: #311b92;
  --avatartext-indigo: #1a237e;
  --avatartext-blue: #0d47a1;
  --avatartext-light-blue: #01579b;
  --avatartext-cyan: #006064;
  --avatartext-teal: #004d40;
  --avatartext-green: #1b5e20;
  --avatartext-light-green: #33691e;
  --avatartext-lime: #827717;
  --avatartext-yellow: #bf5e08;
  --avatartext-amber: #cc5a03;
  --avatartext-orange: #d04b02;
  --avatartext-deep-orange: #bf360c;
  --avatartext-brown: #3e2723;
  --avatartext-gray: #212121;
  --avatartext-blue-gray: #263238;

  --avatarfill-red: #ffebee;
  --avatarfill-pink: #fce4ec;
  --avatarfill-purple: #f3e5f5;
  --avatarfill-deep-purple: #ede7f6;
  --avatarfill-indigo: #e8eaf6;
  --avatarfill-blue: #e3f2fd;
  --avatarfill-light-blue: #e1f5fe;
  --avatarfill-cyan: #e0f7fa;
  --avatarfill-teal: #e0f2f1;
  --avatarfill-green: #e8f5e9;
  --avatarfill-light-green: #f1f8e9;
  --avatarfill-lime: #f9fbe7;
  --avatarfill-yellow: #fffde7;
  --avatarfill-amber: #fff8e1;
  --avatarfill-orange: #fff3e0;
  --avatarfill-deep-orange: #fbe9e7;
  --avatarfill-brown: #efebe9;
  --avatarfill-gray: #fafafa;
  --avatarfill-blue-gray: #eceff1;
}
