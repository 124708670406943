@use "./colors" as *;
@use "./fonts" as *;


@mixin container-mobile {
  display: block !important;
  text-align: left !important;
  margin-left: 0px !important;
  top: 0px !important;
  width: 100% !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: var(--neutral-10, $neutral-10) !important;
}

@mixin container {
  display: inline-block;
  text-align: right;
}

.dx-texteditor.dx-state-readonly {
  background: var(--neutral-95, $neutral-95) !important;
  border: 1px solid var(--neutral-80, $neutral-80) !important;
}

.dx-texteditor.dx-state-disabled {
  background: var(
    --ds-color-ui-state-form-field-disabled,
    $ds-color-ui-state-form-field-disabled
  );
  border: 1px solid var(--neutral-50, $neutral-50);
  color: var(
    --ds-color-ui-state-text-icon-disabled,
    $ds-color-ui-state-text-icon-disabled
  );
}

.position-check.dx-checkbox.dx-state-readonly .dx-checkbox-icon {
  position: relative;
  // top: -5px;
}

.dx-datebox-calendar .dx-dropdowneditor-icon {
  font: 18px/18px DXIcons;
  width: 22px;
  height: 100%;
  text-align: center;
  border-radius: 3px;
}

.dx-button-content .icon {
  color: var(--neutral-30, $neutral-30);
  font-size: 16px !important;
}

.dx-numberbox-spin.dx-texteditor.dx-editor-outlined {
  max-width: 290px;
  width: 276px;
  background: var(
    --ds-background-tiles-panels,
    $ds-background-tiles-panels
  );
  border: 1px solid var(--neutral-50, $neutral-50);
  box-sizing: border-box;
  border-radius: 6px;
  height: 28px;
}

.dx-checkbox-has-text .dx-checkbox-icon,
.dx-checkbox-has-text .dx-checkbox-text {
  vertical-align: text-top;
}

.dx-texteditor.dx-state-readonly.dx-widget input,
.dx-widget textarea {
  font-family: $Roboto;
  font-size: 0.75rem;
  line-height: 16px;
  color: var(--neutral-30, $neutral-30);
}

.dx-checkbox.dx-state-readonly .dx-checkbox-icon {
  background-color: var(--neutral-90, $neutral-90);
}

.dx-checkbox.dx-state-readonly.dx-checkbox-checked .dx-checkbox-icon::before {
  color: var(--text-light-gray, $text-light-gray) !important;
}

.dx-radiogroup.dx-state-readonly .dx-radiobutton-icon::before {
  border-color: var(--neutral-50, $neutral-50);
  background-color: var(--neutral-90, $neutral-90);
}

dx-radio-group.dx-state-disabled {
  background: transparent;
  .dx-widget.dx-state-disabled {
    background: transparent;
  }
}

.dx-state-disabled.dx-radiobutton {
  background: var(
    --ds-background-tiles-panels,
    $ds-background-tiles-panels
  );
  .dx-radiobutton-icon {
    &::before {
      border-color: var(--neutral-80, $neutral-80);
    }
    &.dx-radiobutton-icon-checked {
      .dx-radiobutton-icon-dot {
        background: var(--text-brand, $text-brand);
      }
    }
  }
  .dx-item-content {
    color: var(--neutral-50, $neutral-50);
  }
}

.dx-state-readonly .dx-radiobutton-icon-checked .dx-radiobutton-icon-dot {
  background: var(--text-light-gray, $text-light-gray) !important;
}

.dx-texteditor.dx-state-readonly.dx-widget textarea {
  font-family: $Roboto;
  font-size: 0.75rem;
  line-height: 16px;
  background-color: var(--neutral-95, $neutral-95);
  color: var(--neutral-30, $neutral-30) !important;
}

.dx-checkbox.dx-state-readonly .dx-checkbox-text {
  color: var(--neutral-30, $neutral-30) !important;
}

.dx-texteditor.dx-state-disabled.dx-widget input,
.dx-widget textarea {
  font-family: $Roboto;
  font-size: 0.75rem;
  line-height: 16px;
  color: var(--neutral-50, $neutral-50);
}

.dx-texteditor.dx-state-disabled.dx-widget textarea {
  font-family: $Roboto;
  font-size: 0.75rem;
  line-height: 16px;
  color: var(--neutral-50, $neutral-50) !important;
}

.dx-checkbox.dx-state-disabled .dx-checkbox-text {
  color: var(
    --ds-color-ui-state-text-icon-disabled,
    $ds-color-ui-state-text-icon-disabled
  ) !important;
}

.dx-timeview-format12 .dx-dropdowneditor-input-wrapper {
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  height: 100%;
  width: 70px;
  display: flex;
}

dx-text-area.dx-texteditor-input-small-height .dx-texteditor-container {
  display: flex;
  position: relative;
  overflow: hidden;
  width: 100%;
  resize: vertical;
  height: 100%;
  min-height: 130px;
}

dx-text-area.dx-texteditor-input-min-height-description
  .dx-texteditor-container {
  display: flex;
  position: relative;
  overflow: hidden;
  width: 100%;
  resize: vertical;
  height: 100%;
  min-height: 58px;
}

dx-text-area.dx-texteditor-input-min-height .dx-texteditor-container {
  display: flex;
  position: relative;
  overflow: hidden;
  width: 100%;
  resize: vertical;
  height: 100%;
  min-height: 230px;
}

dx-text-area.dx-texteditor-input-height-1000 .dx-texteditor-container {
  display: flex;
  position: relative;
  overflow: hidden;
  width: 100%;
  resize: vertical;
  height: 100%;
  min-height: 450px;
}

dx-text-area.dx-texteditor-input-height-200 .dx-texteditor-container {
  display: flex;
  position: relative;
  overflow: hidden;
  width: 100%;
  resize: vertical;
  height: 100%;
  min-height: 150px;
}

dx-tag-box.dx-texteditor {
  max-width: 290px;
  width: 100%;
  background: var(
    --ds-background-tiles-panels,
    $ds-background-tiles-panels
  );
  border: 1px solid var(--neutral-50, $neutral-50);
  box-sizing: border-box;
  border-radius: 6px;
  height: auto !important;
}

.dx-tag-content {
  margin: 2.5px 0 0 2px;
  padding: 1px 25px 1px 6px;
  min-width: 40px;
  background-color: var(--neutral-70, $neutral-70);
  border-radius: 2px;
  color: var(--neutral-30, $neutral-30);
}

.dx-dropdownbutton-action.dx-buttongroup-last-item.dx-button
  .dx-button-content {
  text-align: left;
  padding-left: 10px;
  padding-right: 0;
}

.dx-checkbox-checked .dx-checkbox-icon {
  font: 17px/17px Lumen;
  text-align: center;
}

.dx-checkbox-checked .dx-checkbox-icon::before {
  content: "\e9a7" !important;
  font-size: 16px !important;
  position: absolute;
  display: block;
  width: 15px;
  top: 47%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px;
}

.dx-dropdownbutton .dx-button-has-text .dx-icon.dx-icon-right {
  float: right;
  margin-left: 4px;
  margin-right: 0;
}

.dx-widget input,
.dx-widget textarea {
  font-family: $Roboto;
  font-size: 0.75rem;
  line-height: 16px;
  color: var(--neutral-30, $neutral-30);
}

.payment-box-number.dx-widget input {
  font-family: "Roboto";
  font-size: 1rem !important;
  text-align: right;
  line-height: 18px;
  color: var(--neutral-30, $neutral-30);
}

.text-left-payment.dx-widget input {
  font-family: "Roboto";
  font-size: 1rem !important;
  text-align: left !important;
  line-height: 18px;
  color: var(--neutral-30, $neutral-30);
}

.payment-form-currency.dx-widget input {
  font-family: "Roboto";
  font-size: 1rem;
  line-height: 16px;
  color: var(--neutral-30, $neutral-30);
}

label {
  font-family: $Roboto-Medium;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: var(--text-dark-gray, $text-dark-gray);
  margin-bottom: 0px;
  strong {
    color: var(--theme-negative-30, $theme-negative-30);
    margin-left: 2px;
  }
}

.dx-texteditor.dx-editor-outlined {
  max-width: 290px;
  width: 100%;
  // background: var(--neutral-100, $neutral-100);
  border: 1px solid var(--neutral-50, $neutral-50);
  box-sizing: border-box;
  border-radius: 6px;
  height: 28px;
}

.dx-dropdowneditor.dx-state-active .dx-dropdowneditor-icon,
.dx-dropdowneditor.dx-state-hover:not(.dx-custom-button-hovered)
  .dx-dropdowneditor-icon {
  background-color: var(--neutral-85, $neutral-85) !important;
  border-color: transparent;
}

.menu-dropdown.dx-dropdowneditor-button.dx-state-active .dx-dropdowneditor-icon,
.menu-dropdown.dx-dropdowneditor.dx-dropdowneditor-active
  .dx-dropdowneditor-icon {
  background: transparent;
}

.dx-calendar-navigator
  .dx-calendar-caption-button.dx-button
  .dx-button-content {
  padding: 1px 15px 3px;
  color: var(--neutral-30, $neutral-30);
}

.dx-calendar-navigator .dx-button {
  height: 100%;
  border-radius: 0;
  display: table-cell;
  border-color: var(--neutral-70, $neutral-70);
  border: none;
  top: 5px;
  height: 28px;
  border-radius: 6px;
  text-decoration: none !important;
}

.dx-calendar-navigator-next-month.dx-button .dx-icon,
.dx-calendar-navigator-next-view.dx-button .dx-icon {
  color: var(--neutral-30, $neutral-30);
  font-family: "Lumen";
  position: relative;
  top: 4px;
  left: 7px;
}

.dx-calendar-navigator-previous-month.dx-button .dx-icon,
.dx-calendar-navigator-previous-view.dx-button .dx-icon {
  font-family: "Lumen";
  position: relative;
  top: 4px;
  left: 7px;
  color: var(--neutral-30, $neutral-30);
}

.dx-dropdowneditor-button.dx-state-active .dx-dropdowneditor-icon,
.dx-dropdowneditor.dx-dropdowneditor-active .dx-dropdowneditor-icon {
  background-color: transparent;
  border-color: transparent;
  color: var(--neutral-30, $neutral-30);
  opacity: 1;
  border-radius: 6px;
}

dx-select-box.dx-dropdowneditor-button.dx-state-active .dx-dropdowneditor-icon,
.dx-dropdowneditor.dx-dropdowneditor-active .dx-dropdowneditor-icon {
  background-color: transparent;
  border-color: transparent;
  color: var(--neutral-30, $neutral-30);

  opacity: 1;
  &::before {
    content: "\e5c7";
    color: var(--icons-brand, $icons-brand);
  }
}
// .search-box .dx-texteditor-buttons-container {
//   display: flex;
//   width: auto;
//   flex-grow: 0;
//   flex-basis: content;
//   align-items: center;
//   position: relative;
//   top: -22px;
// }

.dx-dropdowneditor-icon {
  font: 16px/16px Lumen;
}

.dx-dropdowneditor-icon::before {
  content: "\e5c5";
  position: absolute;
  display: block;
  width: 18px;
  top: 50%;
  margin-top: -9px;
  left: 50%;
  margin-left: -2px;
}

.dx-show-clear-button .dx-icon-clear {
  position: absolute;

  top: 50%;
  margin-top: -11px !important;
  padding: 8px;
  width: 24px;
  height: 22px;
  border-radius: 6px;
  font-family: "Lumen";
  background-position: 8px 8px;
  background-size: 14px 14px;
  font-size: 14px !important;
  text-align: center;
  line-height: 18px;
  color: transparent;
}

.dx-show-clear-button .dx-clear-button-area {
  width: 26px;
  min-width: 26px;
  height: 22px;
  right: 0;
  left: 12px;
  display: inline-block;
  > :hover {
    // background-color: var(--neutral-85, $neutral-85) !important;
  }
  .dx-icon-clear::before {
    color: transparent;
  }
}

.timeline-list .dx-radiobutton {
  display: none !important;
}

.dx-state-active.dx-show-clear-button .dx-clear-button-area {
  .dx-icon-clear::before {
    color: var(--neutral-30, $neutral-30);
  }
}

.dx-texteditor-input {
  &:hover {
    cursor: pointer;
  }
}

.dx-texteditor.dx-state-hover {
  border: 1px solid var(--neutral-30, $neutral-30) !important;
  cursor: pointer !important;
  .dx-icon-clear::before {
    color: var(--neutral-30, $neutral-30);
  }
}

.box-dictionary:hover {
  .button-content {
    background-color: var(--neutral-85, $neutral-85) !important;
    border-radius: 6px;
  }
}

.dx-texteditor.dx-state-hover {
  border: 1px solid var(--neutral-30, $neutral-30) !important;
  // border: 2px solid var(--neutral-30, $neutral-30) !important;
  // margin: -1px 1px -1px -1px !important;
  cursor: pointer !important;
  .dx-icon-clear::before {
    color: var(--neutral-30, $neutral-30);
  }
}

.dx-texteditor.dx-state-hover .dx-dropdowneditor-icon {
  background-color: var(--neutral-85, $neutral-85);
  border-radius: 6px;
}

.dx-dropdowneditor-button.dx-button-mode-contained,
.dx-dropdowneditor-button.dx-button-mode-outlined,
.dx-dropdowneditor-button.dx-button-mode-text {
  background: 0 0;
  width: 22px;
  height: 22px;
  min-width: 22px;
  padding: 0px;

  :hover {
    background-color: var(--neutral-85, $neutral-85);
    border-radius: 6px;
  }
}

.dictionary.dx-textarea .dx-texteditor-input {
  padding-right: 30px;
}

.input-required .dx-dropdowneditor-button.dx-button-mode-contained,
.dx-dropdowneditor-button.dx-button-mode-outlined,
.dx-dropdowneditor-button.dx-button-mode-text {
  background: 0 0;
  width: 22px;
  height: 22px;
  min-width: 22px;
  padding: 0px;

  :hover {
    background-color: var(--brand-80, $brand-80);
    border-radius: 6px;
  }
}

.input-required.dx-state-focused {
  border: 1px solid var(--theme-negative-30, $theme-negative-30) !important;
  outline: 1px solid var(--theme-negative-30, $theme-negative-30) !important;
  border-radius: 4px;
}

.dx-dropdowneditor-icon {
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  color: var(--neutral-30, $neutral-30);
  width: 22px;
  height: 100%;
  text-align: center;
  border-radius: 3px;

  margin-left: 12px;
}

.dx-icon-clear::before {
  content: "\e5cd";
}

.dx-placeholder {
  position: absolute;
  left: 0;
  max-width: 100%;
  width: auto;
  height: 100%;
  text-align: left;
  cursor: text;
  color: var(--text-light-gray, $text-light-gray);
  font-size: 0.75rem;
  line-height: 14px;
  font-family: "Roboto";
  pointer-events: none;
}

dx-text-area.remarks-textarea.dx-texteditor.dx-editor-outlined {
  min-height: 124px;
  width: 100%;
  background: var(--neutral-100, $neutral-100);
  border: 1px solid var(--neutral-50, $neutral-50);
  box-sizing: border-box;
  border-radius: 6px;
  height: auto !important;
}

dx-text-area.dx-texteditor.dx-editor-outlined {
  max-width: 290px;
  width: 100%;
  background: var(
    --ds-background-tiles-panels,
    $ds-background-tiles-panels
  );
  border: 1px solid var(--neutral-50, $neutral-50);
  box-sizing: border-box;
  border-radius: 6px;
  &:not(.auto-resize) {
    height: auto !important;
  }
}

dx-text-area.remarks-textarea {
  .dx-texteditor-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    min-height: 124px;
    overflow: hidden;
    width: 100%;
    resize: vertical;
    height: 100%;
  }
}

dx-text-area {
  .dx-texteditor-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100%;
    resize: vertical;
    height: 100%;
  }
}

dx-text-area.dictionary .dx-texteditor-container {
  display: flex;
  position: relative;
  overflow: hidden;
  width: 100%;
  resize: none;
  height: 100%;
}

.dx-texteditor-buttons-container
  > .dx-button.dx-button-has-icon:not(.dx-button-has-text)
  > .dx-button-content {
  padding: 0px;
}

.sum .dx-radiobutton .dx-item-content {
  width: 230px;
}

.inline-search-text-box .dx-icon {
  margin-top: 2px !important;
}

.inline-search-text-box .dx-icon.icon-search {
  position: absolute;
  top: 4px;
}

.dx-radiobutton-icon::before {
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid var(--neutral-70, $neutral-70);
  background-color: var(--neutral-100, $neutral-100);
  content: "";
  border-radius: 11px;
  box-sizing: content-box;
  top: 3px;
  position: relative;
}

.dx-texteditor.dx-editor-outlined {
  background: var(
    --ds-background-tiles-panels,
    $ds-background-tiles-panels
  );
  border: 1px solid var(--neutral-50, $neutral-50);
  border-radius: 6px;
}

.dx-texteditor.dx-editor-outlined.dx-state-disabled {
  background: var(--neutral-95, $neutral-95);
  border: 1px solid var(--neutral-50, $neutral-50);
  color: var(
    --ds-color-ui-state-text-icon-disabled,
    $ds-color-ui-state-text-icon-disabled
  );
}

.dx-checkbox-icon {
  width: 22px;
  height: 22px;
  border-radius: 2px;
  border: 1px solid var(--neutral-50, $neutral-50);
  background-color: var(
    --ds-background-tiles-panels,
    $ds-background-tiles-panels
  );
}

// .dx-texteditor.dx-state-active.dx-editor-filled:not(.input-required),
// .dx-texteditor.dx-state-active.dx-editor-outlined:not(.input-required),
// .dx-texteditor.dx-state-active.dx-editor-underlined:not(.input-required),
// .dx-texteditor.dx-state-focused.dx-editor-filled:not(.input-required),
// .dx-texteditor.dx-state-focused.dx-editor-outlined:not(.input-required),
// .dx-texteditor.dx-state-focused.dx-editor-underlined :not(.input-required),
// .dx-htmleditor.dx-state-active.dx-htmleditor-filled,
// .dx-htmleditor.dx-state-active.dx-htmleditor-outlined,
// .dx-htmleditor.dx-state-active.dx-htmleditor-underlined,
// .dx-htmleditor.dx-state-focused.dx-htmleditor-filled,
// .dx-htmleditor.dx-state-focused.dx-htmleditor-outlined,
// .dx-htmleditor.dx-state-focused.dx-htmleditor-underlined {
//   border: 1px solid var(--brand-neutral-10, $brand-neutral-10) !important;
//   outline: 1px solid var(--brand-neutral-10, $brand-neutral-10) !important;
//   // box-shadow: 0px 0px 8px rgba(88, 99, 106, 0.3);
//   border-radius: 4px;
// }
.dx-texteditor {
  &.dx-editor-filled,
  &.dx-editor-outlined,
  &.dx-editor-underlined {
    &.dx-state-active,
    &.dx-state-focused {
      &:not(.input-required) {
        border: 1px solid
          var(--ds-color-ui-state-brand-focus, $ds-color-ui-state-brand-focus) !important;
        outline: 1px solid
          var(--ds-color-ui-state-brand-focus, $ds-color-ui-state-brand-focus) !important;
        // box-shadow: 0px 0px 8px rgba(88, 99, 106, 0.3);
        border-radius: 4px;
      }
    }
  }
}

.dx-htmleditor {
  &.dx-htmleditor-outlined,
  &.dx-htmleditor-outlined,
  &.dx-htmleditor-underlined {
    &.dx-state-active,
    &.dx-state-focused {
      &:not(.input-required) {
        border: 1px solid
          var(--ds-color-ui-state-brand-focus, $ds-color-ui-state-brand-focus) !important;
        outline: 1px solid
          var(--ds-color-ui-state-brand-focus, $ds-color-ui-state-brand-focus) !important;
        // box-shadow: 0px 0px 8px rgba(88, 99, 106, 0.3);
        border-radius: 4px;
      }
    }
  }
}

/* checkbox */
.dx-checkbox {
  display: inline-flex;
}
.dx-checkbox-icon {
  width: 15px;
  height: 20px;
  border-radius: 2px;
  border: 1px solid var(--neutral-50, $neutral-50);
  border: none;
}

.dx-checkbox.dx-state-hover .dx-checkbox-icon {
  border: none;
}

.dx-checkbox.dx-state-focused .dx-checkbox-icon {
  //font-weight: bold;
  color: var(--icons-brand, $icons-brand);
}

.dx-checkbox.dx-checkbox-checked {
  &:after {
    background: transparent !important;
  }
}

.checboxCustom {
  .dx-checkbox-text {
    text-align: left;
    font-family: $Roboto;
    margin-left: -22px;
    padding-left: 27px;
    font-style: normal;
    width: auto;
    font-weight: normal;
    font-size: 0.8125rem;
    color: var(--neutral-30, $neutral-30);
    position: relative;
  }
}

.dx-checkbox-text {
  text-align: left;
  font-family: $Roboto;
  margin-left: -22px;
  padding-left: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  color: var(--neutral-30, $neutral-30);
  position: relative;
}

/* radiobutton */
.dx-radiogroup .dx-radiobutton,
.dx-radiogroup .dx-radiobutton-icon {
  margin: 0;
  border: none;
}

.dx-radiobutton.dx-state-active .dx-radiobutton-icon {
  background-color: rgba(96, 96, 96, 0);
}

.article .dx-texteditor.dx-editor-outlined {
  border: transparent;
  background-color: transparent;
}

.dx-button-mode-text.dx-button-default .dx-icon {
  color: var(--text-gray, $text-gray);
}

.article {
  .dx-texteditor.dx-state-active.dx-editor-filled,
  .dx-texteditor.dx-state-active.dx-editor-outlined,
  .dx-texteditor.dx-state-active.dx-editor-underlined,
  .dx-texteditor.dx-state-focused.dx-editor-filled,
  .dx-texteditor.dx-state-focused.dx-editor-outlined,
  .dx-texteditor.dx-state-focused.dx-editor-underlined {
    border: transparent;
    box-shadow: none;
    border-radius: 4px;
  }

  .dx-widget input,
  .dx-widget textarea {
    display: none;
  }

  .dx-dropdowneditor-active {
    border: transparent !important;
    box-shadow: none;
  }

  .dx-show-clear-button .dx-icon-clear {
    display: none;
  }
}

.dx-radiobutton .dx-item-content {
  font-family: $Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8125rem;
  line-height: 22px;
  color: var(--neutral-30, $neutral-30);
  margin-right: 20px;
}

.dx-bordered-bottom-view
  .dx-treelist-rowsview
  .dx-treelist-nowrap
  .dx-scrollable
  .dx-visibility-change-handler
  .dx-scrollable-both
  .dx-scrollable-simulated {
  background-color: transparent;
}

@media (max-width: 768px) {
  .dx-radiobutton .dx-item-content {
    margin-right: 0;
  }
}

.dx-radiobutton-icon {
  &::before {
    width: 14px;
    height: 14px;
    border: 1px solid var(--neutral-50, $neutral-50);
  }
}

.dx-radiobutton.dx-state-hover .dx-radiobutton-icon {
  &::before {
    border: 1px solid var(--brand-neutral-10, $brand-neutral-10);
  }
}

.dx-radiobutton.dx-state-focused:not(.dx-state-active) .dx-radiobutton-icon {
  &::before {
    border: 1px solid var(--neutral-30, $neutral-30) !important;
    box-shadow: 0px 0px 8px rgba(88, 99, 106, 0.3);
  }
}

.dx-radiobutton-icon.dx-radiobutton-icon-checked {
  &::before {
    border: 1px solid var(--neutral-50, $neutral-50) !important;
  }
}
.dx-radiobutton-icon-checked .dx-radiobutton-icon-dot {
  background: var(--icons-brand, $icons-brand);
  display: block;
  margin: auto;
  top: 7px;
  width: 8px;
  height: 8px;
  content: "";
  border-radius: 4px;
  left: 4px;
  position: absolute;
  border: none;
}

.dx-radiobutton {
  border-radius: 6px;
  border: 1px solid transparent !important;
  &.dx-state-focused {
    border-color: var(--neutral-30, $neutral-30) !important;
  }
}

.dx-state-readonly .dx-radiobutton-icon-checked .dx-radiobutton-icon-dot {
  background: var(--text-light-gray, $text-light-gray) !important;
}

.dx-state-readonly .dx-radiobutton-icon.dx-radiobutton-icon-checked::before {
  border: 1px solid var(--neutral-50, $neutral-50) !important;
}

.dx-radio-value-container {
  display: table-cell;
  padding-right: 0px;
  padding-left: 5px;
  vertical-align: middle;
}

.dx-dropdowneditor-active {
  border: 2px solid
    var(--ds-color-ui-state-brand-focus, $ds-color-ui-state-brand-focus) !important;
  // box-shadow: 0px 0px 6px rgba(88, 99, 106, 0.3);
}

/* date box */
dx-date-box {
  .dx-texteditor-buttons-container {
    border-left: none;
  }
}
.dx-datebox-calendar .dx-dropdowneditor-icon {
  font: 1.187rem/1.187rem Lumen;
  &::before {
    content: "\ea10" !important;
    font-size: 16px;
    color: var(--neutral-30, $neutral-30);
  }
}

.wapro-date-box.dx-datebox-calendar .dx-dropdowneditor-icon {
  font: 1.187rem/1.187rem Lumen;
  &::before {
    content: "\ea10" !important;
    font-size: 16px;
    position: absolute;
    left: 11.5px;
    color: var(--neutral-30, $neutral-30);
  }
}

/* dropdow */
dx-select-box {
  .dx-dropdowneditor-icon {
    font: 1.187rem/1.187rem Lumen;
    &::before {
      //content: "\e313";
      content: "\e5c5";
      font-size: 16px;
    }
  }
}

/* dx-drop-down-box */
dx-drop-down-box {
  .dx-texteditor-buttons-container {
    border-left: none;
  }

  .dx-dropdowneditor-icon {
    border: 1px solid transparent;
    color: var(--neutral-30, $neutral-30);
    font: 1.187rem/1.187rem Lumen;
    width: 22px;
    height: 100%;
    text-align: center;
    border-radius: 3px;
    &::before {
      content: "\e5c5";
      font-size: 16px;
    }
  }
}

.dx-numberbox-spin-down,
.dx-numberbox-spin-up {
  position: relative;
  width: 100%;
  height: 46%;
  cursor: pointer;
}

.dx-numberbox-spin-container {
  overflow: hidden;
  width: 22px;
  padding-top: 2px;
  border-radius: 4px;
}

.dx-numberbox-spin-up-icon {
  font: 16px/1 Lumen;
  color: var(--neutral-30, $neutral-30);
}

.dx-numberbox-spin-button {
  padding: 0px;
}

.dx-numberbox-spin-down-icon {
  font: 16px/1 Lumen;
  color: var(--neutral-30, $neutral-30);
}

.dx-numberbox-spin-up-icon::before {
  content: "\e145";
  font-size: 16px;
  margin-top: -7px;
  margin-left: -9px;
}

.dx-numberbox-spin-up-icon {
  position: relative;
  left: 2px;
}

.dx-numberbox-spin-down-icon::before {
  content: "\e15b";
  font-size: 16px;
  top: 12px;
}

.dx-state-hover.dx-numberbox-spin-button .dx-numberbox-spin-down-icon,
.dx-state-hover.dx-numberbox-spin-button .dx-numberbox-spin-up-icon {
  -webkit-box-shadow: 0 0 0 1px transparent inset;
  box-shadow: 0 0 0 1px transparent inset;
  background-color: var(--neutral-85, $neutral-85);
}

.dx-numberbox-spin-touch-friendly .dx-numberbox-spin-down,
.dx-numberbox-spin-touch-friendly .dx-numberbox-spin-up {
  width: 50%;
  height: 100%;
  display: inline-block;
  position: relative;
  left: 22px;
}

.dx-numberbox-spin-down-icon {
  position: absolute;
  left: -44px;
}

.dx-numberbox-spin-touch-friendly .dx-numberbox-spin-container {
  width: 45px;
  border-left: none;
}

.dx-numberbox-spin-touch-friendly .dx-numberbox-spin-down-icon,
.dx-numberbox-spin-touch-friendly .dx-numberbox-spin-up-icon {
  background-position: center;
  width: 20px;
  height: 20px;
  border-radius: 6px;
}

.form-group-inline-right {
  padding-top: 1px;
  width: 420px;
  float: right;
  &_w415 {
    width: 431px;
    float: none;
  }
  //padding-top: 6px;
  //padding-top: 14px;
}
.form-group-inline-left {
  padding-top: 1px;
  width: 420px;
  float: left;
  padding-left: 59px;
  border-left: 1px solid var(--neutral-50, $neutral-50);
}

.form-group-inline {
  label {
    @include container;
    font-weight: 500;
    width: 70px;
    margin-right: 14px;
  }
  dx-text-box {
    @include container;
    margin-bottom: 12px;
  }

  dx-number-box {
    @include container;
    margin-bottom: 12px;
  }

  dx-select-box {
    @include container;
    margin-bottom: 12px;
  }
  dx-text-area {
    @include container;
    margin-bottom: 12px;
  }

  dx-drop-down-box {
    @include container;
    margin-bottom: 12px;
  }

  app-country-box {
    @include container;
    margin-bottom: 12px;
  }
}

.dx-texteditor-input {
  margin: 0;
  padding: 8px 9px 8px;
  background: rgba(0, 0, 0, 0);
  color: var(--neutral-30, $neutral-30);
  font-size: 1em;
  border-radius: 4px;
  min-height: 25px;
}

.box-body .form-group-inline-right {
  label {
    @include container;
    margin-right: 13px;
    margin-left: 13px;
    width: auto !important;
    font-weight: 500;
    position: relative;
    top: 0px;
  }
}

input.calculator-window {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 26px;
  text-align: right;
  color: var(--neutral-10, $neutral-10);
  position: relative;
  margin-bottom: 0px;
  top: -12px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.box-body .form-group-inline-right {
  width: auto !important;
  min-width: max-content;
  padding-left: 2px;
  float: right;
}

.form-group-inline-left,
.form-group-inline-right {
  label {
    @include container;
    margin-right: 13px;
    width: 70px;
    font-weight: 500;
    position: relative;
    top: 0px;
  }
  dx-text-box {
    @include container;
    margin-bottom: 6px;
  }
  dx-text-area {
    @include container;
    margin-bottom: 6px;
  }
  dx-number-box {
    @include container;
    margin-bottom: 6px;
  }
  dx-check-box {
    @include container;
    margin-bottom: 6px;
  }
  dx-date-box {
    @include container;
    margin-bottom: 6px;
  }
  dx-radio-group {
    @include container;
  }

  dx-select-box {
    @include container;
    margin-bottom: 6px;
  }

  dx-drop-down-box {
    @include container;
    margin-bottom: 6px;
  }

  app-country-box {
    @include container;
    margin-bottom: 6px;
  }
}

.dx-button-mode-contained.dx-button-default {
  border-left: 1px solid var(--neutral-50, $neutral-50);
  background-color: transparent;
}

.dx-button-mode-contained.dx-button-default .dx-icon {
  color: var(--neutral-30, $neutral-30);
}

.dx-button-mode-contained.dx-button-default.dx-state-hover {
  background-color: var(--neutral-85, $neutral-85);
  border-left: nonet;
}

.dx-button-mode-contained.dx-button-default.dx-state-active {
  background-color: transparent;
  // border-left: 1px solid var(--neutral-50, $neutral-50);
  // box-shadow: 0px 0px 6px rgba(88, 99, 106, 0.3);
}

.dx-button-mode-contained.dx-button-default.dx-state-focused {
  background-color: transparent;
  border-left: none;
}

.dx-checkbox-checked .dx-checkbox-icon::before {
  color: var(--icons-brand, $icons-brand) !important;
  border: none;
}

.checkbox-payment.dx-checkbox-checked .dx-checkbox-icon::before {
  color: var(--theme-positive-30, $theme-positive-30) !important;
  border: none;
}

.dx-checkbox.dx-state-disabled {
  background: transparent;
}

.dx-checkbox-checked.dx-state-disabled .dx-checkbox-icon::before {
  color: var(
    --ds-color-ui-state-text-icon-disabled,
    $ds-color-ui-state-text-icon-disabled
  ) !important;
}

.dx-state-disabled .dx-checkbox .dx-checkbox-icon,
.dx-state-disabled .dx-checkbox-text,
.dx-state-disabled.dx-checkbox .dx-checkbox-icon {
  opacity: 1;
}

.dx-checkbox-checked .dx-checkbox-icon .dx-state-hover {
  color: var(--icons-brand, $icons-brand) !important;
  border: none;
}

.dx-checkbox {
  font: 17px/17px Lumen;
  text-align: center;
  .dx-checkbox.dx-state-hover .dx-checkbox-icon {
    border: none;
  }
}

.dx-checkbox.dx-state-hover .dx-checkbox-icon::before {
  color: var(--neutral-30, $neutral-30);
}

.inside {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: -webkit-center;
}

.dx-checkbox .dx-checkbox-icon::before {
  color: var(--neutral-50, $neutral-50);
  content: "\eab2";
  position: absolute;
  display: block;
  width: 15px;
  top: 47%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px;
}

dx-data-grid .dx-checkbox .dx-checkbox-icon::before {
  color: var(--neutral-50, $neutral-50);
  content: "\eab2";
  position: absolute;
  display: block;
  width: 15px;
  top: 47%;
  margin-top: -7px;
  left: 50%;
  margin-left: -8px;
}

.focus-on-detail {
  .dx-checkbox.dx-state-focused .dx-checkbox-icon {
    &::before {
      box-shadow: none;
    }
  }
}

.dx-checkbox.dx-state-focused .dx-checkbox-icon {
  &::before {
    color: var(--neutral-30, $neutral-30);
    -webkit-text-stroke-width: 0.03em !important;
    -webkit-font-smoothing: antialiased !important;
    // box-shadow: 0px 0px 8px rgba(88, 99, 106, 0.3);
    filter: drop-shadow(0px 0px 8px rgba(88, 99, 106, 0.3));
  }
  border: none !important;
}

.dx-header-row .dx-checkbox.dx-state-focused .dx-checkbox-icon {
  border: none !important;
}

.select-box-without-border {
  border: none !important;
  display: inline-block;
}

.input-filter {
  width: 121px;
  border: 1px solid var(--neutral-50, $neutral-50) !important;
  box-sizing: border-box;
  display: inline-block !important;
  border-radius: 20px !important;

  .dx-button-mode-contained.dx-button-default {
    border-left: none;
    background-color: transparent;
  }

  .dx-texteditor-buttons-container
    > .dx-button.dx-button-has-icon:not(.dx-button-has-text)
    > .dx-button-content {
    padding: 0px;
    padding-right: 6px;
  }

  .dx-button-mode-contained.dx-button-has-icon {
    background: transparent !important;
    height: 32px !important;
    border-bottom-right-radius: 20px !important;
    border-top-right-radius: 20px !important;
  }
}

.no-resize .dx-texteditor-container {
  display: flex;
  position: relative;
  overflow: hidden;
  width: 100%;
  resize: none;
  height: 100%;
}

.dx-textarea .dx-texteditor-input {
  resize: none;
  font-family: "Roboto";
  display: block;
  overflow: auto;
  white-space: pre-wrap;
  margin: 0;
  height: 100%;
  padding-left: 7px;
  padding-top: 7px;
}

.dx-textarea.position-prod-doc .dx-texteditor-input {
  resize: none;
  font-family: "Roboto";
  display: block;
  overflow: auto;
  white-space: pre-wrap;
  margin: 0;
  height: 60px;
  padding-left: 7px;
  padding-top: 7px;
}

.input-filter .dx-button-mode-contained.dx-button-default .dx-icon {
  color: var(--neutral-50, $neutral-50);
  &::before {
    font-size: 1.125rem;
    line-height: 18px;
    color: var(--neutral-50, $neutral-50);
    position: relative;
  }
}

.dx-texteditor-buttons-container {
  display: flex;
  width: auto;
  flex-grow: 0;
  flex-basis: content;
  align-items: center;
  margin-right: 2px;
}

.dx-state-readonly .dx-texteditor-buttons-container {
  .dx-state-disabled {
    cursor: pointer;
    background: transparent !important;
    box-sizing: border-box;
  }

  .absui-icon--add::before {
    color: transparent !important;
  }

  .absui-icon--remove::before {
    color: transparent !important;
  }
}

.dx-state-disabled .dx-texteditor-buttons-container {
  background: transparent !important;
}

.textbox-with-btns,
dx-drop-down-box,
dx-select-box {
  .dx-clear-button-area {
    width: 34px;
    min-width: 34px;
    right: 0;
    border-right: none;
  }

  .dx-icon-clear {
    font: 1.187rem/1.187rem Lumen;
    color: var(--neutral-30, $neutral-30);
  }
  .dx-icon-clear::before {
    content: "\e90b";
    position: relative;
    color: var(--neutral-30, $neutral-30);
    font-size: 16px;
    left: -4px;
    top: -6px;
  }
}

@media (max-width: 1200px) {
  .form-group-inline-left {
    width: 360px;
    float: left;
    padding-left: 0px;
    border-left: none;
  }

  .form-group-inline-right {
    text-align: left;
    width: 360px;
    float: left;
    padding-right: 0px;
  }

  .form-group-inline-left,
  .form-group-inline-right {
    label {
      @include container-mobile;
    }
  }

  .form-group-inline {
    label {
      @include container-mobile;
    }
  }
}

@media (max-width: 767.98px) {
  .form-group-inline-left,
  .form-group-inline-right {
    label {
      @include container-mobile;
    }
  }

  .form-group-inline-right dx-check-box {
    height: 25px !important;
  }
}

/* dxfileinput */

.hide-selected-items .dx-fileuploader-files-container {
  display: none !important;
}

.menu-search-list.dx-list:not(.dx-list-select-decorator-enabled)
  .dx-list-item.dx-state-focused {
  background-color: var(--text-light-gray, $text-light-gray) !important;
  .header-menu-name {
    color: var(--neutral-100, $neutral-100) !important;
  }
  .patch-menu {
    color: var(--neutral-100, $neutral-100) !important;
  }
}

.dx-selection.dx-row-focused .dx-checkbox-checked .dx-checkbox-icon::before {
  content: "\e9a8" !important;
}

.timeline-list {
  .dx-list-item-content {
    padding: 4px;
    padding-left: 0px;
    position: relative;
    left: -15px;
  }

  .dx-state-hover .dx-radiobutton-icon::before {
    border: none;
    background: transparent;
  }

  .dx-state-focused .dx-radiobutton-icon::before {
    border: none !important;
  }
  .dx-list-item-selected .p-timeline-date {
    color: var(--text-brand, $text-brand);
  }

  .dx-list-item-selected .p-timeline-user {
    color: var(--text-brand, $text-brand);
  }

  .dx-list-item-selected .p-timeline-type {
    color: var(--text-brand, $text-brand);
  }
  .dx-radiobutton-icon::before {
    width: 14px;
    height: 14px;
    border: 1px solid transparent;
  }
  .dx-radiobutton-icon.dx-radiobutton-icon-checked::before {
    font-family: "Lumen";
    content: "\eb63";
    font-size: 18px;
    color: var(--icons-brand, $icons-brand);
    border: none !important;
    position: relative;
    top: -1px;
    left: -18px;
  }
  .dx-radiobutton-icon-checked .dx-radiobutton-icon-dot {
    display: none;
  }
  .dx-list-item {
    width: calc(100% - 30px);
    border-top: none;
    color: var(--neutral-30, $neutral-30) !important;
    height: 47px;
    padding: 3px 4px 0px 15px;
    border-radius: 6px;
    background: linear-gradient(
      to right,
      transparent 0%,
      transparent calc(0% - -15px),
      var(--neutral-85, $neutral-85) calc(0% - 5px),
      var(--neutral-85, $neutral-85) calc(0% + 13px),
      transparent calc(0% + 17px),
      transparent 0%
    );
    &:hover {
      background: var(--neutral-85, $neutral-85);
      &::before {
        color: var(--neutral-50, $neutral-50);
      }
    }

    &::before {
      font-family: "Lumen";
      content: "\ea83";
      color: var(--neutral-50, $neutral-50);
      font-size: 18px;
      position: relative;
      left: -8px;
      top: 9px;
    }
  }

  .dx-list-item.dx-state-focused {
    background-color: transparent;
    .p-timeline-date {
      color: var(--text-brand, $text-brand);
    }
  }

  .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-hover {
    background: var(--neutral-85, $neutral-85);
    color: var(--neutral-10, $neutral-10) !important;
    &:hover {
      .icon-star {
        display: inline;
      }
    }
  }
}

.p-timeline-date {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: var(--neutral-10, $neutral-10);
  margin-bottom: 0px;
}

.p-timeline-user {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  color: var(--neutral-30, $neutral-30);
  margin-bottom: 0px;
}

.p-timeline-type {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: var(--neutral-30, $neutral-30);
}

.dx-radiobutton.dx-state-focused:not(.dx-state-active) {
  .dx-radiobutton-icon-checked::before {
    border: 1px solid var(--icons-brand, $icons-brand) !important;
  }
  .dx-radiobutton-icon::before {
    box-shadow: none;
  }
}

.sum-rad .dx-radio-value-container {
  padding-left: 0;
  .dx-radiobutton-icon {
    width: 16px;
    height: 16px;
    margin-right: 7px !important;
    margin-bottom: 8px !important;
  }
  .dx-radiobutton-icon::before {
  }
}

.with-more-btn {
  .dx-button-mode-contained.dx-button-default {
    border-left: none;
    .absui-icon--folder-expand:before {
      font-size: 18px;
    }
  }
}

.select-report-list {
  border: none !important;
  .dx-texteditor-input-container {
    display: none;
  }
  // .dx-dropdownbutton-action {
  //   width: 17px;
  // }

  .dx-dropdownbutton-toggle {
    width: 17px !important;
    min-width: 17px;
    .dx-icon {
      width: 17px;
      height: 17px;
      &::before {
        font-size: 17px;
      }
    }
    &.dx-button.dx-state-hover {
      // background-color: var(--neutral-80, $neutral-80) !important;
    }
  }
  .dx-dropdownbutton-action {
    &.dx-button.dx-state-hover {
      // background-color: var(--neutral-80, $neutral-80) !important;
    }
    width: 38px;
    .dx-button-content {
      padding: 0 !important;
      &::before {
        display: inline-block;
        font: normal normal normal 21px Lumen;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 18px;
        content: "\e8ad";
        color: var(--text-gray, $text-gray);
        -webkit-text-stroke-width: 0.012em !important;
        -webkit-font-smoothing: antialiased !important;
      }
    }
  }

  &.disabled,
  &.dx-state-disabled {
    .dx-dropdownbutton-action .dx-button-content::before {
      color: var(
        --ds-color-ui-state-text-icon-disabled,
        $ds-color-ui-state-text-icon-disabled
      ) !important;
    }
  }
}

.transmission-params.dx-dropdownbutton,
.print-params-retail.dx-dropdownbutton,
.print-params.dx-dropdownbutton,
.crm-settings.dx-dropdownbutton {
  // border: none !important;
  .dx-texteditor-input-container {
    display: none;
  }
  // .dx-dropdownbutton-action {
  //   width: 17px;
  // }

  .dx-dropdownbutton-toggle {
    width: 17px !important;
    min-width: 17px;
    .dx-icon {
      width: 17px;
      height: 17px;
      &::before {
        font-size: 17px;
      }
    }
  }
  .dx-dropdownbutton-action {
    width: 38px;
    .dx-button-content {
      padding: 0 !important;
      width: 35px;
      text-align: center;
      &::before {
        width: 38px;
        display: inline-block;
        font: normal normal normal 21px Lumen;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 18px;
        content: "\ea38";
        color: var(--text-gray, $text-gray);
        -webkit-text-stroke-width: 0.012em !important;
        -webkit-font-smoothing: antialiased !important;
      }
    }
  }
}

.transmission-params.dx-dropdownbutton {
  .dx-dropdownbutton-action {
    .dx-button-content:before {
      content: "\e991";
    }
  }
}
.print-params.dx-dropdownbutton {
  .dx-dropdownbutton-action {
    .dx-button-content:before {
      content: "\ea38";
    }
  }
}

.print-params-retail.dx-dropdownbutton {
  .dx-dropdownbutton-action {
    .dx-button-content:before {
      content: "\ec6c";
    }
  }
}

.crm-settings.dx-dropdownbutton {
  .dx-dropdownbutton-action {
    .dx-button-content:before {
      content: "\ec7c";
    }
  }
}

.list-title {
  padding: 9px 10px 0px 10px;
  font-size: 12px;
  line-height: 18px;
  font-family: $Roboto-Medium;
}

.report-list {
  .dx-list-item-content {
    padding: 0 !important;
    height: 34px !important;
  }
  .dx-list-item:hover {
    background-color: var(--neutral-90, $neutral-90);
  }
}

// .dx-dropdownbutton-popup-wrapper .dx-scrollview-content

.text-box-btns-no-border .dx-button-mode-contained.dx-button-default {
  border-left: none;
  &.dx-state-hover {
    border-left: none !important;
  }
  .absui-icon--clear-filter:before {
    font-size: inherit !important;
    -webkit-text-stroke-width: 0;
  }
  .absui-icon--folder-expandd:before {
    top: 0;
  }
}

.dx-state-readonly.textbox-with-btns .dx-texteditor-buttons-container {
  display: flex !important;
}

.textbox-with-btns {
  .dx-button-mode-contained.dx-button-default {
    border-left: none;
  }
  .dx-icon-clear::before {
    top: 0 !important;
    left: 0 !important;
  }
}

.app-list.dx-list:not(.dx-list-select-decorator-enabled)
  .dx-list-item.dx-list-item-selected {
  background-color: var(--brand-90, $brand-90) !important;
  color: #4c4c4c;
  font-weight: normal;
}

.dx-htmleditor-toolbar {
  .dx-toolbar-after,
  .dx-toolbar-before,
  .dx-toolbar-center {
    display: inline-flex;
    align-items: center;
  }
  .dx-selectbox {
    border: 1px solid transparent !important;
    outline: 1px solid transparent !important;
    height: 24px !important;
    &.dx-state-hover {
      background-color: var(--neutral-90, $neutral-90) !important;
    }
    .dx-placeholder::before {
      color: var(--text-gray, $text-gray);
      font-weight: 500;
      padding: 0 9px;
      height: 100%;
      line-height: 22px;
    }
  }
}

.dx-htmleditor-content {
  .dx-variable > span {
    padding: 0 6px 0.5px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
  }
}

.dx-htmleditor .dx-htmleditor-toolbar-wrapper {
  .dx-overlay-content {
    border-width: 1px;
    padding: 5px;
    min-width: 210px;
    max-height: 40vh !important;
    &.dx-state-focused {
      border: 1px solid var(--neutral-70, $neutral-70);
    }

    .dx-popup-content {
      overflow: hidden;
    }
  }
  .dx-dropdownmenu-list .dx-toolbar-hidden-button {
    &.dx-list-item {
      &.dx-state-hover:has(.dx-htmleditor-toolbar-menu-separator) {
        background-color: var(--neutral-100, $neutral-100) !important;
        cursor: default;
      }

      .dx-htmleditor-toolbar-menu-separator {
        margin-top: 4px;
        padding-bottom: 4px;
      }
    }
    .dx-button {
      height: 30px;
      padding: 8px 15px 4px;

      &.dx-state-focused,
      &.dx-state-active,
      &.dx-state-focused-active,
      &.dx-state-selected {
        border: 0px solid var(--neutral-70, $neutral-70) !important;
        &:not(.dx-state-hover) {
          background-color: var(--neutral-90, $neutral-90) !important;
        }
      }

      &.dx-state-hover {
        background-color: var(--neutral-90, $neutral-90) !important;
        border-width: 0px !important;
      }

      .dx-button-content {
        display: flex;
        justify-content: flex-start;
        gap: 8px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .route-mobile .dx-empty-message {
    display: none;
  }
}

.dx-selectbox-popup-wrapper .dx-list {
  background-color: var(--ds-background-dropdown-menu, $ds-background-dropdown-menu);
}

.dx-radiobutton-icon {
  background: var(
    --ds-background-tiles-panels,
    $ds-background-tiles-panels
  );
}

.dx-checkbox-checked .dx-checkbox-icon {
  color: var(--brand-90, $brand-90);
  background-color: inherit;
  height: 13px;
  width: 13px;
}


.permission-radio .dx-radiobutton{
  width: 340px;
  padding: 12px 8px;
  border: 1px solid $neutral-80 !important;
  margin-bottom: 6px;
}

.permission-radio .dx-radiobutton-checked{
  background-color: $brand-90;
}

.permission-radio .dx-radiogroup .dx-radiobutton, .dx-radiogroup .dx-radiobutton-icon {
  margin: 0;
  border: none;
  background: transparent;
}