@use "../../assets/scss/colors" as *;

.dx-treelist-rowsview .dx-treelist-collapsed span::before {
  content: "\e146";
  font-family: Lumen;
  position: absolute;
  width: 18px;
  height: 18px;
  font-size: 18px;
  margin-left: 0px;
}
.dx-treelist-rowsview .dx-row-focused .dx-treelist-expanded span::before {
  color: var(--neutral-100, $neutral-100);
}

.dx-treelist-rowsview .dx-treelist-expanded span::before {
  content: "\e909";
  font-family: Lumen;
  position: absolute;
  width: 18px;
  height: 18px;
  font-size: 18px;
  margin-left: 0px;
  color: var(--neutral-30, $neutral-30);
}

dx-tree-list {
  input {
    padding-left: 4px;
    padding-top: 4px;
  }
  tr[aria-expanded="true"] {
    background: var(--neutral-90, $neutral-90);
  }
  .dx-treelist-rowsview .dx-row-focused.dx-data-row > td:not(.dx-focused),
  .dx-treelist-rowsview .dx-row-focused.dx-data-row > td.dx-focused {
    background-color: var(--neutral-40, $neutral-40);
    color: var(--neutral-100, $neutral-100);
  }
  .dx-treelist-rowsview .dx-row-focused.dx-data-row > td,
  .dx-treelist-rowsview .dx-row-focused.dx-data-row > tr:last-child > td {
    border-bottom: none;
  }
  .dx-treelist-headers .dx-texteditor-input,
  .dx-treelist-rowsview .dx-texteditor-input {
    min-height: 0;
  }
  tr.dx-data-row {
    height: 35px;
  }
  .dx-header-row {
    td {
      font-family: Roboto-Medium;
      background-color: var(--neutral-95, $neutral-95);
      color: var(--neutral-10, $neutral-10);
      font-size: 0.75rem;
      line-height: 14px;
      border-right: 1px solid var(--neutral-80, $neutral-80);
      &:last-child {
        border-right: none;
      }
    }
  }
  .dx-data-row {
    font-family: Roboto;
    font-size: 0.75rem;
    line-height: 14px;
  }
}

// .dx-cell-focus-disabled .blocked-txt{
//     color: var(--neutral-100, $neutral-100);
// }
// .blocked-txt{
//     color: var(--text-light-gray, $text-light-gray);
// }

.dx-treelist-content .dx-treelist-table .dx-row > td,
.dx-treelist-content .dx-treelist-table .dx-row > tr > td {
  vertical-align: middle;
}
.dx-treelist-focus-overlay {
  border: none;
}

dx-tree-list.tree-list-as-tree-view {
  .dx-treelist-headers {
    display: none;
  }
  .dx-treelist-headers + .dx-treelist-rowsview {
    border-top: none;
    background-color: transparent;
  }
  .dx-treelist-container {
    background-color: transparent;
  }
  .dx-row.dx-data-row.dx-column-lines:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  .dx-row.dx-data-row.dx-selection {
    td {
      background-color: transparent !important;
      border-bottom: none !important;
      border-top: none !important;
    }
    .dx-treelist-text-content {
      font-weight: bold;
      color: var(--text-brand, $text-brand) !important;
    }
  }
  tr.dx-data-row {
    height: 28px;
  }
  .dx-treelist-expanded span::before {
    content: "\e5c5" !important;
    color: var(--text-dark-gray, $text-dark-gray);
    font-size: 16px;
    width: 18px;
    height: 18px;
    position: absolute;
    left: -5px;
    top: 5px;
  }
  tr td.dx-treelist-cell-expandable {
    padding-top: 5px;
    padding-bottom: 4px;
  }
  .dx-treelist-collapsed span::before {
    content: "\ea1d" !important;
    color: var(--text-dark-gray, $text-dark-gray);
    font-size: 16px;
    width: 18px;
    height: 18px;
    position: absolute;
    left: -5px;
    top: 5px;
  }
  tr[aria-expanded="true"] {
    background: transparent !important;
  }
}

.configuration-grid
  .dx-treelist-rowsview
  .dx-row-focused
  .dx-treelist-expanded
  span::before {
  color: var(--neutral-10, $neutral-10);
}

.configuration-grid .dx-treelist-rowsview .dx-state-hover.dx-data-row > td {
  background-color: var(--neutral-95, $neutral-95) !important;
}

.configuration-grid .dx-data-row:hover {
  background-color: var(--ds-color-ui-state-neutral-hover, $ds-color-ui-state-neutral-hover) !important;
}
.configuration-grid
  .dx-treelist-rowsview
  .dx-row-focused.dx-data-row
  > td:not(.dx-focused),
dx-tree-list.configuration-grid
  .dx-treelist-rowsview
  .dx-row-focused.dx-data-row
  > td.dx-focused {
  background-color: var(--neutral-90, $neutral-90) !important;
  //color: var(--neutral-10, $neutral-10);
  border-bottom: 1px solid var(--neutral-70, $neutral-70);
  color: var(--neutral-30, $neutral-30);
}

.configuration-grid
  .dx-treelist-rowsview
  tr:not(.dx-row-focused)
  .dx-treelist-empty-space {
  color: var(--neutral-30, $neutral-30);
}

.configuration-grid .dx-row-focused {
  .dx-progressbar-percent,
  i::before,
  .black-txt,
  .grey-txt,
  .hov-underline {
    color: var(--neutral-10, $neutral-10) !important;
  }
}

.configuration-grid .dx-datagrid-rowsview .dx-row-focused.dx-data-row {
  .dx-command-edit:not(.dx-focused) .dx-link,
  > td:not(.dx-focused),
  > tr > td:not(.dx-focused) {
    background-color: var(--neutral-90, $neutral-90) !important;
    color: var(--neutral-10, $neutral-10) !important;
  }
}

// .configuration-grid .dx-treelist .dx-row-lines > td {
//     border-bottom: 1px solid var(--neutral-70, $neutral-70);
// }

.configuration-grid
  .dx-datagrid-rowsview
  .dx-row-focused.dx-data-row:not(.dx-row-lines) {
  > td,
  > tr:last-child > td {
    background-color: var(--ds-color-ui-state-neutral-hover, $ds-color-ui-state-neutral-hover) !important;
    color: var(--neutral-10, $neutral-10) !important;
  }
}

.deliveries-status
  .dx-treeview-node
  li.dx-treeview-node
  .dx-item
  > .dx-item-content.dx-treeview-item-content {
  height: auto !important;
}

.permission-table-tree .dx-treeview-node .dx-treeview-item {
  padding-left: 0;
}

.dx-treeview-item.dx-state-hover {
  color: var(--text-gray, $text-gray);
}

.is-off {
  padding: 2px 3px;
  height: 18px;
  min-width: 18px;
  box-sizing: border-box;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  background-color: var(--tagfill-red, $tagfill-red) !important;
  color: var(--text-brand, $text-brand);
  margin: 0 auto;
  margin-right: 6px;
  font-weight: normal;
}

.configuration-grid .dx-datagrid-rowsview tr.dx-group-row {
  height: 30px;
  border-bottom: 1px solid var(--neutral-80, $neutral-80);
  td {
    border: none !important;
    color: var(--text-brand, $text-brand);
    font-weight: 500;
  }
  // &.dx-row-focused td {
  //   color: var(--text-white, $text-white);
  // }
}

.configuration-grid .dx-datagrid-headers {
  .dx-datagrid-scrollable-simulated.dx-datagrid-content.dx-datagrid-scroll-container {
    display: none;
  }
}

.configuration-grid .dx-datagrid-headers {
  border-bottom: 2px solid var(--neutral-80, $neutral-80);
}

.parameters-grid.configuration-grid {
  tr.dx-group-row {
    background-color: transparent !important;
    &.dx-row-focused {
      background-color: transparent;
    }
  }
  .pad-l-15 {
    padding-left: 15px;
  }
}
.dx-treelist-container {
  color: var(--text-gray, $text-gray);
}

.dx-treelist-container > .dx-treelist-headers,
.dx-treelist-container > .dx-treelist-rowsview {
  background-color: var(
    --ds-background-tiles-panels,
    $ds-background-tiles-panels
  );
}

.dx-treelist-borders .dx-treelist-rowsview,
.dx-treelist-headers + .dx-treelist-rowsview,
.dx-treelist-rowsview.dx-treelist-after-headers {
  border-top: 1px solid var(--neutral-80, $neutral-80);
}

.dx-treelist-headers {
  border-bottom: 1px solid var(--neutral-80, $neutral-80);
}

.dx-treelist-headers .dx-treelist-table .dx-row > td {
  border-bottom: 1px solid var(--neutral-80, $neutral-80);
}

.dx-treelist .dx-row-lines > td {
  border-bottom: 1px solid var(--neutral-80, $neutral-80);
}

.dx-treelist-table .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused) > td:not(.dx-focused) {
  color: var(--text-dark-gray, $text-dark-gray);
}