$icomoon-font-family: "Lumen-Icon-Font" !default;
$icomoon-font-path: "fonts" !default;

$absui-icon--document-search: "\e901";
$absui-icon--stats-money-coins: "\e902";
$absui-icon--clipboard-cut: "\e900";
$absui-icon--checkbox-full: "\e9a7";
$absui-icon--checkbox: "\e9a8";
$absui-icon--control-box: "\eadd";
$absui-icon--arrow-back1: "\e910";
$absui-icon--arrow-downward1: "\e91e";
$absui-icon--arrow-forward1: "\e923";
$absui-icon--account-circle: "\ea9e";
$absui-icon--begins-with: "\ea89";
$absui-icon--share: "\ea88";
$absui-icon--bank-export: "\ea85";
$absui-icon--arrow-up: "\ea7b";
$absui-icon--line_document: "\ea7d";
$absui-icon--attachment-horizontal: "\ea75";
$absui-icon--bezplatny: "\ea5b";
$absui-icon--content-books-1-path1: "\ea47";
$absui-icon--content-books-1-path2: "\eace";
$absui-icon--content-books-1-path3: "\eacf";
$absui-icon--content-books-1-path4: "\ead0";
$absui-icon--content-books-1-path5: "\ead1";
$absui-icon--content-books-1-path6: "\ead2";
$absui-icon--content-books-1-path7: "\ead3";
$absui-icon--content-books-1-path8: "\ead4";
$absui-icon--content-books-1-path9: "\ead5";
$absui-icon--content-books-1-path10: "\ead6";
$absui-icon--content-books-1-path11: "\ead7";
$absui-icon--content-books-1-path12: "\ead8";
$absui-icon--content-books-1-path13: "\ead9";
$absui-icon--content-books-1-path14: "\eada";
$absui-icon--content-books-1-path15: "\eadb";
$absui-icon--content-books-1-path16: "\eadc";
$absui-icon--applications: "\ea32";
$absui-icon--add-box: "\e146";
$absui-icon--add-all: "\e993";
$absui-icon--files: "\e9c1";
$absui-icon--document-text: "\e955";
$absui-icon--csv: "\e936";
$absui-icon--calendar-month: "\ea10";
$absui-icon--assignment-turned-in: "\e862";
$absui-icon--assignment-ind: "\e85e";
$absui-icon--application: "\e917";
$absui-icon--application-group: "\ea2e";
$absui-icon--analysis: "\e916";
$absui-icon--album-out: "\e994";
$absui-icon--album-up: "\e995";
$absui-icon--album-visible: "\e996";
$absui-icon--filter: "\f0b0";
$absui-icon--base-check: "\e960";
$absui-icon--base-x: "\e961";
$absui-icon--attach-file: "\e226";
$absui-icon--baseline-laptop: "\ea25";
$absui-icon--baseline-laptop-new: "\ea26";
$absui-icon--folder-expand: "\ea24";
$absui-icon--folder: "\e2c7";
$absui-icon--arrow-down: "\e90f";
$absui-icon--arrow-up1: "\e95d";
$absui-icon--arrow-up-left: "\e95e";
$absui-icon--arrow-stripes-down: "\e95b";
$absui-icon--arrow-stripes-up: "\e95c";
$absui-icon--arrow-drop-left: "\ea1e";
$absui-icon--arrow-drop-right: "\ea1d";
$absui-icon--arrow-drop-up: "\e5c7";
$absui-icon--angle-double-left: "\f100";
$absui-icon--angle-double-right: "\f101";
$absui-icon--approximation: "\e90e";
$absui-icon--add: "\e145";
$absui-icon--arrow-box-up: "\e998";
$absui-icon--arrow-box-down: "\e997";
$absui-icon--arrow-drop-down-circle: "\e5c6";
$absui-icon--add-circle: "\e147";
$absui-icon--control-point: "\e3ba";
$absui-icon--highlight-off: "\e888";
$absui-icon--announcement: "\e85a";
$absui-icon--bell: "\e99a";
$absui-icon--add-alert: "\e003";
$absui-icon--counter: "\e9af";
$absui-icon--archive: "\e149";
$absui-icon--configuration: "\e991";
$absui-icon--bag: "\e999";
$absui-icon--beenhere: "\e52d";
$absui-icon--connect-link: "\e949";
$absui-icon--functions: "\e24a";
$absui-icon--print: "\e8ad";
$absui-icon--windows: "\eabc";
$absui-icon--tool-select-rectangle: "\eabd";
$absui-icon--table-coins: "\eabe";
$absui-icon--sort-az: "\eabf";
$absui-icon--sort-down: "\eac0";
$absui-icon--sort-filter: "\eac1";
$absui-icon--sort-up: "\eac2";
$absui-icon--sort-za: "\eac3";
$absui-icon--sheets: "\eac4";
$absui-icon--settings-user: "\eac5";
$absui-icon--set-settings: "\eac6";
$absui-icon--package-front: "\eac7";
$absui-icon--money-coins: "\eac8";
$absui-icon--home-3: "\eac9";
$absui-icon--graph-sales-bullish: "\eaca";
$absui-icon--credit-card: "\eacb";
$absui-icon--control-date-time: "\eacc";
$absui-icon--cash-flow: "\eacd";
$absui-icon--application-window: "\eabb";
$absui-icon--thermal-printer: "\e932";
$absui-icon--toggle-off: "\e96c";
$absui-icon--toggle-on: "\e97b";
$absui-icon--source-code: "\e988";
$absui-icon--form-items: "\e9cc";
$absui-icon--expand-updown: "\e9e1";
$absui-icon--computer-3: "\e9e6";
$absui-icon--cash-register: "\ea1c";
$absui-icon--scan-barcode: "\ea4b";
$absui-icon--menu: "\ea87";
$absui-icon--partly-check-circle: "\eac2";
$absui-icon--history-clock-changes: "\eaa1";
$absui-icon--files-copy: "\eaa2";
$absui-icon--variables: "\eaa3";
$absui-icon--shopping-bag: "\eaa4";
$absui-icon--select-none: "\eaa5";
$absui-icon--person-out: "\eaa6";
$absui-icon--person-ok: "\eaa7";
$absui-icon--location: "\eaa8";
$absui-icon--location-circle: "\eaa9";
$absui-icon--view-list: "\eaaa";
$absui-icon--error-money-outline: "\eaab";
$absui-icon--certificate: "\eaac";
$absui-icon--calendar-o: "\eaad";
$absui-icon--person-in: "\eaae";
$absui-icon--person-add: "\eaaf";
$absui-icon--person-remove: "\eab0";
$absui-icon--update: "\eab1";
$absui-icon--history-clock: "\eab2";
$absui-icon--refresh: "\e5d5";
$absui-icon--expand-more: "\e313";
$absui-icon--expand-less: "\e316";
$absui-icon--keyboard-arrow-left2: "\e317";
$absui-icon--keyboard-arrow-right2: "\e318";
$absui-icon--close: "\e5cd";
$absui-icon--arrow-upward: "\e5d8";
$absui-icon--arrow-downward: "\e5db";
$absui-icon--arrow-back: "\e5c4";
$absui-icon--arrow-forward: "\e5c8";
$absui-icon--more-horiz: "\e5d3";
$absui-icon--more-vert: "\e5d4";
$absui-icon--menu1: "\e5d2";
$absui-icon--lock-full: "\eab3";
$absui-icon--locked: "\eab4";
$absui-icon--unlocked: "\eab5";
$absui-icon--visibility: "\e8f4";
$absui-icon--visibility-off: "\e8f5";
$absui-icon--check-circle: "\e86c";
$absui-icon--help-outline: "\e8fd";
$absui-icon--error-outline: "\e001";
$absui-icon--plus-circle-outline: "\eab6";
$absui-icon--remove-circle-outline: "\e15d";
$absui-icon--info-outline: "\e88f";
$absui-icon--power-settings-new: "\e8ac";
$absui-icon--material-search: "\e8b6";
$absui-icon--settings: "\e8b8";
$absui-icon--phone: "\e0cd";
$absui-icon--delete: "\e872";
$absui-icon--shopping-cart: "\e8cc";
$absui-icon--key: "\eab7";
$absui-icon--mode-edit: "\eab8";
$absui-icon--add-circle-fill: "\ec27";
$absui-icon--arrow-backward: "\ec37";
$absui-icon--arrow-double-backward: "\ec38";
$absui-icon--arrow-foreward: "\ec3a";
$absui-icon--file-signature: "\ebdd";
$absui-icon--category: "\e92d";