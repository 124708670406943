@use "./colors" as *;
@use "./variables" as *;

@font-face {
  font-family: "Lumen";
  src: url("../fonts/Lumen-Linear-Icon-Font.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.icon {
  display: inline-block;
  font: normal normal normal 22px Lumen;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.absui-icon--select-one-outline::before {
  content: "\ed3e";
  font-size: 20px;
  line-height: 20px;
}

.absui-icon--select-inverse-outline::before {
  content: "\ed3b";
  font-size: 20px;
  line-height: 20px;
}

.absui-icon--magic-wand::before {
  content: "\ecf4";
  font-size: 20px;
  line-height: 20px;
}

.absui-icon--big-data-compute::before {
  content: "\ebe4";
  font-size: 20px;
  line-height: 20px;
}

.absui-icon--filter-user::before {
  content: "\ec67";
  font-size: 20px;
  line-height: 20px;
}

.absui-icon--symbol-plus-minus::before {
  content: "\eb75";
  font-size: 20px;
  line-height: 20px;
}

.absui-icon--security-user::before {
  content: "\ecdd";
  font-size: 20px;
  line-height: 20px;
}

.absui-icon--checkbox-deselect {
  content: "\ebec";
  font-size: 20px;
  line-height: 20px;
}

.absui-icon--settings-panel:before {
  content: "\e900";
  font-size: 20px;
  line-height: 20px;
}

.absui-icon--shop:before {
  content: "\ea27";
  font-size: 20px;
  line-height: 20px;
}

.absui-icon--swap-horiz-document:before {
  content: "\ecaa";
  font-size: 20px;
  line-height: 20px;
}

.absui-icon--swap-horiz-user:before {
  content: "\ecab";
  font-size: 20px;
  line-height: 20px;
}

.absui-icon--symbol-arrow-down-left:before {
  content: "\ecac";
  font-size: 18px !important;
}

.absui-icon--material-search:before {
  margin-right: 8px;
  content: "\e8b6";
  font-size: 18px !important;
}

.absui-icon--fit-to-point:before {
  content: "\ed2d";
  font-size: 18px !important;
}

.absui-icon--files-shield-check:before {
  content: "\ed5f";
  font-size: 18px !important;
}

.absui-icon--file-shield-check:before {
  content: "\eba5";
  font-size: 18px !important;
}

.absui-icon--external-link:before {
  content: "\ea4e";
  font-size: 18px !important;
}

.absui-icon--truck-exchange:before {
  content: "\ecfe";
  font-size: 18px !important;
}

.absui-icon--symbol-arrow-up-right:before {
  content: "\ecad";
  font-size: 18px !important;
}

.absui-icon--directions-car:before {
  content: "\e531";
  font-size: 18px !important;
}

.absui-icon--tag-edit:before {
  content: "\eba6";
  font-size: 18px !important;
}

.absui-icon--location-edit:before {
  content: "\ed1e";
  font-size: 18px !important;
}

.absui-icon--certificate:before {
  content: "\ea4b";
  font-size: 18px !important;
}

.absui-icon--collect-out:before {
  content: "\e9ad";
  font-size: 18px !important;
}

.absui-icon--number-1:before {
  content: "\eb6a";
  line-height: 18px !important;
  font-size: 18px !important;
}

.absui-icon--number-2:before {
  content: "\eb6b";
  line-height: 18px !important;
  font-size: 18px !important;
}

.absui-icon--number-3:before {
  content: "\eb6c";
  line-height: 18px !important;
  font-size: 18px !important;
}

.absui-icon--xlsx:before {
  content: "\e945";
  line-height: 18px !important;
  font-size: 18px !important;
}

.absui-icon--symbol-download:before {
  content: "\eb18";
  font-size: 20px;
  line-height: 20px;
}

.absui-icon--show-list:before {
  content: "\eb09";
  font-size: 20px;
  line-height: 20px;
}

.absui-icon--drag-mini:before {
  content: "\eb54";
  font-size: 20px;
  line-height: 20px;
}

.absui-icon--file-ist:before {
  content: "\ebcb";
  font-size: 21px;
  line-height: 14px;
}

.absui-backe:before {
  content: "\eaab";
  font-weight: normal;
  font-size: 20px;
  line-height: 14px;
  color: var(--neutral-30, $neutral-30);
}

.absui-icon--notifications::before {
  content: "\e7f4";
  font-size: 18px !important;
  cursor: pointer;
}

.absui-icon--operationsBtn:before {
  content: "\e5d3";
  position: relative;
}

.absui-icon--file-edit::before {
  content: "\e9ba";
  font-size: 1rem !important;
  color: var(--neutral-30, $neutral-30);
  cursor: pointer;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.checkAll::before {
  content: "\e9a3";
  font-size: 1rem !important;
  color: var(--neutral-30, $neutral-30);
  cursor: pointer;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--printer-settings::before {
  content: "\ec6c";
  font-size: 1rem !important;
  color: var(--neutral-30, $neutral-30);
  cursor: pointer;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--crm-settings::before {
  content: "\ec7c";
  font-size: 1rem !important;
  color: var(--neutral-30, $neutral-30);
  cursor: pointer;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--question-mark-variant::before {
  content: "\eada";
  font-size: 1rem !important;
  color: var(--neutral-30, $neutral-30);
  cursor: pointer;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--table-cells-column::before {
  content: "\eae0";
  font-size: 18px !important;
}

.absui-icon--shield::before {
  content: "\e9f2";
  font-size: 1rem !important;
  color: var(--neutral-30, $neutral-30);
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.absui-icon--fullscreen2::before {
  content: "\eb28";
  font-size: 18px;
  color: var(--neutral-30, $neutral-30);
  cursor: pointer;
}

.absui-icon--control-tree::before {
  content: "\eae7";
  font-size: 18px;
}

.absui-icon--doc-add::before {
  content: "\e9b2";
  font-size: 18px;
}

.absui-icon--trend-up::before {
  content: "\e90a";
  font-size: 18px;
}

.absui-icon--trend-down::before {
  content: "\e908";
  font-size: 18px;
}

.absui-icon--patch-map::before {
  content: "\eb40";
  font-size: 18px;
}

.absui-icon--docs-many::before {
  content: "\ea33";
  font-size: 18px;
}

.absui-icon--history-clock::before {
  content: "\e910";
  font-size: 18px;
}

.absui-icon--map-pin::before {
  content: "\e986";
  font-size: 18px;
}

.absui-icon--file-upload {
  &::before {
    content: "\e2c6";
    font-size: 18px;
  }
}

.absui-icon--file-download {
  &::before {
    content: "\ec47";
    font-size: 18px;
  }
}

.absui-icon--correction::before {
  content: "\ec9c";
  font-size: 18px !important;
}

.absui-icon--fullscreen-off::before {
  content: "\eb33";
  font-size: 18px;
  color: var(--neutral-30, $neutral-30);
  cursor: pointer;
}

.absui-icon--form-arrange-tabs::before {
  content: "\eb3d";
}

.absui-icon--accordion-arrange-vertical::before {
  content: "\eb35";
}

.icon-pin::before {
  content: "\e9ea";
}

.icon-pin:hover {
  color: var(--icons-brand, $icons-brand);
}

.icon-eye:before {
  content: "\e8f4";
}

.absui-icon--dashboard-widthlimited::before {
  content: "\ea71";
  font-size: 18px;
  color: var(--neutral-30, $neutral-30);
  cursor: pointer;
}

.icon-absui-icon--location:before {
  content: "\eaa8";
  font-size: 24px;
  margin-right: 18px;
}

.icon-absui-icon--attach-file {
  &.grid-attachment:before {
    color: var(--theme-positive-30, $theme-positive-30);
  }

  &:before {
    content: "\e226";
    font-size: 18px !important;
    margin-right: 18px;
  }
}

.absui-icon--shopping-bag:before {
  content: "\eaca";
  color: var(--text-light-gray, $text-light-gray);
  font-size: 24px;
  margin-right: 18px;
}

.absui-icon--location-circle:before {
  content: "\eabe";
  font-size: 16px;
}

.aabsui-icon--application:before {
  content: "\e917";
  font-size: 21px;
}

.dx-icon-absui-icon--come-out:before {
  content: "\e953";
  font-family: Lumen;
  font-size: 21px;
  font-style: normal;
  top: -1px;
  position: relative;
}

.absui-icon--help-outline:before {
  content: "\e8fd";
  color: var(--text-light-gray, $text-light-gray);
  font-size: 24px;
  margin-right: 18px;
}

.absui-icon--help-outline-up:before {
  content: "\e8fd";
  color: var(--text-light-gray, $text-light-gray);
  font-size: 24px;
}

.icon-update:before {
  content: "\e923";
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 14px;
  color: var(--neutral-30, $neutral-30);
}

.absui-icon--variables:before {
  content: "\eaa3";
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 14px;
  color: var(--neutral-30, $neutral-30);
}

.absui-icon--double-left:before {
  content: "\e9b5";
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 14px;
  color: var(--neutral-30, $neutral-30);
}

.absui-icon--double-right:before {
  content: "\e9b6";
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 14px;
  color: var(--neutral-30, $neutral-30);
}

.icon-certyfikate:before {
  content: "\ea4b";
  font-weight: normal;
  font-size: 21px;
  line-height: 14px;
}

.icon-pencile:before {
  content: "\e932";
  font-weight: normal;
  font-size: 21px;
  line-height: 14px;
  color: var(--neutral-30, $neutral-30);
}

.icon-refresh:before {
  content: "\e5d5";
  font-weight: normal;
  font-size: 21px;
  line-height: 14px;
}

.icon-row:before {
  color: var(--neutral-70, $neutral-70);
}

.icon-border-row {
  width: 40px;
  height: 40px;
  background: var(--neutral-100, $neutral-100);
  border: 1px solid rgba(218, 222, 225, 0.5);
  box-sizing: border-box;
  border-radius: 35px;
  text-align: center;
  box-sizing: border-box;
  padding-top: 4px;
  cursor: pointer;
  display: inline-block;
  margin-right: 12px;
}

.icon-accordion {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 2px solid var(--neutral-30, $neutral-30);
  // border-color: var(--neutral-30, $neutral-30);
  cursor: pointer;
  background-color: transparent;

  &.selected {
    border-color: var(--icons-brand, $icons-brand);
    background-color: var(--icons-brand, $icons-brand);
  }
}

.icon-border-order {
  width: 30px;
  height: 30px;
  background: var(--neutral-100, $neutral-100);
  border: 1px solid rgba(218, 222, 225, 0.5);
  box-sizing: border-box;
  border-radius: 35px;
  text-align: center;
  box-sizing: border-box;
  padding-top: 0px;
  cursor: pointer;
  display: inline-block;
  margin-left: 12px;
}

.absui-icon--files::before {
  content: $absui-icon--files;
  font-weight: normal;
  font-size: 21px;
  line-height: 14px;
}

.absui-icon--arrow-downward:before {
  content: "\e5db";
  font-weight: normal;
  font-size: 21px;
  line-height: 14px;
  color: var(--icons-brand, $icons-brand);
  margin-left: -3px;
}

.absui-icon--arrow-upward:before {
  content: "\e5d8";
  font-weight: normal;
  font-size: 21px;
  line-height: 14px;
  color: var(--icons-brand, $icons-brand);
  margin-left: -3px;
}

.icon-border-row:hover {
  background: var(--icons-brand, $icons-brand);

  .icon:before {
    color: var(--neutral-100, $neutral-100) !important;
  }
}

.box-shadow {
  box-shadow: 0px 8px 25px rgba(67, 63, 75, 0.08);
}

.icon-calendar:before,
.dx-icon-calendar {
  content: "\e91e";
  font-weight: normal;
  font-size: 21px;
  line-height: 14px;
  color: var(--neutral-30, $neutral-30);
}

a.active .absui-icon--expand-less:before {
  color: var(--brand-30, $brand-30);
}

.icon--keyboard-arrow-left:before {
  content: "\e317";
  font-weight: normal;
  font-size: 21px;
  line-height: 7px;
}

.icon--remove-selected::before {
  content: "\eaa3";
  font-size: 21px;
  line-height: 14px;
}

.icon--keyboard-arrow-right:before {
  content: "\e318";
  font-weight: normal;
  font-size: 21px;
  line-height: 14px;
  color: var(--icons-brand, $icons-brand);
}

.absui-icon--news-small {
  &::before {
    content: "\eaf7";
    font-style: normal;
    font-size: 21px;
  }
}

.icon-search:hover::before {
  background-color: var(--neutral-80, $neutral-80);
}

.icon-search:before {
  content: "\e8b6";
  font-style: normal;
  font-size: 18px;
}

.absui-icon--angle-double-left::before {
  content: "\f100";
  font-style: normal;
  font-size: 18px;
}

.absui-icon--document-search::before {
  content: "\eab5";
  font-style: normal;
  font-size: 18px;
}

.absui-icon--angle-double-right::before {
  content: "\f101";
  font-style: normal;
  font-size: 18px;
}

.circle-add-correct::before {
  content: "\e147";
  font-style: normal;
  font-size: 1.125rem !important;
  -webkit-text-stroke-width: 0.012em !important;
  -webkit-font-smoothing: antialiased !important;
}

.absui-icon--expand-less:before {
  content: "\e316";
  font-size: 20px;
  line-height: 7px;
  color: var(--neutral-30, $neutral-30);
}

.absui-icon--status-cancel2 {
  &:before {
    content: "\eafa";
    font-size: 18px !important;
    color: var(--icons-brand, $icons-brand);
  }
}

.dx-row-focused i.absui-icon--document-text::before {
  color: var(--avatartext-light-blue, $avatartext-light-blue) !important;
}

.absui-icon--document-text {
  &:before {
    content: "\e955";
    font-size: 18px !important;
    color: var(--avatartext-light-blue, $avatartext-light-blue);
  }
}

.absui-icon--home-user {
  &:before {
    content: "\ebcb";
    font-size: 18px !important;
  }
}

.absui-icon--comment {
  &:before {
    content: "\e0b9";
    font-size: 18px !important;
  }
}

.dx-row-focused i.absui-icon--bill-credit-card::before {
  color: var(--theme-positive-30, $theme-positive-30) !important;
}

.absui-icon--bill-credit-card {
  &:before {
    content: "\eb62";
    font-size: 18px !important;
    color: var(--theme-positive-30, $theme-positive-30);
  }
}

.absui-icon--status-cancel {
  top: 4px;
  position: relative;

  &:before {
    content: "\ebb6";
    // font: Lumen;
    font-size: 21px;
    line-height: 7px;
    color: var(--icons-brand, $icons-brand);
  }
}

.btn-dx-top-menu .dx-icon.btn-icon {
  margin-top: -3px !important;
  margin-left: -1px;
}

.absui-icon--arrow-forward:before {
  content: "\e5c8";
  font-size: 21px;
  line-height: 14px;
}

.icon-border {
  width: 40px;
  height: 40px;
  background: var(--neutral-100, $neutral-100);
  border: 1px solid rgba(218, 222, 225, 0.5);
  box-sizing: border-box;
  border-radius: 35px;
  text-align: center;
  box-sizing: border-box;
  padding-top: 4px;
  cursor: pointer;
  display: inline-block;
  margin-right: 12px;
}

.icon-header {
  .fa:before {
    color: var(--icons-brand, $icons-brand);
  }

  .icon:before {
    color: var(--icons-brand, $icons-brand);
  }
}

.icon-header.dx-state-disabled {
  background-color: var(--neutral-70, $neutral-70) !important;
  opacity: 1 !important;
  color: var(--neutral-100, $neutral-100) !important;

  .icon:before {
    color: var(--neutral-100, $neutral-100);
  }

  .fa:before {
    color: var(--neutral-100, $neutral-100);
  }
}

.dx-menu-base .dx-menu-item.dx-state-disabled {
  opacity: 1;
  color: var(--neutral-50, $neutral-50) !important;

  i {
    color: var(--neutral-50, $neutral-50) !important;
  }
}

.dx-state-disabled {
  cursor: default;
  box-sizing: border-box;
}

.icon-plus:before {
  content: "\eab6";
  font-weight: normal;
  font-size: 21px;
  line-height: 14px;
  color: var(--neutral-30, $neutral-30);
}

.icon-eye-slash:before {
  content: "\e8f5";
}

.keyboard-arrow-left:before {
  content: "\e317";
  font-size: 21px;
  line-height: 14px;
  text-align: center;
  font-weight: 400;
  list-style: normal;
  color: var(--neutral-10, $neutral-10);
  width: 30px;
  height: 30px;
  background: var(--neutral-100, $neutral-100);
  border: 1px solid rgba(218, 222, 225, 0.5);
  box-sizing: border-box;
  border-radius: 35px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.absui-icon--delete:before {
  content: "\e872";
  font-size: 21px;
  line-height: 14px;
}

.shopping-cart:before {
  content: "\e8cc";
  color: var(--neutral-10, $neutral-10) !important;
  font-weight: 400;
  font-style: normal;
  position: relative;
  font-size: 21px;
  line-height: 17px;

  position: relative;
  color: var(--neutral-100, $neutral-100);
}

.absui-icon--globe-key:before {
  content: "\ebfa";
}

.absui-icon--is-greate:before {
  content: "\e318";
  font-size: 21px;
  line-height: 14px;
  text-align: center;
  font-weight: 400;
  list-style: normal;
  color: var(--neutral-10, $neutral-10);
  width: 30px;
  height: 30px;
  background: var(--neutral-100, $neutral-100);
  border: 1px solid rgba(218, 222, 225, 0.5);
  box-sizing: border-box;
  border-radius: 35px;
  transform: matrix(-1, 0, 0, 1, 0, 0);

  &:hover {
    color: var(--icons-brand, $icons-brand) !important;
  }
}

.absui-icon--list-view:before {
  content: "\eaaa";
  font-size: 21px;
}

.absui-icon--select-one:before {
  content: "\ecd9";
  font-size: 18px;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--select-inverse:before {
  content: "\ebd0";
  font-size: 18px;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--select-triple-left:before {
  content: "\ecda";
  font-size: 18px;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--select-all:before {
  content: "\ebcf";
  font-size: 18px;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--file-money:before {
  content: "\ea9f";
  font-size: 21px;
}

.absui-icon--star-o:before {
  content: "\e83a";
  font-size: 21px;
}

.absui-icon--money-coins::before {
  content: "\eac0";
  font-size: 21px;
}

.absui-icon--playlist-forbidden::before {
  content: "\ed37";
  font-size: 21px;
}

.absui-icon--report-user::before {
  content: "\ebd6";
  font-size: 21px;
}

.absui-icon--mapping-truck-load::before {
  content: "\ecdc";
  font-size: 21px;
}

.absui-icon--mapping-money::before {
  content: "\ecdb";
  font-size: 21px;
}

.absui-icon--packages-check::before {
  content: "\e9de";
  font-size: 21px;
}

.absui-icon--select-none:before {
  content: "\eaa5";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--status-height-circle-fill::before {
  content: "\ed11";
  font-size: 18px;
  color: var(--text-positive, $text-positive);
}

.absui-icon--status-length-circle-fill::before {
  color: var(--text-positive, $text-positive);
  content: "\ed12";
  font-size: 18px;
}

.absui-icon--status-width-circle-fill::before {
  content: "\ebd1";
  font-size: 18px;
  color: var(--text-positive, $text-positive);
}

.absui-icon--package-data:before {
  content: "\e958";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--expand-more:before {
  content: "\e313";
  color: var(--neutral-30, $neutral-30);
  cursor: pointer;
  font-size: 20px;
  line-height: 14px;
}

.icon-history:before {
  content: "\eaa1";
  font-size: 21px;
  line-height: 14px;
  cursor: pointer;
}

.icon-filter-config:before {
  content: "\e990";
  font-size: 21px;
  line-height: 14px;
  cursor: pointer;
  font-weight: normal;
}

.icon-sign-out:before {
  content: "\e8ac";
  font-size: 18px;
  line-height: 14px;
}

.absui-icon--box-edit:before {
  content: "\e98a";
  font-size: 18px;
}

.absui-icon--status-info:before {
  content: "\eb00";
  font-size: 18px;

  &_gray {
    color: var(--neutral-30, $neutral-30);
  }

  &_blue {
    color: var(--theme-info-30, $theme-info-30);
  }
}

.absui-icon--status-info_blue:before {
  content: "\eb00";
  font-size: 18px;
  color: var(--theme-info-30, $theme-info-30);
}

.absui-icon--phone:before {
  content: $absui-icon--phone;
  font-size: 16px;
}

.icon-download:before {
  content: "\e884";
  margin-right: 15px;
  cursor: pointer;
  font-size: 20px;
}

.icon-upload:before {
  content: "\e2c6";
}

.icon-info:before {
  content: "\e88f";
}

.icon-lock:before {
  content: "\e96c";
  cursor: pointer;
  position: relative;
  top: -4px;
  font-size: 21px;
}

.icon-lock-per:before {
  content: "\e96c";
  cursor: pointer;
  position: relative;
  font-size: 21px;
}


.absui-icon--arrow-foreward-padlock:before {
  content: "\ed05";
  cursor: pointer;
  position: relative;
  font-size: 21px;
}

.icon-unlock-per:before {
  content: "\e97b";
  position: relative;
  cursor: pointer;
  font-size: 21px;
}

.icon-unlock:before {
  content: "\e97b";
  position: relative;
  top: -4px;
  cursor: pointer;
  font-size: 21px;
}

.icon-plus-square-o:before {
  content: "\e145";
  border: solid 1px;
  font-size: 21px;
  line-height: 14px;
}

.icon-user-add:before {
  content: "\e9e1";
  font-size: 21px;
  line-height: 14px;
}

.absui-icon--doc-out:before {
  content: "\e9b3";
  font-size: 21px;
  line-height: 14px;
}

.absui-icon--playlist-check:before {
  content: "\e980";
  font-size: 21px;
  line-height: 14px;
}

.absui-icon--playlist-remove::before {
  content: "\e982";
  font-size: 21px;
  line-height: 14px;
}

.icon-user-remove:before {
  content: "\e9e6";
  font-size: 21px;
  line-height: 14px;
}

.icon--settings:before {
  content: "\e8b8";
  font-size: 21px;
  line-height: 14px;
}

.fa-clone {
  font-size: 21px;
  line-height: 14px;
}

.icon-line-out-mail:before {
  content: "\ea80";
  font-size: 21px;
  line-height: 14px;
}

.icon-details:before {
  content: "\e98e";
  font-size: 21px;
  line-height: 14px;
}

.icon-save:before {
  content: "\e161";
  font-size: 21px;
  line-height: 14px;
}

/* zamowienia */
.absui-icon--person-out:before {
  content: $absui-icon--person-out;
  font-weight: normal;
  font-size: 21px;
  line-height: 14px;
  color: var(--text-light-gray, $text-light-gray);
}

.absui-icon--partly-check-circle:before {
  content: "\eac2";
  font-weight: normal;
  font-size: 1rem !important;
  line-height: 14px;
  color: var(--text-light-gray, $text-light-gray);
}

.absui-icon--error-money-outline:before {
  content: "\eaab";
  font-weight: normal;
  font-size: 21px;
  line-height: 14px;
  color: var(--icons-brand, $icons-brand);
}

.absui-icon--more-horiz {
  &:before {
    content: "\e5d3";
    font-weight: normal;
    font-size: 21px;
    line-height: 14px;
    color: var(--icons-brand, $icons-brand);
  }

  &.default {
    &:before {
      color: var(--neutral-30, $neutral-30);
      font-size: 1.12rem !important;
    }
  }
}

.icon--keyboard-arrow-right-mobile:before {
  content: "\e318";
  font-weight: normal;
  font-size: 21px;
  line-height: 14px;
  color: var(--neutral-10, $neutral-10);
}

.absui-icon--expand-more-mobile:before {
  content: "\e313";
  color: var(--icons-brand, $icons-brand);
  cursor: pointer;
  font-size: 21px;
  line-height: 14px;
}

.absui-icon--menu:before {
  content: "\ea87";
  color: var(--neutral-30, $neutral-30);
  font-size: 21px;
}

.absui-icon--home-3:before {
  content: "\eabc";
  font-size: 21px;
}

.absui-icon--content-books-1:before {
  content: "\ea47";
  font-size: 21px;
}

.absui-icon--docs-out:before {
  content: "\e9b4";
  font-size: 21px;
}

.absui-icon--review-track-changes:before {
  content: "\ed24";
  font-size: 21px;
}

.absui-icon--script-user:before {
  content: "\ed25";
  font-size: 21px;
}

.absui-icon--lock-rodo:before {
  content: "\e96c";
  font-size: 21px;
}

.absui-icon--list-money:before {
  content: "\ebda";
  font-size: 21px;
}

.absui-icon--system:before {
  content: "\e931";
  font-size: 21px;
}

.absui-icon--token-add:before {
  content: "\ecb8";
  font-size: 18px;
}

.absui-icon--box-information:before {
  content: "\e98b";
  font-size: 18px;
}

.absui-icon--status-cancel-red:before {
  content: "\eafa";
  color: var(--icons-brand, $icons-brand);
  font-size: 18px !important;
}

.absui-icon--question-answer:before {
  content: "\e8af";
  font-size: 18px;
}

.absui-icon--settings::before {
  content: "\ea38";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--settings-user:before {
  content: "\eac8";
  font-size: 21px;
}

.absui-icon--application-group:before {
  content: "\ea2e";
  font-size: 21px;
}

.absui-icon--business:before {
  content: "\e7ee";
  font-size: 21px;
}

.absui-icon--account-circle::before {
  content: "\ea9e";
  font-size: 18px;
  line-height: 18px;
}

.absui-icon--history-clock-change::before {
  content: "\eabb";
  font-size: 18px;
  line-height: 18px;
}

.absui-icon--arrow-drop-right::before {
  content: "\ea1d";
  font-size: 20px;
  line-height: 20px;

  color: var(--text-light-gray, $text-light-gray);
}

.absui-icon--arrow-drop-down::before {
  content: "\e5c5";
  font-size: 20px;
  line-height: 20px;
  color: var(--neutral-10, $neutral-10);
  position: relative;
  top: -4px;
  left: -13px;
}

.absui-icon--arrow-drop-down::before {
  content: "\e5c5";
  font-size: 20px;
  line-height: 20px;
  color: var(--neutral-10, $neutral-10);
  position: relative;
  top: -4px;
  left: -13px;
}

.absui-icon--arrow-drop-up::before {
  content: "\e5c7";
  font-size: 20px;
  line-height: 20px;
  color: var(--neutral-10, $neutral-10);
  position: relative;
  top: -4px;
  left: -13px;
}

.absui-icon--arrow-drop-left::before {
  content: "\ea1e";
  font-size: 20px;
  line-height: 20px;
  color: var(--neutral-10, $neutral-10);
}

.absui-icon--arrow-drop-right-user-panel::before {
  content: "\ea1d";
  font-size: 18px;
  line-height: 18px;
  position: relative;
  top: 3.5px;
  left: 15px;
}

.absui-icon--public::before {
  content: "\e80b";
  font-size: 18px;
  line-height: 18px;
}

.absui-icon--lock-full::before {
  content: "\e96c";
  font-size: 18px;
  line-height: 18px;
}

.absui-icon--lock-full_mobile::before {
  font-size: 16px !important;
  content: "\e96c";
  line-height: 16px;
}

.absui-icon--unlocked::before {
  content: "\e97b";
  font-size: 18px;
  line-height: 18px;
}

.absui-icon--find-start::before {
  content: "\eadd";
  font-size: 18px;
  line-height: 18px;
}

.absui-icon--package-front::before {
  content: "\eac1";
  font-size: 18px;
  line-height: 18px;
}

.absui-icon--arrow-back::before {
  content: "\e5c4";
  font-size: 18px;
  line-height: 18px;
}

.absui-icon--arrow-forward::before {
  content: "\e5c8";
  font-size: 18px;
  line-height: 18px;
}

.absui-icon--drag-vertical::before {
  content: "\eaa4";
  font-size: 18px;
  line-height: 18px;
}

.absui-icon--info::before {
  content: "\e88e";
  font-size: 18px;
  line-height: 18px;
}

.dx-icon.absui-icon--help::before {
  font-size: 21px;
  line-height: 21px;
}

.absui-icon--help::before {
  content: "\e887";
  font-size: 18px;
  line-height: 18px;
}

.absui-icon--book-open::before {
  content: "\ebf1";
  font-size: 21px;
  line-height: 21px;
}

.absui-icon--book-open-questionmark::before {
  content: "\ebf1";
  font-size: 21px;
  line-height: 21px;
  color: var(--text-gray, $text-gray);
}

.user-info-panel .absui-icon--book-open::before {
  margin-left: -0.75px;
}

.absui-icon--monitor-dashboard::before {
  content: "\ea54";
  font-size: 18px;
  line-height: 18px;
}

.absui-icon--calendar-text-add:before {
  content: "\e9a1";
  font-size: 16px;
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--link:before {
  content: "\e9d7";
  font-size: 16px;
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--messge-ok:before {
  content: "\eae9";
  font-size: 16px;
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--wallet-giftcard::before {
  content: "\ea3a";
  font-size: 16px;
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--payment:before {
  content: "\e924";
  font-size: 16px;
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--add-circle:before,
.absui-icon--add-circle-visible::before {
  content: "\e147";
  font-size: 16px;
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--truck:before {
  content: "\e97a";
  font-size: 16px;
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--table-cells-row:before {
  content: "\eade";
  font-size: 16px;
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--sum-in:before {
  content: "\ebaa";
  font-size: 16px;
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--calculator-loan:before {
  content: "\eb9c";
  font-size: 16px;
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--calc-set-settings:before {
  content: "\eac7";
  font-size: 16px;
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--genius:before {
  content: "\eb58";
  font-size: 18px;
  color: var(--text-brand, $text-brand);
  position: absolute;
  top: -2px;
  margin-left: 3px;
}

.absui-icon--geniuss:hover {
  cursor: pointer;
}

.absui-icon--geniuss:before {
  content: "\eb58";
  font-size: 18px;
  position: relative;
  color: var(--text-brand, $text-brand);
}

.absui-icon--currency-exchange:before {
  content: "\eb9d";
  font-size: 16px;
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--money-banknote:before {
  content: "\eb9f";
  font-size: 16px;
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--calc:before {
  content: "\e99f";
  font-size: 16px;
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--link-money:before {
  content: "\e9d3";
  font-size: 16px;
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--file-edit-1:before {
  content: "\e9b9";
  font-size: 16px;
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--file-add:before {
  content: "\e9b7";
  font-size: 16px;
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--cancel:before {
  content: "\e5c9";
  font-size: 16px;
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.dx-icon.dx-icon-barcode::before {
  content: "\eac5";
  font-family: Lumen;
  font-style: normal;
  color: var(--neutral-10, $neutral-10);
  font-size: 1rem !important;
}

.dx-icon.dx-icon-scaner::before {
  content: "\eb88";
  font-family: Lumen;
  font-style: normal;
  color: var(--text-gray, $text-gray);
  position: relative;
  font-size: 18px !important;
}

.absui-icon--send::before {
  content: "\e163";
  font-size: 16px;
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--add-no-circle:before {
  content: "\e145";
  font-size: 16px;
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--mode-edit:before {
  content: "\e932";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.numberbox-spin-down-icon::before {
  content: "\e15b";
  font-size: 16px;
  top: 12px;
}

.absui-icon--keyboard:before {
  content: "\eb77";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--orders:before {
  content: "\e922";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--file-sort-down:before {
  content: "\eb8c";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--file-box-down:before {
  content: "\e99c";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--checkbox-all:before {
  content: "\e9a5";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--deselct-all:before {
  content: "\ec10";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--checkbox-arrowback:before {
  content: "\ea76";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--export-spreadsheet:before {
  content: "\eb12";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--sale-fill:before {
  content: "\eb15";
  font-size: 1.12rem !important;
  color: var(--icons-green, $icons-green);
  -webkit-font-smoothing: antialiased;
}

.absui-icon--symbol-percent:before {
  content: "\eb65";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--colors:before {
  content: "\eb56";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--show-check:before {
  content: "\ec6b";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--tag:before {
  content: "\e9fe";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--file-lock:before {
  content: "\ea86";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--replay:before {
  content: "\e042";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--highlight-off:before,
.absui-icon--highlight-off-visible:before {
  content: "\e872";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--delete-sweep:before {
  content: "\e16c";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--highlight-offf:before {
  content: "\e888";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--check-circle:before {
  content: "\e86c";
  font-size: 16px;
}

.absui-icon--packages-add:before {
  content: "\ecc3";
  font-size: 16px;
}

.absui-icon--package-delete:before {
  content: "\e9dd";
  font-size: 16px;
}

.absui-icon--status-question:before {
  content: "\eb04";
  font-size: 16px;
}

.absui-icon--partly-circle:before {
  content: "\ec34";
  font-size: 16px;
}

.absui-icon--status-partly-circle:before {
  content: "\ec35";
  font-size: 16px;
}

.absui-icon--error-outline:before {
  font-size: 16px;
  content: "\e001";
}

.absui-icon--check:before {
  font-size: 16px;
  content: "\e5ca";
}

.absui-icon--remove-circle-outline:before {
  content: "\e15d";
  font-size: 16px;
}

.absui-icon--sort-down::before {
  content: "\eacc";
}

.absui-icon--sort-up::before {
  content: "\eace";
}

.absui-icon--set-settings:before {
  content: "\eac6";
}

.absui-icon--windows::before {
  content: "\ead9";
}

.absui-icon--key:before {
  content: "\e9cc";
  font-size: 16px;
}

.absui-icon--print::before {
  content: "\e8ad";
  font-size: 21px;
  color: var(--text-gray, $text-gray);
}

.absui-icon--xml::before {
  content: "\e946";
  font-size: 21px;
  line-height: 14px;
}

.absui-icon--file-ist::before {
  content: "\ebcb";
  font-size: 21px;
  line-height: 14px;
}

.absui-icon--table-coins::before {
  content: "\ead2";
  font-size: 21px;
}

.absui-icon--mdi-book::before {
  content: "\ea79";
  font-size: 21px;
}

.absui-icon--mail-back-menu::before {
  content: "\ec1a";
  font-size: 21px;
}

.absui-icon--file-coins::before {
  content: "\ec71";
  font-size: 21px;
}

.absui-icon--sort-ascending::before {
  content: "\eacb";
  font-size: 21px;
  color: var(--text-gray, $text-gray);
}

.absui-icon--playlist-minus::before {
  content: "\e981";
  font-size: 21px;
  color: var(--text-gray, $text-gray);
}

.dx-icon.dx-icon-playlist-plus::before {
  content: "\e03b";
  font-family: Lumen;
  font-style: normal;
  font-size: 21px;
  color: var(--text-gray, $text-gray);
}

.absui-icon--toggle-on-full::before {
  content: "\eb9e";
  font-size: 21px !important;
  color: var(--text-gray, $text-gray);
}

.absui-icon--toggle-on::before {
  content: "\ead5";
  font-size: 21px !important;
  color: var(--text-gray, $text-gray);
}

.absui-icon--toggle-off::before {
  content: "\ead4";
  font-size: 21px !important;
  color: var(--text-gray, $text-gray);
}

.absui-icon--button-arrow-up::before {
  content: "\ecdf";
  font-size: 18px !important;
  color: var(--text-gray, $text-gray);
}

.absui-icon--button-arrow-down::before {
  content: "\eb31";
  font-size: 18px !important;
  color: var(--text-gray, $text-gray);
}

.absui-icon--status-off-circle::before {
  content: "\eb01";
  font-size: 21px;
  color: var(--text-gray, $text-gray);
}

.absui-icon--status-ok-green::before {
  content: "\eb02";
  font-size: 18px;
  color: var(--text-positive, $text-positive);

  &_warning:before {
    color: rgba(168, 104, 0, 1);
  }
}

.absui-icon--status-ok-warning::before {
  content: "\eb02";
  font-size: 18px;
  color: rgba(168, 104, 0, 1);
}

.absui-icon--link-ok-full::before {
  content: "\ecd7";
  font-size: 18px;
  color: var(--text-positive, $text-positive);
}

.absui-icon--link-refresh::before {
  content: "\ecd8";
  font-size: 18px;
  color: var(--text-gray, $text-gray);
}

.absui-icon--hide-left-panel::before {
  content: "\eaec";
  font-size: 21px;
  color: var(--text-gray, $text-gray);
}

.absui-icon--link-off::before {
  content: "\e9d5";
  font-size: 21px;
  color: var(--neutral-50, $neutral-50);
}

.absui-icon--hide-right-panel::before {
  content: "\eaed";
  font-size: 21px;
  color: var(--text-gray, $text-gray);
}

.absui-icon--renumber::before {
  content: "\e980";
  font-size: 21px;
  color: var(--text-gray, $text-gray);
}

.absui-icon--docs-many::before {
  content: "\ea33";
  font-size: 21px;
  color: var(--text-gray, $text-gray);
}

.absui-icon--wrench::before {
  content: "\e992";
}

.dx-icon-spindown::before {
  font-family: Lumen;
  content: "\e5c5";
  cursor: pointer;
  font-size: 18px;
  line-height: 14px;
}

.drop-down-box::before {
  font-family: Lumen;
  content: "\e5c5";
  cursor: pointer;
  font-size: 18px;
  line-height: 14px;
  position: relative;
  top: -3px;
}

.absui-icon--arrow-drop-down-box::before {
  font-family: Lumen;
  content: "\e5c5";
  cursor: pointer;
  font-size: 18px;
  line-height: 14px;
}

.dx-icon-spinup::before {
  font-family: Lumen;
  content: "\e5c7";
  cursor: pointer;
  font-size: 18px;
  line-height: 14px;
}

.dx-icon-absui-icon--scissors {
  font-style: normal;

  &::before {
    content: "\eab0";
    font-family: Lumen;
    cursor: pointer;
    font-size: 21px;
    line-height: 14px;
  }
}

.dx-icon-absui-icon--barcode {
  font-style: normal;

  &::before {
    content: "\e95f";
    font-family: Lumen;
    cursor: pointer;
    font-size: 21px;
    line-height: 14px;
  }
}

.absui-icon--sort-descending::before {
  content: "\eacf";
  font-size: 21px;
  color: var(--text-gray, $text-gray);
}

.absui-icon--credit-card::before {
  content: "\eacb";
  font-size: 21px;
  color: var(--text-gray, $text-gray);
}

.absui-icon--person-ok:before {
  content: "\eac3";
  font-size: 21px;
}

.absui-icon--folder-expanddd:before {
  content: "\ea24";
  font-size: 18px;
  position: relative;
  top: 0px;
}

.absui-icon--folder-expandd:before {
  content: "\ea24";
  font-size: 18px;
  position: relative;
  top: -3px;
}

.absui-icon--folder-expand:before {
  content: "\ea24";
  font-size: 21px;
}

.absui-icon--dropdown--folder-expand {
  margin-top: -4px !important;

  &:before {
    content: "\ea24";
    font-size: 16px;
  }
}

.absui-icon--dropdown--folder-open-outline {
  margin-top: -4px !important;

  &:before {
    content: "\eaf6";
    font-size: 16px;
  }
}

.absui-icon--next {
  &:before {
    content: "\e318";
    font-size: 16px;
  }
}

.absui-icon--prev {
  &:before {
    content: "\e317";
    font-size: 16px;
  }
}

.absui-icon--photo-add {
  &:before {
    content: "\e9e7";
    font-size: 16px;
  }
}

.absui-icon--files:before {
  content: "\e9c1";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--calculator-business-money:before {
  content: "\eb49";
  font-size: 1.187rem;
  font-size: 21px;
}

.absui-icon--attach-files:before {
  content: "\eaa9";
  font-size: 1.187rem;
  font-size: 21px;
}

.absui-icon--invoice-sequence:before {
  content: "\e957";
  font-size: 1.187rem;
  font-size: 21px;
}

.absui-icon--bill-credit-card:before {
  content: "\eb62";
  font-size: 1.187rem;
  font-size: 21px;
}

.absui-icon--package-front:before {
  content: "\eac1";
  font-size: 1.187rem;
  font-size: 21px;
}

.absui-icon--status-stop-circle-fill:before {
  content: "\ebb3";
  font-size: 18px !important;
  color: var(--neutral-50, $neutral-50);
}

.absui-icon--shopping-cartarrow-in:before {
  content: "\ec45";
  font-size: 1.187rem;
  font-size: 21px;
}

.absui-icon--shopping-cartarrow-out:before {
  content: "\ec46";
  font-size: 1.187rem;
  font-size: 21px;
}

.absui-icon--add-cart:before {
  content: "\ec82";
  font-size: 1.187rem;
  font-size: 21px;
}

.absui-icon--files-copy:before {
  content: "\eab8";
  font-size: 21px;
}

.absui-icon--sheets {
  &:before {
    content: "\eac9";
  }
}

.absui-icon--control-date-time::before {
  content: "\eab3";
}

.absui-icon--stats::before {
  content: "\e952";
}

.absui-icon--applications {
  &:before {
    content: $absui-icon--applications;
  }
}

.absui-icon--document-text {
  &:before {
    content: $absui-icon--document-text;
  }
}

.absui-icon--files-copy {
  &:before {
    content: "\eab8";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--dollar {
  &:before {
    content: "\e227";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--fiscalized {
  &:before {
    content: "\e227";
    font-size: 14px !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--gear-numbers {
  &:before {
    content: "\eb21";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--gear-new {
  &:before {
    content: "\eb20";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.tool-select-rectangle {
  &:before {
    content: "\ead6";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--form-informnation {
  &:before {
    content: "\eb27";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--gear-remove {
  &:before {
    content: "\eb20";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--enterprise {
  &:before {
    content: "\e91f";
    font-size: 21px;
  }
}

.absui-icon--point-list::before {
  content: "\e9ec";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--wallet::before {
  content: "\ea3a";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--clear-filter {
  &:before {
    content: "\e90b";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--import-contacts {
  &:before {
    content: "\e0e0";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--folder-open-outline {
  &:before {
    content: "\eaf6";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--gear-remove2 {
  &:before {
    content: "\eb22";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--tool-select-rectangle {
  &:before {
    content: "\e86c";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--lock {
  &:before {
    //content: "\e988";
    content: "\eb32";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--unlock {
  &:before {
    //content: "\e989";
    content: "\eb32";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--trash {
  &:before {
    content: "\e98f";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--form-items {
  &:before {
    content: "\e98b";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--print {
  &:before {
    content: "\e8ad";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--select {
  &:before {
    content: "\e86c";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--deliveries {
  &:before {
    content: "\e964";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--trolley {
  &:before {
    content: "\ea03";
    font-size: 1.12rem !important;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--user-group {
  &:before {
    content: "\ea48";
    font-size: 1.12rem !important;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--list-user {
  &:before {
    content: "\eb3f";
    font-size: 1.12rem !important;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--podukty {
  &:before {
    content: "\e94f";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--edocuments {
  &:before {
    content: "\eb2c";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--base-currencies {
  &:before {
    content: "\eb1b";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--set-lock {
  &:before {
    content: "\eb1e";
    font-size: 1rem !important;
  }
}

.absui-icon--insert-drive-file {
  &:before {
    content: "\e24d";
    font-size: 1rem !important;
  }
}

.absui-icon--script {
  &:before {
    content: "\ec4f";
    font-size: 1rem !important;
  }
}

.absui-icon--money-dollar-fill {
  &:before {
    content: "\eb34";
    position: relative;
    top: 2px;
    font-size: 1.125rem !important;
    color: var(--icons-cyan, $icons-cyan);
  }
}

.absui-icon--warning2 {
  &:before {
    content: "\e002";
    font-size: 1rem;
  }
}

.absui-icon--add-box {
  &:before {
    content: "\e146";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--indeterminate-check-box {
  &:before {
    content: "\e909";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--add-in {
  &:before {
    content: "\e145";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--remove-in {
  &:before {
    content: "\e15b";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--add-all {
  &:before {
    content: "\e993";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--remove-all {
  &:before {
    content: "\eb76";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.dx-state-hover .absui-icon--grid-unlock {
  color: var(--neutral-50, $neutral-50);

  &:hover {
    color: var(--neutral-30, $neutral-30);
  }

  &:before {
    content: "\eb1c";
    font-size: 18px !important;
  }
}

.dx-state-hover .absui-icon--grid-lock {
  color: var(--neutral-50, $neutral-50);

  &:hover {
    color: var(--neutral-30, $neutral-30);
  }

  &:before {
    content: "\eb1c";
    font-size: 18px !important;
  }
}

.absui-icon--grid-lock {
  &:before {
    content: "\eb1e";
    font-size: 18px !important;
  }
}

.absui-icon--set-unlock {
  &:before {
    content: "\eb1c";
    font-size: 1.12rem !important;
  }
}

.absui-icon--get-nbp {
  &:before {
    content: "\eb2f";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--get-ebc {
  &:before {
    content: "\eb2b";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--select-ebc {
  &:before {
    content: "\eb2c";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--select-nbp {
  &:before {
    content: "\eb2e";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--active {
  &:before {
    content: "\e9a6";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--factory {
  &:before {
    content: "\e96a";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--tables,
.btn-dx-top-menu .dx-icon.absui-icon--tables {
  &:before {
    content: "\eae1";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em !important;
    -webkit-font-smoothing: antialiased;
  }
}

.icon-star-mobile {
  &:before {
    content: "\e88a";
    font-size: 20px;
    font-family: Lumen;
    top: 2px;
    position: relative;
    color: var(--neutral-50, $neutral-50) !important;
  }
}

.icon-star-mobile-active {
  &:before {
    content: "\e88a";
    font-size: 20px;
    font-family: Lumen;
    top: 2px;
    position: relative;
    color: var(--icons-brand, $icons-brand) !important;
  }
}

.absui-icon--operations {
  &:before {
    content: "\e5d3";
    font-size: 1.12rem !important;
  }
}

.absui-icon--operations-kanban:before {
  content: "\e5d4";
  font-size: 1.12rem !important;
}

.absui-icon--sum {
  &:before {
    content: "\eb17";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--history {
  &:before {
    content: "\e910";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--playlist-play {
  &:before {
    content: "\e05f";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--creator {
  &:before {
    content: "\eb23";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--verified-user {
  &:before {
    content: "\e8e8";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--shield-question {
  &:before {
    content: "\ed58";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--pin-classic-off {
  &:before {
    content: "\eaa7";
    color: var(--text-dark-gray, $text-dark-gray);
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--pin-classic {
  &:before {
    content: "\e9ea";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--pin-classic-vertical {
  &:before {
    content: "\eb87";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--undo {
  &:before {
    content: "\ea06";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.history-clock-changes {
  &:before {
    content: "\eabb";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.icon-close-custom-search-box {
  border-radius: 6px;
  padding-top: 1px;
  padding-bottom: 1px;
  height: 28px;
  position: relative;
  top: 6px;

  &:before {
    border-radius: 6px;
    display: none;
    padding-top: 1px;
    padding-bottom: 2px;
    height: 28px;
    position: relative;
    top: 0px;
    content: "\e5cd";
    font-size: 14px;
  }

  &:hover {
    height: 28px;
    position: relative;
    top: 6px;
    background: transparent;

    &::before {
      color: var(--icons-brand, $icons-brand);
    }
  }
}

.dx-state-hover {
  .icon-close-custom-search-box {
    &:before {
      display: inline-block;
    }
  }
}

.absui-icon--close {
  &:before {
    content: "\e5cd";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--close-chips-gdpr-data-search {
  &:before {
    content: "\e5cd";
    font-size: 14px !important;
    padding: 2px;
    border-radius: 50%;
  }
}

.absui-icon--check-bold {
  &:before {
    content: "\eba8";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--symbol-asterisk {
  &:before {
    content: "\eb74";
    font-size: 1.12rem !important;
  }
}

.absui-icon--filter {
  &::before {
    content: "\f0b0";
    font-size: 1rem;
  }
}

.absui-icon--task-list {
  &::before {
    content: "\ead8";
    font-size: 1rem;
  }
}

.absui-icon--filter-disabled {
  &::before {
    content: "\e9c2";
    font-size: 1rem;
  }
}

.absui-icon--form-informnation2 {
  &::before {
    content: "\eb27";
    font-size: 1rem;
  }
}

.absui-icon--play {
  &::before {
    content: "\e973";
    font-size: 1rem;
  }
}

.absui-icon--object-group {
  &::before {
    content: "\eca4";
    font-size: 1rem;
  }
}

.absui-icon--file-out {
  &::before {
    content: "\e9be";
    font-size: 1rem;
  }
}

.globe {
  &:before {
    content: "\eae5";
    font-size: 1.12rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--multiplication {
  &:before {
    content: "\e96d";
    font-size: 18px !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.absui-icon--tab-filter {
  &:before {
    content: "\e951";
    font-size: 15px !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }
}

.dx-icon-more-horizontal::before {
  content: "\e5d3";
  font-family: Lumen;
  font-size: 18px !important;
  font-style: normal;
  color: var(--neutral-30, $neutral-30) !important;
  position: relative;
}

.icon.arrow-small-right:before {
  content: "\eb5b";
  font-family: Lumen;
  font-size: 18px;
  color: var(--neutral-40, $neutral-40);
  position: relative;
  top: 4px;
}

.dx-state-hover .icon.icon-circle-blank {
  color: var(--neutral-50, $neutral-50);

  &:hover {
    color: var(--neutral-30, $neutral-30);
  }

  &::before {
    content: "\eaff";
    font-family: Lumen;
    font-size: 18px;
  }
}

.icon.low-priority::before {
  content: "\eaff";
  font-family: Lumen;
  font-size: 18px !important;
  color: var(--neutral-40, $neutral-40);
  width: 18px;
  height: 18px;
}

.medium-priority::before {
  content: "\eb13";
  font-family: Lumen;
  font-size: 18px !important;
  color: var(--theme-positive-30, $theme-positive-30);
}

.icon.high-priority::before {
  content: "\eb14";
  font-family: Lumen;
  font-size: 18px !important;
  color: var(--icons-brand, $icons-brand);
  width: 18px;
  height: 18px;
}

.icon-group {
  &.icon-tree {
    color: var(--neutral-10, $neutral-10);
    padding-right: 5px;
    width: 18px;
    height: 18px;
    margin-right: 2px;

    &::before {
      top: 2px;
      font-size: 16px !important;
      width: 18px;
      height: 18px;
      margin-right: 2px;
    }
  }

  &.icon-cell::before {
    position: relative;
    top: 1px;
    left: 0;
    width: 18px;
    height: 18px;
    margin-right: 2px;
  }

  &::before {
    font-family: Lumen;
    font-size: 18px !important;
    font-style: normal;
    position: absolute;
    top: 21px;
    left: 16px;
    width: 18px;
    height: 18px;
    margin-right: 2px;
    color: var(--neutral-30, $neutral-30);
  }

  &.phone-group::before {
    content: "\e0cd";
  }

  &.user-group::before {
    content: "\ea48";
  }

  &.car-group::before {
    content: "\e531";
  }

  &.service-group::before {
    content: "\e931";
  }

  &.default-icon::before {
    content: "\e951";
  }

  &.icon-group.icon-review::before {
    content: "\e917";
  }

  &.icon-group.icon-repair::before {
    content: "\e992";
  }

  &.icon-group.icon-correction:before {
    content: "\ec9c";
  }

  &.plus-btn {
    position: absolute;
    top: 20px;
    left: calc(100% - 35px);

    &::before {
      top: 0;
      left: 0;
      content: "\e145";
    }
  }
}

i.positive::before {
  color: var(--theme-positive-30, $theme-positive-30) !important;
}

i.negative::before {
  color: var(--icons-brand, $icons-brand) !important;
}

.absui-icon--sql::before {
  content: "\ec50";
}

.absui-icon--media-play::before {
  content: "\ec4d";
}

.absui-icon--four-dots::before {
  content: "\ec43";
}

.absui-icon--info-outline::before {
  content: "\e88f";
}

.absui-icon--help-nest::before {
  content: "\e887";
}

.absui-icon--table::before {
  content: "\eae1";
}

.absui-icon--windowpanel-bottomhide::before {
  content: "\eaea";
}

.absui-icon--debug-step-into::before {
  content: "\ec4e";
}

.absui-icon--btn-circle-play-stop::before {
  content: "\ec4c";
}

.absui-icon--card::before {
  content: "\eaaa";
}

.absui-icon--if::before {
  content: "\ec54";
}

.absui-icon--else::before {
  content: "\ec53";
}

.absui-icon--cash-register::before {
  content: "\eaaf";
}

.absui-icon--add-circle-fill::before {
  content: "\ec27";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--arrow-backward::before {
  content: "\ec37";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--arrow-double-backward::before {
  content: "\ec38";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--arrow-foreward::before {
  content: "\ec3a";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--view-list2::before {
  content: "\eb0f";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--file-signature::before {
  content: "\ebdd";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--category::before {
  content: "\e92d";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--queue::before {
  content: "\e03c";
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--email-open::before {
  content: "\e966";
  font-size: 16px;
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--mailbox-draft::before {
  content: "\ec1d";
  font-size: 16px;
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--mailbox::before {
  content: "\ec1c";
  font-size: 16px;
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--mail-back::before {
  content: "\ec1a";
  font-size: 16px;
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--symbol-forbidden::before {
  content: "\ebb5";
  font-size: 16px;
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--clipboard-out::before {
  content: "\e9aa";
  font-size: 16px;
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--clipboard-cut::before {
  content: "\eab0";
  font-size: 16px;
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--clipboard-copy::before {
  content: "\eab8";
  font-size: 16px;
  font-size: 1.12rem !important;
  -webkit-text-stroke-width: 0.02em;
  -webkit-font-smoothing: antialiased;
}

.absui-icon--checkbox-deselect-full::before {
  content: "\ebed";
  font-size: 16px;
  font-size: 1.12rem !important;
}

.absui-icon--checkbox-select-full::before {
  content: "\ebef";
  font-size: 16px;
  font-size: 1.12rem !important;
}

.absui-icon--calendar-day-select::before {
  content: "\eafd";
  font-size: 16px;
  font-size: 1.12rem !important;
}

.absui-icon--refresh-mail::before {
  content: "\e94d";
  font-size: 16px;
  font-size: 1.12rem !important;
}

.absui-icon--windowpanel-lefthide::before {
  content: "\eaec";
  font-size: 16px;
  font-size: 1.12rem !important;
}

.absui-icon--windowpanel-leftshow::before {
  content: "\eaed";
  font-size: 16px;
  font-size: 1.12rem !important;
}

.absui-icon--mail-open::before {
  content: "\ec22";
  font-size: 16px;
  font-size: 1.12rem !important;
}

.absui-icon--warehouse-files::before {
  content: "\e902";
  font-size: 16px;
  font-size: 1.12rem !important;
}

.jpk-icon--calendar-month::before {
  content: "\e91e";
  font-size: 16px;
  line-height: 16px;
  color: var(--neutral-30, $neutral-30);
  position: absolute;
  top: 5px;
  left: 4px;
}

.absui-icon--help-outline-j:before {
  content: "\e8fd";
  color: var(--text-light-gray, $text-light-gray);
  font-size: 24px;
}

.absui-icon--circle-number-1::before {
  content: "\eb7d";
}

.absui-icon--circle-number-2::before {
  content: "\eb7e";
}

.absui-icon--circle-number-3::before {
  content: "\eb7f";
}

.absui-icon--circle-number-4::before {
  content: "\eb80";
}

.absui-icon--circle-number-5::before {
  content: "\eb81";
}

.absui-icon--circle-number-6::before {
  content: "\eb6f";
}

.absui-icon--circle-number-7::before {
  content: "\eb83";
}

.absui-icon--circle-number-8::before {
  content: "\eb84";
}

.absui-icon--circle-number-9::before {
  content: "\eb85";
}

.absui-icon--circle-number-0::before {
  content: "\eb7c";
}

.absui-icon--people-vendor::before {
  content: "\ebfd";
}

.absui-icon--file-info::before {
  content: "\eb55";
}

.absui-icon--collect-out {
  &::before {
    content: "\e9ad";
    font-size: 24px;
  }
}

.absui-icon--analysis {
  &::before {
    content: "\e916";
  }
}

.absui-icon--cells-merge {
  &::before {
    content: "\ebc5";
  }
}

.absui-icon--zoom-in {
  &::before {
    content: "\e8ff";
  }
}

.absui-icon--zoom-out {
  &::before {
    content: "\e913";
  }
}

.absui-icon--label {
  &::before {
    content: "\e9ff";
  }
}

.absui-icon--light-bulb-shine {
  &::before {
    content: "\ecb0";
    font-size: 24px;
  }
}

.absui-icon--delegacja {
  &::before {
    content: "\ea5c";
    font-size: 24px;
  }
}

.absui-icon--microphone {
  &::before {
    content: "\ecc1";
  }
}

.absui-icon--equalizer {
  &::before {
    content: "\e01d";
  }
}

.absui-icon--cloud-up {
  &::before {
    content: "\e9ac";
  }
}
