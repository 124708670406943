@use "./colors" as *;

.main-mobile-grid .dx-datagrid .dx-datagrid-headers {
  border-top: none;
  display: none;
}

.fullWidth {
  width: 100% !important;
}

.title-data-header-document {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--neutral-10, $neutral-10);
  margin-bottom: 0px;
  display: inline-block;
}

.more-mobile-details {
  padding: 30px 16px;
  // background: white;
  margin-top: -30px;
  margin-bottom: 20px;
}

.width-mobile {
  width: 100% !important;
  margin-right: 0px !important;
}

.data-header-document {
  padding: 10px 16px;
  width: 100%;
  background: var(--neutral-100, $neutral-100);
  border-radius: 8px;
  box-shadow: var(--shadow-l);
}

.circle-showMore {
  width: 32px;
  height: 32px;
  position: relative;
  left: calc(50% - 16px);
  top: -16px;
  padding-top: 1px;
  border-radius: 50%;
  background: var(--neutral-100, $neutral-100);
  box-shadow: var(--shadow-l);
  line-height: 42px;
  text-align: center;
}

.details-label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-dark-gray, $text-dark-gray);
  margin-bottom: 0px;
}

.mobile-sum-document {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: var(--text-dark-gray, $text-dark-gray);
}

.mobile-sum-gross-document {
  color: var(--text-brand, $text-brand) !important;
}

.details-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: var(--neutral-30, $neutral-30);
  margin-bottom: 5px;
}

.details-mobile-panel {
  width: 100%;
  // height: calc(100% - 80px);
  height: 100%;
  overflow: auto;
  // position: absolute;
  position: fixed;
  // top: 77px;
  top: 0;
  left: 0px;
  background: var(--neutral-100, $neutral-100);
  z-index: 14;
  background: var(--neutral-95, $neutral-95);

  header {
    padding: 10px 8px 16px;
    background: var(--neutral-100, $neutral-100);
    box-shadow: var(--shadow-l);
  }

  .nameSelectedPosition {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--neutral-10, $neutral-10);
  }

  .left-header {
    width: 30px;
    float: left;
  }

  .right-details {
    padding-left: 12px;
    float: left;
  }
}

.main-mobile-grid {
  max-width: 100% !important;
}

.semafore-mobile {
  background: transparent;
  border-radius: 32px !important;
  width: 4px !important;
  float: left;
}

.semafore-edit-by-me {
  background: var(--accent-red, $accent-red);
}

.semafore-edit-by-another-user {
  background: var(--accent-light-blue, $accent-light-blue);
}

.content-main-grid {
  padding-left: 12px;
}

.hov-underline-mobile {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-brand, $text-brand);
}

.mobile-grid-label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--neutral-30, $neutral-30);
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 0px;
}

.dx-row-focused.dx-selection .mobile-grid-label {
  color: var(--brand-neutral-00, $brand-neutral-00) !important;
}
.dx-row-focused.dx-selection .mobile-grid-text {
  color: var(--brand-neutral-10, $brand-neutral-10) !important;
}
.dx-row-focused.dx-selection .hov-underline-mobile {
  color: var(--brand-10, $brand-10) !important;
}
.dx-row-focused.dx-selection .dx-icon:before {
  color: var(--icons-dark-gray, $icons-dark-gray) !important;
}
.dx-row-focused.dx-selection .dx-button:hover .dx-icon:before {
  color: var(--neutral-30, $neutral-30) !important;
}

.mobile-grid-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  color: var(--neutral-30, $neutral-30);
  margin-bottom: 0px;
  display: inline-block;
}

//menu mobilen
.top-header-mobile {
  height: 36px;
  background-color: var(--brand-neutral-00, $brand-neutral-00);
  padding: 0;
}

.navbar-toggler-mobile .dx-icon-menu::before {
  content: "\eabf";
  color: var(--text-brand-inverted, $text-brand-inverted) !important;
  font-size: 1.3125rem;
  padding: 12px 12px;
  display: inline-block;
  top: -3px !important;
}

.mobile-main-menu .dx-submenu {
  display: none;
}

.mobile-main-menu .dx-context-menu-container-border {
  display: none;
}

.tap-menu-mobile {
  background-color: var(
    --ds-color-ui-state-neutral-hover,
    $ds-color-ui-state-neutral-hover
  ) !important;
  border-radius: 4px;
}

.navbar-toggler-mobile-active .dx-icon-menu::before {
  content: "\eabf";
  color: var(--neutral-10, $neutral-10) !important;
  font-size: 1.3125rem;
  padding: 12px 12px;
  display: inline-block;
}

.menu-mobile.dx-popup-wrapper > .dx-overlay-content {
  border: none;
  background: var(
    --ds-background-navigation-page-header,
    $ds-background-navigation-page-header
  );
  box-shadow: var(--shadow-l);
  border-radius: 0px;
  min-width: 480px;
}

.about-program.dx-popup-wrapper > .dx-overlay-content {
  border: none;
  background: var(
    --ds-background-navigation-page-header,
    $ds-background-navigation-page-header
  );
  box-shadow: var(--shadow-l);
  border-radius: 8px 8px 0px 0px;
  min-width: 350px;
}

.menu-mobile .active {
  border-radius: 4px;
  width: 100% !important;
}

.active .dx-menu-item-text-span-mobile {
  color: var(--text-brand, $text-brand);
}

.menu-mobile .dx-popup-content {
  padding: 10px 0px !important;
}

.title-header-mobile {
  font-family: "Geomanist";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: var(--neutral-100, $neutral-100);
}

.nav-mobile {
  height: 40px;
  background-color: var(--brand-neutral-10, $brand-neutral-10);
  display: flex;
  align-items: center;
}

.navbar-toggler-mobile {
  height: 40px;
  width: 40px;
  background-color: var(--brand-neutral-10, $brand-neutral-10);
  display: inline-block;
}

.right-mobile-grid {
  float: right;
  width: 40px;
}

.navbar-toggler-mobile-active {
  height: 41px;
  width: 40px;
  background-color: var(--neutral-100, $neutral-100);
  display: inline-block;
}

.item-menu-mobile {
  height: 44px;
  width: 100% !important;
  padding: 12px 6px 12px 12px;
}

.content-main-grid {
  width: calc(100% - 40px);
  max-width: calc(100% - 40px);
}

.dx-menu-items-container {
  list-style-type: none;
  display: inline-block;
  white-space: nowrap;
  cursor: pointer;
  width: 100% !important;
}

.dx-menu-item-text-span-mobile {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--neutral-30, $neutral-30);
  display: inline-block;
  margin-left: 8px;
}

.bottom-mobile-form {
  height: 56px;
  background-color: var(--neutral-100, $neutral-100);
  border-top: 1px solid var(--neutral-80, $neutral-80);
  position: absolute;
  width: 100vw;
  left: 0px;
  bottom: 0px;
  padding: 12px;
  box-sizing: border-box;
  text-align: right;
}

.right-mobile-grid-selected {
  position: relative;
  bottom: -35px;
  right: -15px;
  z-index: 10;
}

.content-main-grid-selected {
  width: calc(100% - 50px);
  border-right: 1px solid var(--neutral-80, $neutral-80);
}

//-----------------------------------

@media (max-width: 1000px) {
  dx-accordion.no-padding {
    padding-left: 5px !important;
  }


  .tab-panel-route-mobile .dx-tabs-wrapper {
    top: 0px !important;
  }

  .tab-panel-route-mobile .dx-tabpanel-container {
    height: 100% !important;
  }

  .tab-panel-route-mobile-empty .dx-tabpanel-container {
    height: calc(100vh - 119px) !important;
  }

  .choosePoint.dx-texteditor.dx-editor-outlined {
    width: calc(100% - 23px) !important;
    max-width: 100% !important;
  }

  .h-28-i.mobile {
    height: 32px !important;
  }

  .hide-notification {
    display: none;
  }

  .form-financial-document-description {
    width: 100% !important;
    max-width: 100% !important;
  }

  .choosePointMobile {
    margin-left: 0px !important;
  }

  .dx-popup-flex-height .dx-popup-content {
    margin-top: 10px !important;
  }

  .form-group-inline-right app-country-box {
    width: 100% !important;
    max-width: 100% !important;
  }

  .dx-toolbar-label .dx-toolbar-item-content > div {
    margin-top: 5px !important;
  }

  .popup-form .flex-box-column-content {
    gap: 12px 0 !important;
    display: grid;
  }

  .main-mobile-grid .dx-datagrid .dx-row > td {
    padding: 5px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 0px;
    width: calc(100vw - 20px);
  }

  .dx-datagrid .dx-scrollable-container {
    overflow: hidden;
    width: 100%;
    height: 100% !important;
  }

  dx-tab-panel.form-tab .dx-tabpanel-container {
    margin-top: 0 !important;
    padding-top: 26px !important;
  }

  .btn-dx-top-menu-folder {
    height: 32px !important;
  }
  .dx-button-mode-contained.dx-button-danger {
    height: 32px !important;
  }

  .dx-button-mode-contained.dx-button-success {
    height: 32px !important;
  }

  .dx-button.outline-secondary {
    height: 32px !important;
  }

  .dx-button-has-text .dx-button-content {
    padding: 6px 16px !important;
  }

  app-additional-operation {
    display: none;
  }
  .top-menu .top-menu-item .dx-menu-item-text {
    font-size: 14px !important;
  }

  .dx-texteditor.dx-editor-outlined {
    height: 32px !important;
  }

  .btn-dx-secondary {
    width: 100% !important;
    height: 32px !important;
  }

  .btn-dx-secondary .dx-button-text {
    font-size: 14px !important;
    color: var(--neutral-30, $neutral-30) !important;
  }

  dx-drop-down-box .dx-dropdowneditor-icon::before {
    font-size: 18px;
  }

  .dx-widget input,
  .dx-widget textarea {
    font-size: 14px !important;
  }

  dx-tab-panel {
    .dx-multiview-wrapper {
      //padding-left: 6px;
      padding-right: 0px !important;
    }
  }

  .colorInput {
    width: calc(100% - 52px) !important;
  }

  .main-mobile-grid
    .dx-datagrid-content
    .dx-datagrid-table
    .dx-row
    .dx-command-select {
    right: 0px;
    position: absolute;
    text-align: center;
    height: 50px;
  }

  .dx-focused {
    background-color: transparent !important;
  }

  .offer-pos-accordion {
    width: calc(100% + 20px) !important;
    margin-left: -15px;
  }

  .a-hyper-link {
    left: -133px !important;
  }

  .dx-datagrid .dx-row.dx-data-row.dx-column-lines {
    border-bottom: 2px solid var(--neutral-80, $neutral-80);
  }

  .main-mobile-grid {
    .dx-datagrid .dx-row.dx-data-row.dx-column-lines {
      border-bottom: 1px solid var(--neutral-80, $neutral-80);
      width: calc(100vw - 20px) !important;
    }
  }

  .dx-checkbox-icon {
    width: 17px;
    height: 19px;
    border-radius: 2px;
    border: 1px solid var(--neutral-50, $neutral-50);
    border: none;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: scrollbartrack-vertical;
    width: 0px !important;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: scrollbartrack-horizontal;
    width: 0px !important;
  }

  .right-header-btn {
    display: none;
  }

  .apps-panel {
    //width: 144px;
    width: 112px;
    opacity: 0;
    height: auto;
    box-sizing: border-box;
    background: var(--neutral-100, $neutral-100);
    box-shadow: var(--shadow-m);
    position: absolute;
    z-index: 0;
    display: none;
    top: 32px;
    left: 81px;
    padding: 0px 31px;
    -ms-transition: all 400ms;
    -webkit-transition: all 400ms;
    -moz-transition: all 400ms;
    -o-transition: all 400ms;
    transition: all 400ms;
  }

  .top-menu-wrapper {
    height: 50px !important;
  }

  .mobile-user-icon .icon-search:before {
    font-size: 22px !important;
  }

  .dx-scrollable-native > div.dx-scrollable-wrapper > .dx-scrollable-container,
  .dx-scrollable-native.dx-scrollable-wrapper > .dx-scrollable-container {
    -webkit-overflow-scrolling: touch;
    position: relative;
    height: 100%;
  }

  .dx-icon-menu::before {
    font-size: 22px !important;
  }

  .absui-icon--account-circle::before {
    font-size: 22px !important;
    line-height: 22px;
  }

  .displayBloc {
    display: block !important;
    margin-bottom: -10px;
  }

  .dx-data-row:hover {
    background-color: var(
      --ds-color-ui-state-neutral-hover,
      $ds-color-ui-state-neutral-hover
    );
  }

  .dx-datagrid .dx-row.dx-column-lines.dx-header-row {
    background: var(--neutral-100, $neutral-100);
    height: 34px;
  }

  .dx-datagrid .dx-row.dx-column-lines.dx-header-row > td:not(:last-child) {
    border-right: none;
  }

  .dx-datagrid {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--neutral-10, $neutral-10);
  }

  label {
    text-align: left !important;
    margin-bottom: 4px;
    // width: 100%!important;
  }

  .btn-filter-box {
    padding: 0px;
    border-radius: 30px !important;
    border: 1px solid transparent !important;
    margin-right: 6px;
    height: 26px !important;
    position: relative;
    top: 0px;
    font-size: 0.75rem;
    padding-right: 17px !important;
    font-family: "Roboto";
    font-weight: 400;
  }

  dx-button[icon~="absui-icon--clear-filter"] {
    padding: 0 !important;
    top: -4px;
  }

  .margin12bottom {
    margin-bottom: 12px;
  }

  .topZero {
    top: 0px !important;
  }

  .toTop {
    position: relative;
    top: 0px;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }

  .chips-btn {
    margin-bottom: 0px;
    &.btn-filter-box {
      margin-bottom: 4px;
      padding-right: 0 !important;
    }
  }

  .col-md-6 {
    padding-right: 15px !important;
  }
  .dropDownBoxClassGrid {
    height: 263px !important;
    width: 100% !important;
  }

  .document-form-mobile .title-pop-up {
    display: none;
  }

  .dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-row-lines) > td,
  .dx-datagrid-rowsview
    .dx-row-focused.dx-data-row:not(.dx-row-lines)
    > tr:last-child
    > td {
    background-color: var(
      --ds-color-ui-state-cell-focus,
      $ds-color-ui-state-cell-focus
    ) !important;
    color: var(--neutral-30, $neutral-30) !important;
  }

  .tab-panel-document .dx-tabpanel-tabs {
    width: 100%;
    height: 44px;
    background: var(--neutral-95, $neutral-95);
    padding: 0 16px;
  }

  .tab-panel-document .dx-tabpanel-container {
    margin-top: 0px !important;
    padding: 10px 16px !important;
    box-shadow: var(--shadow-m);
    border-radius: 8px;
  }

  .main-mobile-grid
    .dx-datagrid-content
    .dx-datagrid-table
    .dx-row
    .dx-editor-cell {
    max-width: 0;
    padding: 0;
    vertical-align: top;
    padding-top: 14px !important;
  }

  .main-mobile-grid .dx-datagrid-checkbox-size {
    vertical-align: middle;
    padding-bottom: 2px;
    height: 20px;
  }

  .dx-datagrid-rowsview .dx-adaptive-detail-row .dx-adaptive-item-text {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 1px;
  }

  /* mobile: checkbox na sfocusowanej pozycji nie mieści się w kolumnie np. artykuly / nowy / definicje,intrastat / sprzedaż wiązana */
  .dx-datagrid-rowsview
    .dx-row-focused.dx-data-row:not(.dx-row-lines)
    > td
    .dx-checkbox
    .dx-checkbox-icon::before,
  .dx-datagrid-rowsview
    .dx-row-focused.dx-data-row:not(.dx-row-lines)
    > tr:last-child
    > td
    .dx-checkbox
    .dx-checkbox-icon::before {
    content: "\eab2";
    position: absolute;
    color: var(--neutral-50, $neutral-50) !important;
    display: block;
    width: 17px;
    top: 50%;
    margin-top: -10px;
    left: 50%;
    margin-left: -8px;
  }

  .dx-datagrid-rowsview
    .dx-row-focused.dx-data-row:not(.dx-row-lines).dx-selection
    > td
    .dx-checkbox
    .dx-checkbox-icon::before,
  .dx-datagrid-rowsview
    .dx-row-focused.dx-data-row:not(.dx-row-lines).dx-selection
    > tr:last-child
    > td
    .dx-checkbox
    .dx-checkbox-icon::before {
    color: var(--brand-00, $brand-00) !important;
    content: "\e9a7" !important;
  }

  .bottomTabPanelMobile {
    .dx-tabpanel-tabs {
      width: 100%;
      height: 34px;
      padding: 0 16px;
    }

    .dx-tabpanel > .dx-tabpanel-tabs .dx-tabs-scrollable .dx-tabs-wrapper {
      display: table-row;
      top: 1px;
      position: relative;
    }

    .dx-tabpanel-container {
      // margin-top: 0px !important;
      padding-top: 10px !important;
      padding-left: 16px;
      padding-right: 16px;
      padding-left: 12px;
      padding-right: 12px;
      background: var(--neutral-100, $neutral-100);
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .dx-tab.dx-tab-selected {
      background-color: transparent;
      color: var(--text-brand, $text-brand);
      border-bottom: 2px solid var(--text-brand, $text-brand);
      height: 32px;
    }
  }

  .main-mobile-grid colgroup {
    display: none;
  }

  .main-mobile-grid thead {
    display: none;
  }

  body {
    overflow-x: hidden;
    max-width: 100vw;
  }

  .dx-row-focused .dx-progressbar-percent,
  .dx-row-focused i::before,
  .dx-row-focused .black-txt,
  .dx-row-focused .grey-txt,
  .dx-row-focused .hov-underline {
    color: var(--neutral-30, $neutral-30) !important;
  }

  .dx-scrollable-native.dx-scrollable-both,
  .dx-scrollable-native.dx-scrollable-both
    > .dx-scrollable-wrapper
    > .dx-scrollable-container,
  .dx-scrollable-native.dx-scrollable-both
    > div
    > .dx-scrollable-wrapper
    > .dx-scrollable-container {
    touch-action: pan-y;
    float: none;
    overflow-x: hidden;
    overflow-y: auto !important;
    width: 100%;
  }

  .dx-scrollable-native.dx-scrollable-vertical,
  .dx-scrollable-native.dx-scrollable-vertical
    > .dx-scrollable-wrapper
    > .dx-scrollable-container,
  .dx-scrollable-native.dx-scrollable-vertical
    > div
    > .dx-scrollable-wrapper
    > .dx-scrollable-container {
    touch-action: pan-y;
    overflow-x: hidden;
    overflow-y: auto !important;
    width: 100%;
  }

  .dx-datagrid-rowsview .dx-row-focused.dx-data-row > td,
  .dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr:last-child > td {
    border-bottom: none;
  }

  .dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-row-lines) > td,
  .dx-datagrid-rowsview
    .dx-row-focused.dx-data-row:not(.dx-row-lines)
    > tr:last-child
    > td {
    background-color: transparent !important;
  }

  .dropDownBoxClassGrid {
    .dx-row-focused.dx-data-row .dx-command-edit:not(.dx-focused) .dx-link,
    .dx-datagrid-rowsview .dx-row-focused.dx-data-row > td:not(.dx-focused),
    .dx-datagrid-rowsview
      .dx-row-focused.dx-data-row
      > tr
      > td:not(.dx-focused) {
      background-color: var(--neutral-40, $neutral-40);
      // color: var(--neutral-100, $neutral-100);
    }
  }

  .dx-popup-title.dx-toolbar {
    height: 52px !important;
    padding: 0px 0px;
    overflow: visible;
    box-shadow: none;
  }

  .dx-tab.dx-tab-selected {
    background-color: transparent;
    height: 43px !important;
  }

  .dx-tabs-scrollable .dx-tab {
    height: 42px !important;
    display: inline-block;
  }

  .dx-tabpanel > .dx-tabpanel-tabs .dx-tabs {
    box-shadow: none;
    height: 44px !important;
  }

  .dx-tab {
    background-color: transparent;
    padding: 13px 10px !important;
  }

  .dx-tab-text {
    vertical-align: middle;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem !important;
    line-height: 14px;
  }

  .dx-popup-title.dx-toolbar .dx-toolbar-item:last-child {
    padding-left: 14px !important;
    padding-top: 12px !important;
  }

  .dx-popup-content {
    padding: 16px 14px !important;
    padding-top: 0px !important;
  }

  .absui-icon--close:before {
    content: "\e5cd";
    font-size: 1.25rem !important;
    -webkit-text-stroke-width: 0.02em;
    -webkit-font-smoothing: antialiased;
  }

  .dx-popup-title.dx-toolbar .dx-toolbar-label {
    font-size: 1rem !important;
  }

  .dx-popup-wrapper > .dx-overlay-content {
    box-shadow: var(--shadow-l);
    border-radius: 6px;
    min-width: 300px !important;
    max-width: 100%;
  }

  .big-form-mobile.dx-popup-wrapper > .dx-overlay-content {
    box-shadow: var(--shadow-l);
    border-radius: 0px;
    min-width: 100% !important;
    max-width: 100%;
    height: 100% !important;
    max-height: 100% !important;
  }

  .document-header-mobile-panel {
    // padding: 0px 15px 16px;
    // width: calc(100% + 28px);
    // left: -14px;
    position: relative;
    background: var(--neutral-100, $neutral-100);
    z-index: 10;
    background: var(--neutral-95, $neutral-95);
    header {
      padding: 10px 8px 14px;
      background: var(--neutral-100, $neutral-100);
      box-shadow: var(--shadow-l);
    }

    .nameSelectedPosition {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: var(--neutral-10, $neutral-10);
      padding-bottom: 5px;
    }
  }

  .document-form-mobile .dx-popup-content {
    padding: 0px 0px !important;
  }

  .document-form-mobile.dx-popup-wrapper > .dx-overlay-content {
    box-shadow: var(--shadow-l);
    border-radius: 0px;
    min-width: 300px !important;
    max-width: 100%;
    width: 100% !important;
    // height: calc(100vh - 76px) !important;
    height: 100vh !important;
  }

  .bottom-side-panel {
    display: none;
  }

  .with_search_panel {
    width: 100% !important;
  }

  .right-side-panel {
    display: none;
  }

  .search-result-panel {
    width: 100% !important;
    left: 0px !important;
    height: 100% !important;
    max-height: 100% !important;
    top: 0px !important;
    padding-top: 6px;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
  }

  .content-about-program {
    padding-left: 14px !important;
    padding-right: 14px !important;
  }

  .scroll-height {
    max-height: 82vh !important;
    margin-top: 80px !important;
  }

  header {
    position: relative !important;
  }

  .top-menu-wrapper {
    position: relative !important;
  }

  .top-menu-panel {
    position: relative;
    height: 40px !important;
    padding-top: 6px !important;
    padding-left: 12px !important;
    padding-right: 6px !important;
    padding-bottom: 8px !important;
    margin-bottom: 20px !important;
  }

  .btn-dx-top-menu-folder .absui-icon--add-circle:before {
    content: "\e147";
    position: relative;
    top: 1px;
  }

  .right-header-btn {
    top: 80px !important;
  }

  .dx-menu-item.dx-state-hover {
    border-radius: 6px;
    background-color: transparent !important;
  }

  .menu-mobile.dx-popup-wrapper > .dx-overlay-content {
    box-shadow: none;
    min-width: 100% !important;
    min-height: 70% !important;
  }

  .menu-mobile .dx-menu-base .dx-menu-item-content {
    padding: 1px 12px 1px !important;
  }

  .absui-icon--arrow-drop-right-user-panel::before {
    font-size: 20px !important;
  }

  .settings-mobile.dx-popup-wrapper > .dx-overlay-content {
    min-width: 320px !important;
  }

  .dx-texteditor.dx-editor-outlined {
    width: 100% !important;
    max-width: 100% !important;
  }

  .dx-texteditor.dx-editor-outlined.change-address {
    width: calc(100% - 35px) !important;
    max-width: 100% !important;
  }

  .oneIconBeside.dx-texteditor.dx-editor-outlined {
    width: calc(100vw - 87px) !important;
  }

  .btn-secondary-only-icon {
    height: 32px !important;
    width: auto !important;
    margin-left: 6px;
  }
  .treeIconBeside.dx-texteditor.dx-editor-outlined {
    width: calc(100vw - 164px) !important;
  }

  .box-body .form-group-inline-right {
    width: 100% !important;
    min-width: max-content;
    padding-left: 2px;
    float: right;
  }

  .customer_div {
    width: 100% !important;
  }

  .main-mobile-grid .dx-datagrid-checkbox-size .dx-checkbox-icon {
    height: 18px;
    width: 18px;
    margin-right: 5px;
  }

  .dx-datagrid-checkbox-size .dx-checkbox-icon {
    height: 18px;
    position: relative;
    right: 0px;
    top: -1px;
    width: 18px;
  }

  .dx-checkbox .dx-checkbox-icon::before {
    transform: translateY(-50%);
    margin-top: 0px !important;
    content: "\eab2";
    position: absolute;
    display: block;
    width: 18px;
    top: 50%;
    left: 50%;
    margin-left: -10px;
    font-size: 21px;
  }

  .mobile-position {
    top: -1px !important;
  }

  .btn-dx-top-menu-folder {
    margin-right: 12px !important;
  }

  .btn-dx-top-menu-folder .dx-icon::before {
    font-size: 20px !important;
  }

  .btn-dx-top-menu-folder.lastBtn {
    margin-right: 0px !important;
  }

  .dx-icon-menu::before {
    top: -3px !important;
  }

  .dx-scrollable-native.dx-scrollable-native-ios .dx-scrollable-content {
    min-height: 100%;
    box-sizing: content-box;
  }
}

@media (max-width: 400px) {
  .custom-wrapper {
    width: 300px !important;
    max-width: 100% !important;
  }

  .spinner-border {
    width: 3rem !important;
    height: 3rem !important;
    left: calc(50% - 24.5px) !important;
  }

  .indigoSignature {
    display: none;
  }
}

.mobile-search-box-container {
  display: flex;
  justify-content: stretch;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;

  & > * {
    margin-bottom: 0;
    flex: 1 1 calc(100% - 40px);
  }

  .dx-button {
    border-color: transparent;
    border-radius: 50%;
    flex: 1 0 33px;

    &:hover {
      background-color: var(--neutral-90, $neutral-90);
    }
  }
}

.search-result-panel .dx-button {
  border-color: transparent;
  border-radius: 50%;
  flex: 1 0 32px;
  width: 100%;

  &:hover {
    background-color: var(--neutral-90, $neutral-90);
  }
}

.wapro-anywhere-mobile svg {
  max-width: 237px;
}

.main-mobile-grid .dx-command-expand {
  border-bottom: 1px solid var(--neutral-70, $neutral-70) !important;
}

@media (max-width: 1000px) {
  .count-container {
    height: auto !important;
  }
}
