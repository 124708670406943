/* small containers, dropdows, popovers, buttons */
.small-corners{
    border-radius: 6px;
}
/* big cotainers */
.big-corners{
    border-radius: 12px;
}
/* form controls */
.full-corners{
    border-radius: 32px; /* 50 % ? */
}
