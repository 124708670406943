@use "./colors" as *;

.dx-fileuploader-file-name {
  float: left;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-bottom: 3.5px;
  color: var(--neutral-30, $neutral-30);;
}

.dx-fileuploader-file-status-message {
  float: left;
  font-size: 12px;
  height: 16px;
  display: none;
  margin-bottom: 10px;
}

.dx-fileuploader-file-info {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}

.cropper-container {
  direction: ltr;
  font-size: 0;
  line-height: 0;
  position: relative;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cropper-container img {
  backface-visibility: hidden;
  display: block;
  height: 100%;
  image-orientation: 0deg;
  max-height: none !important;
  max-width: none !important;
  min-height: 0 !important;
  min-width: 0 !important;
  width: 100%;
}

.cropper-wrap-box,
.cropper-canvas,
.cropper-drag-box,
.cropper-crop-box,
.cropper-modal {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.cropper-wrap-box,
.cropper-canvas {
  overflow: hidden;
}

.cropper-drag-box {
  background-color: var(--neutral-100, $neutral-100);
  opacity: 0;
}

.cropper-modal {
  background-color: var(--neutral-100, $neutral-100);
  opacity: 0.5;
}

.cropper-view-box {
  display: block;
  height: 100%;
  outline: 1px solid #39f;
  outline-color: rgba(51, 153, 255, 0.75);
  overflow: hidden;
  width: 100%;
}

.cropper-dashed {
  border: 0 dashed #eee;
  display: block;
  opacity: 0.5;
  position: absolute;
}

.cropper-dashed.dashed-h {
  border-bottom-width: 1px;
  border-top-width: 1px;
  height: calc(100% / 3);
  left: 0;
  top: calc(100% / 3);
  width: 100%;
}

.cropper-dashed.dashed-v {
  border-left-width: 1px;
  border-right-width: 1px;
  height: 100%;
  left: calc(100% / 3);
  top: 0;
  width: calc(100% / 3);
}

.cropper-center {
  display: block;
  height: 0;
  left: 50%;
  opacity: 0.75;
  position: absolute;
  top: 50%;
  width: 0;
}

.cropper-center::before,
.cropper-center::after {
  background-color: #eee;
  content: " ";
  display: block;
  position: absolute;
}

.cropper-center::before {
  height: 1px;
  left: -3px;
  top: 0;
  width: 7px;
}

.cropper-center::after {
  height: 7px;
  left: 0;
  top: -3px;
  width: 1px;
}

.cropper-face,
.cropper-line,
.cropper-point {
  display: block;
  height: 100%;
  opacity: 0.1;
  position: absolute;
  width: 100%;
}

.cropper-face {
  background-color: var(--neutral-100, $neutral-100);
  left: 0;
  top: 0;
}

.cropper-line {
  background-color: #39f;
}

.cropper-line.line-e {
  cursor: ew-resize;
  right: -3px;
  top: 0;
  width: 5px;
}

.cropper-line.line-n {
  cursor: ns-resize;
  height: 5px;
  left: 0;
  top: -3px;
}

.cropper-line.line-w {
  cursor: ew-resize;
  left: -3px;
  top: 0;
  width: 5px;
}

.cropper-line.line-s {
  bottom: -3px;
  cursor: ns-resize;
  height: 5px;
  left: 0;
}

.cropper-point {
  background-color: #39f;
  height: 5px;
  opacity: 0.75;
  width: 5px;
}

.cropper-point.point-e {
  cursor: ew-resize;
  margin-top: -3px;
  right: -3px;
  top: 50%;
}

.cropper-point.point-n {
  cursor: ns-resize;
  left: 50%;
  margin-left: -3px;
  top: -3px;
}

.cropper-point.point-w {
  cursor: ew-resize;
  left: -3px;
  margin-top: -3px;
  top: 50%;
}

.cropper-point.point-s {
  bottom: -3px;
  cursor: s-resize;
  left: 50%;
  margin-left: -3px;
}

.cropper-point.point-ne {
  cursor: nesw-resize;
  right: -3px;
  top: -3px;
}

.cropper-point.point-nw {
  cursor: nwse-resize;
  left: -3px;
  top: -3px;
}

.cropper-point.point-sw {
  bottom: -3px;
  cursor: nesw-resize;
  left: -3px;
}

.cropper-point.point-se {
  bottom: -3px;
  cursor: nwse-resize;
  height: 20px;
  opacity: 1;
  right: -3px;
  width: 20px;
}

@media (min-width: 768px) {
  .cropper-point.point-se {
    height: 15px;
    width: 15px;
  }
}

@media (min-width: 992px) {
  .cropper-point.point-se {
    height: 10px;
    width: 10px;
  }
}

@media (min-width: 1200px) {
  .cropper-point.point-se {
    height: 5px;
    opacity: 0.75;
    width: 5px;
  }
}

.cropper-point.point-se::before {
  background-color: #39f;
  bottom: -50%;
  content: " ";
  display: block;
  height: 200%;
  opacity: 0;
  position: absolute;
  right: -50%;
  width: 200%;
}

.cropper-invisible {
  opacity: 0;
}

.cropper-bg {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC");
}

.cropper-hide {
  display: block;
  height: 0;
  position: absolute;
  width: 0;
}

.cropper-hidden {
  display: none !important;
}

.cropper-move {
  cursor: move;
}

.cropper-crop {
  cursor: crosshair;
}

.cropper-disabled .cropper-drag-box,
.cropper-disabled .cropper-face,
.cropper-disabled .cropper-line,
.cropper-disabled .cropper-point {
  cursor: not-allowed;
}

.ngx-pe-overlay-container {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 1rem;
  box-sizing: border-box;
}
.ngx-pe-overlay-container * {
  box-sizing: border-box;
}
.ngx-pe-dialog-container {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  outline: 0;
  width: 100%;
  height: auto;
  max-height: 95vh;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
  background: var(--neutral-100, $neutral-100);
  color: var(--neutral-100, $neutral-100);
  display: flex;
  flex-direction: column;
  border: 1px solid var(--neutral-70, $neutral-70);
}
.ngx-pe-dialog-header {
  border-radius: 8px 8px 0px 0px;
  padding: 14px 16px;
  background: var(--neutral-100, $neutral-100);
  /* shadow-l */
  box-shadow: var(--shadow-l);
  font-size: 1rem;
  font-weight: 400;

  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: var(--neutral-30, $neutral-30);
}
.ngx-pe-dialog-body {
  height: 100%;
  position: relative;
  box-sizing: border-box;
  top: 40px;
}
.ngx-pe-dialog-footer {
  padding: 1rem 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 40px;
}
.ngx-pe-cropper-wrapper {
  position: relative;
}
.ngx-pe-img {
  display: block;
  max-width: 100%;
  height: auto;
  max-height: 300px;
}
.ngx-pe-tool-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: var(--neutral-100, $neutral-100);
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  top: -40px;
  padding-left: 15px;
}
.ngx-pe-tool-bar button {
  background-color: transparent;
  border-width: 0;
  color: var(--neutral-100, $neutral-100);
  cursor: pointer;
  float: left;
  font-size: 0.875rem;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
}
.ngx-pe-tool-bar button:hover {
  background-color: var(--neutral-100, $neutral-100);
  color: var(--neutral-30, $neutral-30);
}
.ngx-pe-tool-bar svg {
  width: 20px;
  height: 20px;
  fill: var(--neutral-30, $neutral-30);
}
.ngx-pe-btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.25rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb));
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.ngx-pe-btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.ngx-pe-btn-outline-light {
  border-radius: 4px;
  background: var(--neutral-80, $neutral-80);
  width: 70px;
  height: 26px;
  padding: 4px 12px;
  color: var(--neutral-30, $neutral-30);
  /* body-12/medium */
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border: none;
  position: relative;
  left: 78px;
  &:hover {
    background: var(--neutral-60, $neutral-60);
    color: var(--neutral-30, $neutral-30);
    border: none;
  }
}

.remove-btn-ngx-editor {
  border-radius: 4px;
  background: var(--text-brand, $text-brand) !important;
  width: auto !important;
  height: 23px !important;
  padding: 4px 12px;
  color: #fff !important;
  /* body-12/medium */
  font-family: Roboto !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 18px !important;
  border: none;
  position: relative;
  top: -28px;
  left: 60px;
  &:hover {
    background: var(--brand-00, $brand-00) !important;
    border: none;
  }
}
.ngx-pe-btn-primary {
  border-radius: 4px;
  background: var(--text-brand, $text-brand);
  width: 70px;
  height: 26px;
  padding: 3px 12px;
  color: #fff;
  /* body-12/medium */
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border: none;
  position: relative;
  left: -76px;
  &:hover {
    background: var(--brand-00, $brand-00);
    border: none;
  }
}
.ngx-pe-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: var(--text-dark-gray, $text-dark-gray);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 10s ease;
}
.ngx-pe-processing {
  background-color: rgba(34, 34, 34, 0.8);
}
.ngx-pe-loading svg {
  width: 50px;
}

.zoom-in {
  &:before {
    font: normal normal normal 22px Lumen;
    content: "\e8ff";
    color: var(--neutral-30, $neutral-30);
    font-size: 18px;
  }
  svg {
    display: none;
  }
}

.zoom-out {
  &:before {
    font: normal normal normal 22px Lumen;
    content: "\e913";
    color: var(--neutral-30, $neutral-30);
    font-size: 18px;
  }
  svg {
    display: none;
  }
}

.rotate-left {
  &:before {
    font: normal normal normal 22px Lumen;
    content: "\ece8";
    color: var(--neutral-30, $neutral-30);
    font-size: 18px;
  }
  svg {
    display: none;
  }
}

.move-btn-ngx-editor {
  display: none !important;
  &:before {
    font: normal normal normal 22px Lumen;
    content: "\ecec";
    color: var(--neutral-30, $neutral-30);
    font-size: 18px;
  }
  svg {
    display: none;
  }
}

.show-tooltip-ngx-photo {
  display: inline-block !important;
}

.tooltip-ngx-photo {
  display: none;
  padding: 6px;
  font-size: 11px;
  border-radius: 4px;
  line-height: 14px;
  background: var(--neutral-10, $neutral-10);
  white-space: nowrap;
  color: var(--neutral-100, $neutral-100);
  -webkit-border-radius: 4px;
  z-index: 52;
  height: 26px;
  position: absolute;
  top: 35px;
  left: 6px;
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  &::after {
    content: "";
    background-color: var(--neutral-10, $neutral-10);
    display: block;
    width: 6px;
    height: 12px;
    position: relative;
    top: -24px;
    left: calc(50% - 7px);
    z-index: -1;
    transform: rotate(45deg);
  }
}

.zoom-out-tooltip {
  left: 46px !important;
}

.rotate-left-tooltip {
  left: 60px !important;
}

.rotate-right-tooltip {
  left: 92px !important;
}

.flip-horizontal-tooltip {
  left: 120px !important;
}

.flip-vertical-tooltip {
  left: 160px !important;
}

.rotate-right {
  &:before {
    font: normal normal normal 22px Lumen;
    content: "\ece9";
    color: var(--neutral-30, $neutral-30);
    font-size: 18px;
  }
  svg {
    display: none;
  }
}

.crop-btn-ngx-editor {
  display: none !important;
  &:before {
    font: normal normal normal 22px Lumen;
    content: "\ece5";
    color: var(--neutral-30, $neutral-30);
    font-size: 18px;
  }
  svg {
    display: none;
  }
}

.flip-horizontal {
  &:before {
    font: normal normal normal 22px Lumen;
    content: "\ece6";
    color: var(--neutral-30, $neutral-30);
    font-size: 18px;
  }
  svg {
    display: none;
  }
}

.flip-vertical {
  &:before {
    font: normal normal normal 22px Lumen;
    content: "\ece7";
    color: var(--neutral-30, $neutral-30);
    font-size: 18px;
  }
  svg {
    display: none;
  }
}

.reset-btn-ngx-editor {
  &:before {
    font: normal normal normal 22px Lumen;
    content: "\e923";
    color: var(--neutral-30, $neutral-30);
    font-size: 18px;
    margin-right: 4px;
  }
  svg {
    display: none;
  }
}

.move-btn-ngx-editor,
.crop-btn-ngx-editor,
.zoom-in,
.zoom-out,
.rotate-left,
.rotate-right,
.flip-horizontal,
.flip-vertical,
.reset-btn-ngx-editor {
  &:hover {
    background-color: var(--neutral-90, $neutral-90) !important;

    cursor: pointer;
    border-radius: 4px;
  }
}

.reset-btn-ngx-editor {
  font-family: Roboto !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 18px !important;
  color: var(--text-gray, $text-gray) !important;
  width: auto !important;
  position: absolute;
  right: 15px;
}

.ngx-editor:not(.dx-fileuploader-empty) .dx-fileuploader-input-wrapper {
  border: 1px dashed #b3b3b3;
  border-radius: 4px;
  width: 320px;
  height: 42px;
  align-items: center;
  padding: 0px;
  background: #ffffff;
}

.ngx-editor:not(.dx-fileuploader-empty) .dx-fileuploader-input-wrapper {
  background: url(../../assets/img/Empty-state/text-empty-remove-bg.svg);
  background-repeat: no-repeat;
  background-position: 15px 15px;
}
