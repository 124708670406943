@use "./colors" as *;

.title-accordion {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 19px;
  color: var(--neutral-30, $neutral-30);
}

.no-padding {
  &.mobile-accordion .dx-accordion-item-body {
    padding: 10px 0;
    .flex-box-column {
      width: 100% !important;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .dx-accordion-item-body {
    overflow: hidden;
    padding: 8px 0px 22px;
    font-size: 14px;
  }
}

dx-accordion.internalAccordion {
  .dx-accordion-item-title.dx-accordion-item-title {
    padding-left: 10px !important;
  }
}

.dx-scheduler-appointment-tooltip-wrapper
  .dx-item.dx-list-item.dx-state-focused {
  border: none !important;
}

dx-accordion.normal-text .dx-accordion-item-title {
  color: var(--text-dark-gray, $text-dark-gray) !important;
  border-bottom: 1px solid var(--neutral-80, $neutral-80) !important;
  &::before {
    color: var(--text-dark-gray, $text-dark-gray) !important;
  }
}

dx-accordion.internalAccordion {
  .dx-accordion-item.dx-state-hover > .dx-accordion-item-title {
    background-color: transparent;
    color: var(--text-brand, $text-brand) !important;
    border-bottom: 1px solid var(--text-brand, $text-brand) !important;
    &::before {
      color: var(--icons-brand, $icons-brand) !important;
    }
  }
  .dx-accordion-item-title {
    font-family: Roboto-Medium;
    font-size: 0.75rem !important;
    font-weight: normal;
    color: var(--neutral-30, $neutral-30) !important;
    padding-left: 0 !important;
    border-bottom: 1px solid var(--neutral-50, $neutral-50) !important;
  }
  .dx-accordion-item-title::before {
    font-weight: 400;
    color: var(--neutral-10, $neutral-10) !important;
    content: "\e313";
    font-family: Lumen;
    font-size: 16px;
    margin-left: 9px;
    margin-right: 0;
    line-height: 16px;
  }
  .dx-accordion-item.dx-state-active > .dx-accordion-item-title {
    background-color: transparent !important;
  }
  .dx-accordion-item.dx-accordion-item-opened .dx-accordion-item-title {
    border-bottom: 1px solid var(--text-brand, $text-brand) !important;
    color: var(--text-brand, $text-brand) !important;
    font-size: 0.75rem;
    // line-height: 15px;
  }
}

.dx-accordion-item {
  border: none;
  border-bottom: 1px solid var(--neutral-50, $neutral-50);
}

.dx-accordion-item.dx-state-active:not(.dx-accordion-item-opened)
  > .dx-accordion-item-title {
  color: var(--text-light-gray, $text-light-gray);
  background-color: transparent;
}

.dx-accordion-item.dx-state-hover > .dx-accordion-item-title {
  background-color: transparent;
  color: var(--text-brand, $text-brand) !important;
  border-bottom: 1px solid var(--text-brand, $text-brand) !important;
  &::before {
    color: var(--icons-brand, $icons-brand) !important;
  }
}

.dx-accordion-item-title {
  font-family: Roboto-Medium;
  font-style: normal;
  //font-weight: bold;
  //font-size: 1rem;
  //line-height: 19px;
  font-size: 0.75rem;
  line-height: 15px;
  color: var(--text-light-gray, $text-light-gray);
  box-sizing: border-box;
  background-color: transparent;
  //padding: 30px 15px 11px 15px;
  /* padding: 9px 12px; */
}

.dx-accordion-item-title::before {
  font-weight: 400;
  color: var(--neutral-10, $neutral-10);
  content: "\e313";
  font-family: Lumen;
  font-size: 16px;
  margin-left: 9px;
  margin-right: 0;
  line-height: 16px;
}

.dx-state-focused.dx-accordion-item {
  border-color: var(--neutral-30, $neutral-30);
}

dx-accordion {
  background-color: transparent !important;
  .dx-accordion-item {
    border: none !important;
    .dx-accordion-item-title {
      padding-top: 13px;
      padding-bottom: 10px;
      padding-left: 10px;
      border-bottom: 1px solid var(--neutral-80, $neutral-80);
    }
    &.dx-accordion-item-opened > {
      .dx-accordion-item-title {
        border-bottom: 1px solid var(--text-brand, $text-brand);
        color: var(--text-brand, $text-brand);
        font-size: 0.75rem;
        line-height: 15px;
        &::before {
          content: "\e316";
          color: var(--icons-brand, $icons-brand) !important;
        }
      }
    }
  }
  .c-frame-row h5 {
    font-size: 0.8125rem;
    line-height: 15px;
  }
}

.dx-accordion-item-body {
  padding-top: 24px;
}

.standardAccordion {
  .dx-accordion-item-body {
    padding: 12px 0;
  }
  .dx-accordion-item .dx-accordion-item-title {
    border-bottom: 1px solid var(--neutral-80, $neutral-80) !important;
    color: var(--text-light-gray, $text-light-gray) !important;
    padding-left: 0;
    &::before {
      color: var(--neutral-10, $neutral-10) !important;
    }
  }
}
