@use "./colors" as *;
@use "./fonts" as *;


.top-menu-panel {
  height: 35px;
  width: calc(100% + 40px);
  margin-left: -10px;
  margin-bottom: 12px;
  background: var(--neutral-100, $neutral-100);
  padding-left: 0px;
  padding-top: 8px;
  padding-bottom: 9px;
  box-shadow: var(--shadow-m);
  white-space: nowrap;

  @media (max-width: 1000px) {
    transition: all 0.2s ease-in-out;
    &.stick-to-top {
      top: 0;
    }
  }
}

.dx-context-menu-content-delimiter {
  display: none !important;
}

.top-menu.dx-context-menu .dx-submenu {
  margin-top: -3px !important;
  margin-left: 3px;
}

.top-menu.dx-menu-base .dx-menu-item-content {
  padding: 3px 15px 0px !important;
  height: 30px !important;
}

.dx-context-menu .dx-submenu {
  background-color: var(--ds-background-dropdown-menu, $ds-background-dropdown-menu);
  margin-top: 0px;
  margin-left: 5px;
  border: 1px solid var(--neutral-70, $neutral-70);
  box-shadow: 0 2px 2px rgb(0 0 0 / 15%);
}

.dx-context-menu
  .dx-menu-no-icons
  > .dx-menu-item-wrapper
  > .dx-menu-item
  > .dx-menu-item-content
  .dx-menu-item-text {
  padding-left: 0px;
}

.dx-submenu .top-menu {
  height: auto !important;
}

.top-menu {
  .dx-menu-item.dx-state-hover {
    background-color: transparent;
  }

  height: 100%;
  ul {
    height: 100%;
  }
  .dx-menu-horizontal .dx-menu-item-wrapper {
    display: inline-block;
  }

  .top-menu-item {
    padding-top: 4px;
    height: 35px !important;
    margin: 0;
    font-family: "Roboto";
    &:hover {
      .icon {
        color: var(--text-light-gray, $text-light-gray);
      }
    }
    &.active {
      .dx-menu-item-text {
        color: var(--text-brand, $text-brand);
        top: -1.5px;
      }
    }
    .dx-menu-item-text {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 14px;
      padding-left: 0px;
      padding-right: 0px;
      color: var(--text-light-gray, $text-light-gray);
      position: relative;
      top: -1px;
    }
  }

  .dx-submenu .top-menu-item {
    padding-top: 8px !important;
    height: 30px !important;
    margin: 0;
    font-family: "Roboto";
    &:hover {
      background-color: var(--neutral-90, $neutral-90);
      border-radius: 4px;
      // .dx-menu-item-text {
      //     color: var(--neutral-30, $neutral-30)!important;
      // }
    }
    &.active {
      .dx-menu-item-text {
        color: var(--text-brand, $text-brand) !important;
        font-weight: 500 !important;
      }
    }

    &:focus {
      border-bottom: 2px solid
        var(--ds-color-ui-state-brand-focus, $ds-color-ui-state-brand-focus);
      .dx-menu-item-text {
        color: var(
          --ds-color-ui-state-brand-focus,
          $ds-color-ui-state-brand-focus
        );
      }
    }
    .dx-menu-item-text {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-size: 0.75rem;
      line-height: 14px;
      padding-left: 0px;
      padding-right: 0px;
      color: var(--neutral-30, $neutral-30) !important;
    }
  }
}

/* treeview */
.dx-treeview-node {
  font-family: $Roboto;
  font-style: normal;
  // font-weight: 500;
  font-size: 0.75rem;
  line-height: 16px;
  list-style-type: none;
  color: var(--text-gray, $text-gray);
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-drag: none;
}

.dx-treeview-item-without-checkbox.dx-state-focused > .dx-treeview-item {
  background-color: transparent; //var(--neutral-100, $neutral-100);
  color: var(--text-gray, $text-gray);
  font-family: $Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 16px;
}

.dx-treeview-toggle-item-visibility {
  font: 16px/16px Lumen !important;
  text-align: center;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-width: 0.24px;
}

.dx-treeview-toggle-item-visibility::before {
  content: "\e318";
  position: absolute;
  display: block;
  width: 22px;
  top: 50%;
  margin-top: -11px;
  left: 50%;
  margin-left: -11px;
}

.dx-treeview-toggle-item-visibility.dx-treeview-toggle-item-visibility-opened {
  font: 16px/16px Lumen !important;
  text-align: center;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-width: 0.24px;
}

.dx-treeview-item-without-checkbox.dx-state-selected > .dx-treeview-item {
  background-color: transparent;
  font-size: 0.75rem;
  font-weight: bold !important;
  line-height: 16px;
  color: var(--text-brand, $text-brand) !important;
}

.dx-treeview-toggle-item-visibility.dx-treeview-toggle-item-visibility-opened::before {
  content: "\e313";
  position: absolute;
  display: block;
  width: 22px;
  top: 50%;
  margin-top: -11px;
  left: 50%;
  margin-left: -11px;
}

.dx-popover-arrow::after {
  position: absolute;
  display: block;
  overflow: hidden;
  content: " ";
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border: none !important;
}

.dx-context-menu .dx-menu-item-popout::before {
  font-family: "Lumen";
  content: "\ea1d";
  font-size: 18px;
  line-height: 18px;
}

app-product-scaner + .refresh-panel {
  & > .dx-button {
    height: 26.5px !important;
  }
}

@media (max-width: 1000px) {
  dx-data-grid:not(.fixed-height):not(.main-mobile-grid) {
    height: auto !important;
  }
}
