@use "ol/ol.css";
@import '../../../../assets/scss/colors';

::ng-deep {
  .dx-popup-title.dx-toolbar .dx-toolbar-item:last-child {
    padding-left: 64px;
    padding-top: 13px;
  }
}

#map {
  width: 100%;
  height: calc(100vh - 85px);
  margin-top: 6px;
}

.separator {
  margin-left: 16px;
  margin-right: 8px;
  border-left: 1px solid var(--ds-color-ui-neutral-80, #e5e5e5);
  border-radius: 4px;
  height: 24px;
}

.route-title {
  margin-left: 10px;
  color: var(--ds-color-text-black, #000);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  &_fz12 {
    margin: 0;
    padding: 0;
    font-size: 12px;
  }
  &_implementation {
    line-height: 10px;
  }
  &_date {
    line-height: 14px;
  }
}

.route-desc {
  color: var(--ds-color-text-gray, #4c4c4c);
  font-family: Roboto;
  margin-bottom: 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.map.absui-icon--directions-car:before {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 47%;
  content: "\e531";
  font-size: 24px !important;
}

.link-open-street {
  text-align: center;
  width: 195px;
  position: absolute;
  bottom: 25px;
  right: 16px;
  background-color: rgba(255, 255, 255, 0.7);
}

.tooltip {
  position: absolute;
  background-color: var(--ds-body-background-100, $ds-body-background-100);
  border: 1px solid var(--neutral-60, $neutral-60);
  padding: 5px;
  font-size: 12px;
  color: var(--neutral-20, $neutral-20);
  display: none; /* Początkowo ukryty */
  white-space: nowrap; /* Zapewnia, że tekst nie złamie się na nową linię */
  z-index: 1000; /* Ustawienie na wyższy z-index, aby tooltip był widoczny nad mapą */
}

.map-mobile-title {
  display: flex;
  justify-content: space-between;
  padding: 14px;
  background: var(--ds-color-ui-neutral-100, #fff);
  box-shadow: var(--shadow0l);
  font-weight: 600;
  color: var(--theme-text-icon-gray, #4c4c4c);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  line-height: 20px; /* 125% */
  &_subject {
    font-weight: 600;
  }
}

.buttons-map {
  z-index: 1000;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 30px;
  box-shadow: var(--shadow0l);

  &_google-maps {
    bottom: 14px;
    left: 14px;
    background: var(--ds-color-ui-neutral-100, #fff); /* Tło dla google-maps */
  }

  &_calculate-route {
    bottom: 14px;
    right: 14px;
    background: var(--theme-brand-10, #bc1a22); /* Tło dla calculate-route */
  }

  &_fit-to-point {
    background: var(--ds-color-ui-neutral-100, #fff); /* Tło dla google-maps */
    bottom: 70px;
    right: 14px;
  }
}

@media (max-width: 1000px) {
  #map {
    width: 100%;
    height: calc(100% - 48px);
  }
  .link-open-street {
    font-size: 10px;
    width: 143px;
    line-height: 14px;
    bottom: 7px;
    right: 114px;
  }
}
