/* in-flight clone */
.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    //opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
    box-shadow: 0px 10px 30px 0px #4C4C4C21;
    pointer-events: none;
  }
  /* high-performance display:none; helper */
  .gu-hide {
    // left: -9999px !important;
  }
  /* added to mirrorContainer (default = body) while dragging */
  .gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }
  /* added to the source element while its mirror is dragged */
  .gu-transit {
    // opacity: 0.2;
    // -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    // filter: alpha(opacity=20);
  }