app-wapro-assistant,
wapro-assistant {
  * {
    box-sizing: border-box;
  }

  &[dragging] *,
  &[dragging] *:hover {
    cursor: grabbing !important;
    user-select: none !important;
  }

  button,
  .chat-message-button {
    padding: 6px 12px;
    @media (min-width: 768px) {
      padding: 4px 10px;
    }
    gap: 2px;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    background-color: var(--neutral-80, #e5e5e5);
    border: 1px solid var(--neutral-80, #e5e5e5);
    color: var(--text-gray, #4c4c4c);
    font-weight: 400;

    &:hover {
      cursor: pointer;
      background-color: var(--neutral-85, #ededed);
    }

    &:focus,
    &:focus-visible {
      outline: 1px solid var(--ds-color-ui-state-brand-focus, #30393f) !important;
      border-color: var(--ds-color-ui-state-brand-focus, #30393f) !important;
    }


    &[disabled="true"],
    &[disabled="true"]:hover,
    &[disabled="true"]:focus,
    &[disabled="true"]:focus-visible,
    &[disabled="true"]:active,
    &[disabled="true"]:visited,
    &:disabled,
    &:disabled:hover,
    &:disabled:focus,
    &:disabled:focus-visible,
    &:disabled:active,
    &:disabled:visited {
      background-color: var(--neutral-90, #f5f5f5) !important;
      border: 1px solid var(--neutral-90, #f5f5f5) !important;
      color: var(--text-light-gray, #666666) !important;
      cursor: default;
    }

    &.chosen,
    &.chosen:hover,
    &.chosen:focus,
    &.chosen:active,
    &.chosen:focus-visible,
    &.chosen:visited,
    &.chosen:disabled {
      background-color: var(--theme-warning-100, #fef8eb) !important;
      border: 1px solid var(--theme-warning-100, #fef8eb) !important;
      color: var(--text-dark-gray, #222222) !important;
    }
  }
}

.wapro-assistant-toggle {
  i.icon:before {
    font-size: 22px;
  }
}
