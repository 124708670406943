@use "./colors" as *;
@use "./fonts" as *;

.dx-popup-wrapper {
  & > .dx-overlay-content {
    border: none;

    &.dx-state-focused {
      border: 1px solid var(--ds-body-stroke-DM-only, $ds-body-stroke-DM-only);
    }
  }
}

.dx-box-item-content .dx-numberbox-spin-container {
  display: none;
}

.settings-mobile .dx-popup-content {
  padding: 16px 12px !important;
}

.dx-scheduler-navigator-calendar {
  width: 100% !important;
}

.dx-calendar {
  width: 260px;
  min-width: 260px;
  height: 268.6px;
  min-height: 268.6px;
  background-color: var(--ds-background-dropdown-menu, $ds-background-dropdown-menu);
  -webkit-tap-highlight-color: transparent;
  outline: 0;
  border: 1px solid transparent;
}

.btn-dx-top-menu-folder-grid.dx-state-focused {
  background-color: var(--neutral-85, $neutral-85);
  border-radius: 6px;
  .dx-icon {
    &::before {
      // color: var(--neutral-30, $neutral-30) !important;
    }
  }
}

.dx-popup-wrapper.dx-dropdowneditor-overlay
  > .dx-overlay-content.dx-dropdowneditor-overlay-flipped {
  background: var(--ds-background-dropdown-menu, $ds-background-dropdown-menu);
  box-sizing: border-box;
  border: 1px solid var(--neutral-80, $neutral-80);
  box-shadow: var(--shadow-m);
  border-radius: 8px;
  min-width: 460px;
}

.dx-popup-wrapper.dx-dropdowneditor-overlay > .dx-overlay-content {
  background: var(--ds-background-dropdown-menu, $ds-background-dropdown-menu);
  box-sizing: border-box;
  border: 1px solid var(--neutral-80, $neutral-80);
  box-shadow: var(--shadow-m);
  border-radius: 8px;
  min-width: 460px;
  // min-height: 300px;

  .dx-datagrid > .dx-datagrid-headers,
  .dx-datagrid > .dx-datagrid-rowsview,
  .dx-datagrid > .dx-datagrid-total-footer,
  .dx-row.dx-column-lines.dx-header-row {
    background: var(
      --ds-background-dropdown-menu,
      $ds-background-dropdown-menu
    );
  }
}

.max320.dx-popup-wrapper.dx-dropdowneditor-overlay > .dx-overlay-content {
  background: var(--ds-background-dropdown-menu, $ds-background-dropdown-menu);
  box-sizing: border-box;
  border: 1px solid var(--neutral-80, $neutral-80);
  box-shadow: var(--shadow-m);
  border-radius: 8px;
  min-width: 320px;
}

.dx-datebox-wrapper-calendar.dx-dropdowneditor-overlay > .dx-overlay-content {
  background: var(--ds-background-dropdown-menu, $ds-background-dropdown-menu);
  box-sizing: border-box;
  border: 1px solid var(--neutral-80, $neutral-80);
  box-shadow: var(--shadow-m);
  border-radius: 8px;
  min-width: 300px !important;
}

.dx-datebox-wrapper.dx-datebox-wrapper-datetime
  .dx-toolbar
  .dx-button
  .dx-button-content {
  padding: 2px 10px;
}

.dx-datebox-wrapper-datetime.dx-dropdowneditor-overlay > .dx-overlay-content {
  background: var(--ds-background-dropdown-menu, $ds-background-dropdown-menu);
  box-sizing: border-box;
  border: 1px solid var(--neutral-80, $neutral-80);
  box-shadow: var(--shadow-m);
  border-radius: 8px;
  min-width: 515px !important;
}

.bottom-drop-box {
  border-top: 1px solid var(--neutral-80, $neutral-80);
  height: 42px;
  box-sizing: border-box;
  width: calc(100% + 20px);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 6px;
  margin-left: -10px;
}

.no-padding {
  padding-left: 0px;
  padding-right: 0px;
  margin-top: -11px;
  width: calc(100% + 10px);
  margin-left: -5px;
}

.boxShadow {
  box-shadow: var(--shadow-m) !important;
}

.dx-popup-content {
  padding: 12px 16px 16px;
}

.popup-footer {
  display: flex;
  justify-content: flex-end;
  gap: 6px;
  height: auto;
  padding-bottom: 0;
}

.confirm-dialog-wapro .dx-popup-content {
  padding: 26px 14px 14px 20px;

  @media (max-width: 1000px) {
    padding-top: 14px !important;
  }
}
/* .dx-popup-content {
     padding: 15px;
} */

.dx-popup-title.dx-toolbar .dx-toolbar-label {
  font-size: 0.875rem;
  font-family: $Roboto;
  font-weight: 700;
  color: var(--neutral-30, $neutral-30);
}

.dx-popover-wrapper.dx-position-left .dx-popover-arrow {
  width: 10px;
  height: 20px;
  inset: 3px -9px auto auto !important;
}

.dx-popover-wrapper.dx-position-right .dx-popover-arrow {
  width: 10px;
  height: 20px;
  inset: 8px auto auto -9px !important;
}

.dx-popup-title.dx-toolbar.column-popup-title {
  background: var(--neutral-90, $neutral-90);

  .dx-toolbar-label {
    font-family: $Roboto;
    // font-size: 1rem;
    color: var(--neutral-30, $neutral-30);
    // line-height: 2rem;
    font-weight: bold;
    padding-top: 13px !important;
  }
}

.dx-popup-draggable .dx-popup-title {
  cursor: move;
  touch-action: pinch-zoom;
  z-index: 1;
}

.confirme-warning,
.confirme-delete,
.confirme-question,
.confirme-information {
  font-family: Lumen;
  font-size: 1.875rem;
  line-height: 30px;
  vertical-align: middle;
}

.confirme-delete {
  &::before {
    color: var(--text-brand, $text-brand);
    content: "\e888";
  }
}

.confirme-warning {
  &::before {
    color: var(--icons-orange, $icons-orange);
    content: "\e002";
  }
}

.confirme-information {
  &::before {
    color: var(--theme-info-30, $theme-info-30);
    content: "\e88f";
  }
}

.confirme-question {
  &::before {
    color: var(--neutral-30, $neutral-30);
    content: "\e8fd";
  }
}

.confirme-warning-message {
  &::before {
    font-family: Lumen;
    font-size: 18px;
    line-height: 18px;
    color: var(--icons-orange, $icons-orange);
    margin-right: 8px;
    vertical-align: middle;
    content: "\e002";
  }
}

.header-bar-popup {
  display: flex;
  min-height: 30px;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 4px 10px;
  border: 1px solid var(--theme-info-85, $theme-info-85);
  border-radius: 4px;
  background: var(--theme-info-100, $theme-info-100);

  &_text {
    font-weight: 400;
    font-size: 12px;
    color: var(--neutral-30, $neutral-30);
    font-family: $Roboto;
    font-style: normal;
    line-height: 18px;
  }
}

.confirme-info-message {
  &::before {
    font-family: Lumen;
    font-size: 18px;
    line-height: 18px;
    color: var(--theme-info-30, $theme-info-30);
    margin-right: 8px;
    vertical-align: middle;
    content: "\e88f";
  }
}

.dx-context-menu.dx-overlay-content {
  overflow: inherit;
  position: absolute;
  //width: 212px!important;
}

.dx-tooltip-wrapper > .dx-overlay-content {
  border: 1px solid var(--neutral-70, $neutral-70);
  background: var(--ds-background-dropdown-menu, $ds-background-dropdown-menu);
  box-shadow: var(--shadow-l);
  border-radius: 6px;
  min-width: 0px !important;
}

.dx-popup-wrapper {
  & > .dx-overlay-content {
    border: 1px solid var(--neutral-70, $neutral-70);
    background: var(
      --ds-background-tiles-panels,
      $ds-background-tiles-panels
    );
    color: var(--text-gray, $text-gray);
    box-shadow: var(--shadow-l);
    border-radius: 8px;
    min-width: 520px;
    // max-width: 100%;
    &.dx-popup-fullscreen-width {
      border-radius: 0;
    }
  }

  &.card-of-record {
    & > .dx-overlay-content {
      background: var(--ds-overlay-background-98, $ds-overlay-background-98);
    }
  }
}

.dx-overlay-content .dx-popup-content {
  max-height: none !important;
  font-size: 13px;
  font-family: $Roboto;
  line-height: 15.23px;
}

.confirm-dialog-wapro .dx-overlay-content .dx-popup-content {
  max-height: none !important;
  font-size: 12px;
  font-family: $Roboto;
  line-height: 18px;
}

.dx-toolbar-after,
.dx-toolbar-before,
.dx-toolbar-center {
  top: 0;
  display: inline;
  height: 100%;
}

.columns-hr {
  margin-top: 0.5rem;
}

dx-list {
  .dx-list-item-before-bag .dx-list-select-checkbox,
  .dx-list-item-before-bag .dx-list-select-radiobutton {
    margin-top: -7px;
    margin-bottom: -3px;
    margin-left: 10px;
  }
}

.route-users.dx-list {
  .dx-list-item-before-bag.dx-list-select-radiobutton-container {
    display: none;
    width: 0;
  }
}

.dx-list.dx-list-select-decorator-enabled
  .dx-list-item.dx-state-focused
  .dx-checkbox-icon,
.dx-list.dx-list-select-decorator-enabled
  .dx-list-item.dx-state-focused
  .dx-radiobutton-icon::before,
.dx-list.dx-list-select-decorator-enabled
  .dx-list-select-all.dx-state-focused
  .dx-checkbox-icon,
.dx-list.dx-list-select-decorator-enabled
  .dx-list-select-all.dx-state-focused
  .dx-radiobutton-icon::before {
  border: 1px solid transparent;
}

.columns-list {
  .dx-list-items {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .dx-item {
    display: flex;
    align-items: center;
    padding: 6px 12px;
    gap: 8px;

    .dx-list-item-before-bag.dx-list-select-checkbox-container,
    .dx-list-item-before-bag.dx-list-select-radiobutton-container {
      width: 17px;
      flex: 0 0 17px;
      height: 17px;
      display: flex;
    }
    .dx-checkbox {
      margin: 0;
      height: 100%;
      width: 100%;
      .dx-checkbox-icon {
        height: 100%;
        width: 100%;
      }
      .dx-checkbox-icon::before {
        width: 17px;
        font-size: 17px !important;
        margin: 0;
        left: 0;
        top: 0;
      }
    }
    .dx-list .dx-empty-message,
    .dx-list-item-content {
      width: auto;
      padding: 0;
    }
    .dx-list-item-after-bag.dx-list-reorder-handle-container {
      width: 100%;
      flex: 1 0 auto;
      width: auto;
      display: flex;
      justify-content: flex-end;
      .dx-list-reorder-handle {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        padding: 0;
      }
    }

    .dx-list-item-after-bag {
      height: 18px;
    }
  }

  .dx-list-item,
  .dx-item.dx-list-item {
    border: 1px solid transparent !important;
    border-radius: 4px;
    border-top: none;
    .dx-checkbox,
    .dx-checkbox-checked .dx-checkbox-icon {
      color: var(--icons-brand, $icons-brand) !important;
      background-color: inherit;
    }

    &.dx-state-active,
    &.dx-state-active.dx-state-hover {
      background-color: var(
        --ds-color-ui-state-row-focus,
        $ds-color-ui-state-row-focus
      ) !important;
      color: var(--icons-brand-inverted, $icons-brand-inverted) !important;
      .dx-item-content {
        color: inherit !important;
      }
      .dx-checkbox-icon {
        background-color: inherit;
        &:before {
          content: "\eab2" !important;
          color: var(--icons-brand-inverted, $icons-brand-inverted) !important;
        }
      }

      .dx-checkbox-checked .dx-checkbox-icon {
        &:before {
          content: "\e9a8" !important;
        }
      }
      .pinned-icon:before {
        font-family: "Lumen";
        content: "\eb87";
        color: var(--icons-brand-inverted, $icons-brand-inverted) !important;
        font-size: 1.2rem !important;
      }
    }

    &.dx-state-focused {
      border-color: transparent !important;
      border-width: 1px !important;
      &:has(.column-fixed) {
        border-bottom: 1px solid var(--neutral-80, $neutral-80) !important;
      }
      .dx-checkbox-icon {
        border: none !important;
      }
    }

    &:has(.column-fixed) {
      border-bottom-color: var(--neutral-80, $neutral-80) !important;
    }
    // &.dx-state-focused {
    //   background-color: var(--neutral-40, $neutral-40) !important;
    //   border-radius: 2px;
    //   color: var(--neutral-100, $neutral-100) !important;

    //   .dx-item-content {
    //     color: var(--neutral-100, $neutral-100) !important;
    //   }

    //   .dx-checkbox {
    //     color: var(--neutral-100, $neutral-100) !important;

    //     .dx-checkbox-icon {
    //       color: inherit !important;
    //       background-color: transparent !important;

    //       &::before {
    //         color: #fff !important;
    //       }
    //     }
    //   }

    //   .dx-checkbox-checked {
    //     color: var(--neutral-100, $neutral-100) !important;

    //     .dx-checkbox-icon {
    //       color: inherit !important;
    //       background-color: transparent !important;

    //       &::before {
    //         color: #fff !important;
    //         content: "\e9a8" !important;
    //       }
    //     }
    //   }
    // }
  }

  .dx-list .dx-empty-message,
  .dx-list-item-content {
    padding: 5px;
    padding-left: 15px;
    border-radius: 6px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: var(--text-light-gray, $text-light-gray);
  }

  .dx-list-item.dx-state-focused {
    border: 0px solid transparent !important;
    box-shadow: none;
  }

  .dx-list-item.dx-sortable-source-hiden {
    border: 1px solid transparent !important;
    box-shadow: none;
  }

  .dx-list-item.dx-state-hover:not(.dx-state-active) {
    background: var(
      --ds-color-ui-state-neutral-hover,
      $ds-color-ui-state-neutral-hover
    );

    .dx-list-item-after-bag .dx-list-reorder-handle::before {
      // color: var(--icons-brand, $icons-brand);
    }

    &.dx-state-focused {
      .dx-list-item-after-bag .dx-list-reorder-handle::before {
        // color: var(--brand-70, $brand-70);
      }
    }
  }

  .dx-sortable-clone.dx-sortable-dragging {
    border: 1px solid transparent !important;
    border-radius: 6px;
  }
}

.confirm-dialog {
  width: 355px;
  height: 155px;
}

.title-pop-up {
  position: absolute;
  top: -0px;
  width: 100%;
  // padding-right: 20px;
  right: 0;
  box-sizing: border-box;
  // padding-bottom: 14px;
  // padding-top: 14px;
  padding: 13px 16px;
  background-color: var(
    --ds-background-tiles-panels,
    $ds-background-tiles-panels
  );

  &_bottom {
    bottom: -0;
  }
  &_newOrderItem {
    top: -6px;
  }
  dx-button {
    position: relative;
  }
  dx-switch {
    position: relative;
    z-index: 2;
  }
  dx-check-box {
    position: relative;
    z-index: 2;
  }
  dx-drop-down-button {
    position: relative;
    z-index: 2;
  }
  i {
    position: relative;
    z-index: 2;
  }
}

.dx-popup-title {
  position: relative;
  padding: 0px 0px;
  min-height: 28px;
  border-bottom: none;
  background: 0 0;
  color: var(--neutral-30, $neutral-30);
}

.title-pop-up {
  .dx-button.dx-button-mode-contained.dx-button-danger,
  .dx-button.dx-button-mode-contained.dx-button-success {
    min-width: 70px;
  }
}

.dx-popup-title .dx-closebutton {
  display: block;
  position: relative;
  border-radius: 4px;
  top: -5px;
  right: 15px;
  background: 0 0;
  width: 23px;
  height: 23px;
  margin: 0 -4px 0 4px;
  border: 1px solid transparent;
}

.dx-popup-title.dx-toolbar .dx-toolbar-item:last-child {
  padding-left: 16px;
  padding-top: 10px;
  line-height: 26px;
}

.document-brand {
  .dx-popup-title.dx-toolbar .dx-toolbar-item:last-child {
    padding-left: 54px !important;
    padding-top: 22px;
    line-height: 26px;
  }

  &.card-of-record {
    .dx-popup-title.dx-toolbar .dx-toolbar-item:last-child {
      padding-left: 66px !important;
      padding-top: 22px;
      line-height: 20px;
      padding-bottom: 14px;
    }
  }
}

.confirm-dialog-wapro .dx-popup-title.dx-toolbar .dx-toolbar-item:last-child {
  padding-left: 20px;
  padding-top: 12px;
}

.dx-popup-title.dx-toolbar .dx-toolbar-items-container {
  height: 53px;
  overflow: visible;
}

.confirm-dialog-wapro .dx-popup-title.dx-toolbar .dx-toolbar-items-container {
  height: 43px;
  overflow: visible;
}

.dx-tooltip-wrapper .dx-overlay-content {
  border: none;
  background-color: transparent;
  color: var(--neutral-30, $neutral-30);
  box-shadow: none;
  border-radius: 4px;
}

.dx-popup-title.dx-toolbar {
  height: 53px;
  padding: 0px 0px;
  overflow: visible;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.confirm-dialog-wapro .dx-popup-title.dx-toolbar {
  height: 43px;
  overflow: visible;
}

@media (max-width: 812px) {
  .dx-toolbar-label > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 10px -5px;
    padding: 0 5px;
    //display: none;
  }
}

.dx-overlay-wrapper.dx-dropdowneditor-overlay.dx-popup-wrapper.dx-dropdownlist-popup-wrapper {
  .dx-overlay-content.dx-popup-normal.dx-resizable {
    min-width: 200px;
    min-height: 0px;
    padding: 5px 5px;
  }
}

.dialog-body {
  display: flex;
  gap: 14px;
  padding-right: 18px;
  padding-bottom: 8px;
  min-height: 30px;

  .dialog-main-text {
    padding-top: 5px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    & > :nth-child(1) {
      display: block;
      margin: 0;
      &.confirmQuestion {
        padding-bottom: 8px;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
}

.dialog-footer {
  padding-top: 14px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 6px;

  dx-button {
    min-width: 70px;
  }
}

.user-guide-fix {
  @media (min-width: 1001px) {
    &.new-comerial-operation-popup {
      .dx-popup-content {
        position: relative;
        top: -54px;
        padding-top: 54px;
      }
    }
  }
}

.legend-tooltip .dx-popup-content {
  padding: 6px 0px 8px 8px !important;

  .mobile-legend {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 90vw;
    text-align: left;
    .legend-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .legend-header-text {
        font-family: $Roboto;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        text-align: left;
        color: var(--text-white, $text-white);
      }

      .legend-header-btn {
        background: transparent !important;
        .dx-icon {
          color: var(--neutral-100, $neutral-100);
        }

        &:hover,
        &:focus,
        &:active {
          .dx-icon:before {
            color: var(--neutral-85, $neutral-85);
          }
        }
      }
    }

    .legend-title {
      white-space: normal;
    }

    .legend-items {
      display: flex;
      flex-direction: column;
      gap: 6px;
      padding-right: 28px;
      justify-content: flex-start;
      align-items: flex-start;

      .legend-item {
        display: flex;
        gap: 10px;
        justify-content: flex-start;
        align-items: center;

        .legend-item-color {
          width: 10.8px;
          height: 10.8px;
          background-color: var(--accent-grey, $accent-grey);
          border-radius: 50%;
          margin: 3.6px;
        }

        .legend-item-text {
          font-family: $Roboto;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          text-align: left;
        }
      }
    }
  }
}

.dx-overlay-shader:has(+ .dx-overlay-shader) {
  background: none !important;
}
